import * as React from 'react';
import { useState } from 'react';
import {
    Box,
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    TextField,
    Button,
} from "@mui/material";
import * as FormTxt from './Constants';
import { addPatientNote } from '../../../api/apigateway/apigateway';

function InputNewPatientVisit() {

    const [state, setState] = useState({
        gen1: true,
        gen2: true,
        gen3: true,
        gen4: true,
        heent1: true,
        heent2: true,
        heent3: true,
        heent4: true,
        heent5: true,
        heent6: true,
        heent7: true,
        heent8: true,
        heent9: true,
        heent10: true,
        heent11: true,
        heent12: true,
        neck1: true,
        neck2: true,
        neck3: true,
        neck4: true,
        neck5: true,
        neck6: true,
        resp1: true,
        resp2: true,
        resp3: true,
        resp4: true,
        resp5: true,
        cardio1: true,
        cardio2: true,
        abd1: true,
        abd2: true,
        abd3: true,
        back1: true,
        back2: true,
        extrem1: true,
        extrem2: true,
        extrem3: true,
        extrem4: true,
        neuro1: true,
        neuro2: true,
        neuro3: true,
        neuro4: true,
        neuro5: true,
        neuro6: true,
        neuro7: true,
        psych1: true,
        plan1: false,
        plan2: false,
        plan3: false,
        plan4: false,
    });

    const [chiefComplaint, setChiefComplaint] = useState("");
    const [complaint, setComplaint] = useState("");
    const [type, setStateType] = useState("");
    const [history, setHistory] = useState("");
    const [assessment, setAssessment] = useState("");
    const [report, setReport] = useState("");

    const handleCheckboxChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const handleChiefComplaintChange = (event) => {
        setChiefComplaint(event.target.value);
    };

    const handleTypeChange = (event) => {
        setStateType(event.target.value);
    };

    const handleHistoryChange = (event) => {
        setHistory(event.target.value);
    };

    const handleComplaintChange = (event) => {
        setComplaint(event.target.value);
    };

    const handleAssessmentChange = (event) => {
        setAssessment(event.target.value);
    };

    const handleReportChange = (event) => {
        setReport(event.target.value);
    };

    const onGenerateReport = () => {
        var rval = "";
        rval += FormTxt.disclaimer + '\n\n';
        rval += "CHIEF COMPLAINT: " + chiefComplaint + '\n\n';
        rval += "HISTORY OF PRESEENT ILLNESS:\nThe patient is a " + type +
            " with a history of " + history +
            " complaining of a " + complaint + ".\n\n";
        rval += "REVIEW OF SYSTEMS: " + FormTxt.reviewOfSystems + "\n\n";
        rval += "PHYSICAL EXAM: \n";
        rval += "GENERAL: " +
            (gen1 ? (FormTxt.gen1txt+", ") : "") +
            (gen2 ? (FormTxt.gen2txt+",") : "") +
            (gen3 ? (FormTxt.gen3txt+",") : "") +
            (gen4 ? (FormTxt.gen4txt+",") : "") + "\n";
        rval += "HEENT: " +
            (heent1 ? (FormTxt.heent1txt +", ") : "") +
            (heent2 ? (FormTxt.heent2txt +", ") : "") +
            (heent3 ? (FormTxt.heent3txt +", ") : "") +
            (heent4 ? (FormTxt.heent4txt +", ") : "") +
            (heent5 ? (FormTxt.heent5txt +", ") : "") +
            (heent6 ? (FormTxt.heent6txt +", ") : "") +
            (heent7 ? (FormTxt.heent7txt +", ") : "") +
            (heent8 ? (FormTxt.heent8txt +", ") : "") +
            (heent9 ? (FormTxt.heent9txt +", ") : "") +
            (heent10 ? (FormTxt.heent10txt +", ") : "") +
            (heent1 ? (FormTxt.heent1txt +", ") : "") +
            (heent1 ? (FormTxt.heent1txt +", ") : "") + "\n";
        rval += "NECK: " +
            (neck1 ? (FormTxt.neck1txt +", ") : "") +
            (neck2 ? (FormTxt.neck2txt +", ") : "") +
            (neck3 ? (FormTxt.neck3txt +", ") : "") +
            (neck4 ? (FormTxt.neck4txt +", ") : "") +
            (neck5 ? (FormTxt.neck5txt +", ") : "") +
            (neck6 ? (FormTxt.neck6txt +", ") : "") + "\n";
        rval += "RESPIRATORY: " +
            (resp1 ? (FormTxt.resp1txt +", ") : "") +
            (resp2 ? (FormTxt.resp2txt +", ") : "") +
            (resp3 ? (FormTxt.resp3txt +", ") : "") +
            (resp4 ? (FormTxt.resp4txt +", ") : "") +
            (resp5 ? (FormTxt.resp5txt +", ") : "") + "\n";
        rval += "CARDIOVASCULAR: " +
            (cardio1 ? (FormTxt.cardio1txt +", ") : "") +
            (cardio2 ? (FormTxt.cardio2txt +", ") : "") + "\n";
        rval += "ABDOMINAL: " +
            (abd1 ? (FormTxt.abd1txt +", ") : "") +
            (abd2 ? (FormTxt.abd2txt +", ") : "") +
            (abd3 ? (FormTxt.abd3txt +", ") : "") + "\n";
        rval += "BACK: " +
            (back1 ? (FormTxt.back1txt +", ") : "") +
            (back2 ? (FormTxt.back2txt +", ") : "") + "\n";
        rval += "EXTREMITIES: " +
            (extrem1 ? (FormTxt.extremities1txt +", ") : "") +
            (extrem2 ? (FormTxt.extremities2txt +", ") : "") +
            (extrem3 ? (FormTxt.extremities3txt +", ") : "") +
            (extrem4 ? (FormTxt.extremities4txt +", ") : "") + "\n";
        rval += "NEUROLOGICAL: " +
            (neuro1 ? (FormTxt.neuro1txt +", ") : "") +
            (neuro2 ? (FormTxt.neuro2txt +", ") : "") +
            (neuro3 ? (FormTxt.neuro3txt +", ") : "") +
            (neuro4 ? (FormTxt.neuro4txt +", ") : "") +
            (neuro5 ? (FormTxt.neuro5txt +", ") : "") +
            (neuro6 ? (FormTxt.neuro6txt +", ") : "") +
            (neuro7 ? (FormTxt.neuro7txt +", ") : "") + "\n";
        rval += "PSYCH: " +
            (psych1 ? (FormTxt.psych1txt +", ") : "") + "\n\n";

        rval += "ASSESSMENT: \n" + assessment + "\n\n";
        rval += "PLAN: \n" +
            FormTxt.planMainTxt + " " +
            (plan1 ? (FormTxt.planTxt1 +", ") : "") +
            (plan2 ? (FormTxt.planTxt2 +", ") : "") +
            (plan3 ? (FormTxt.planTxt3 +", ") : "") +
            (plan4 ? (FormTxt.planTxt4 +", ") : "") +
            FormTxt.planMainTxt2 + "\n";
        addPatientNote(rval, "0");
        setReport(rval);

    };

    const onResetForm = () => {
        setState({
            gen1: true,
            gen2: true,
            gen3: true,
            gen4: true,
            heent1: true,
            heent2: true,
            heent3: true,
            heent4: true,
            heent5: true,
            heent6: true,
            heent7: true,
            heent8: true,
            heent9: true,
            heent10: true,
            heent11: true,
            heent12: true,
            neck1: true,
            neck2: true,
            neck3: true,
            neck4: true,
            neck5: true,
            neck6: true,
            resp1: true,
            resp2: true,
            resp3: true,
            resp4: true,
            resp5: true,
            cardio1: true,
            cardio2: true,
            abd1: true,
            abd2: true,
            abd3: true,
            back1: true,
            back2: true,
            extrem1: true,
            extrem2: true,
            extrem3: true,
            extrem4: true,
            neuro1: true,
            neuro2: true,
            neuro3: true,
            neuro4: true,
            neuro5: true,
            neuro6: true,
            neuro7: true,
            psych1: true,
            plan1: false,
            plan2: false,
            plan3: false,
            plan4: false,
        });
        setReport("");
        setAssessment("");
        setChiefComplaint("");
        setComplaint("");
        setHistory("");
        setStateType("");
    };

    const {
        gen1, gen2, gen3, gen4,
        heent1, heent2, heent3, heent4, heent5, heent6, heent7, heent8, heent9, heent10, heent11, heent12,
        neck1, neck2, neck3, neck4, neck5, neck6,
        resp1, resp2, resp3, resp4, resp5,
        cardio1, cardio2,
        abd1, abd2, abd3,
        back1, back2,
        extrem1, extrem2, extrem3, extrem4,
        neuro1, neuro2, neuro3, neuro4, neuro5, neuro6, neuro7,
        psych1,
        plan1, plan2, plan3, plan4,
    } = state;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant={"body2"} gutterBottom component={"div"}>{FormTxt.disclaimer}</Typography>

            <TextField sx={{m: 2, marginLeft: 0}} id={"chief-complaint"} value={chiefComplaint} onChange={handleChiefComplaintChange} label="Chief Complaint"/>

            <Box sx={{
                display:'flex',
                flexDirection:'column',
                m: 2,
            }}>
                <FormLabel sx={{marginLeft: -2}} component="general"> HISTORY OF PRESENT ILLNESSES: </FormLabel>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 1,
                }}>

                    <Typography sx={{marginRight: 2, marginTop: 1}} variant={"body1"} gutterBottom component={"div"}>The patient is a</Typography>
                    <TextField
                        required
                        id={"chief-complaint"}
                        value={type}
                        onChange={handleTypeChange}
                        variant={"standard"}/>
                    <Typography sx={{marginLeft: 2, marginRight: 2, marginTop: 1}} variant={"body1"} gutterBottom component={"div"}>with a history of</Typography>
                    <TextField
                        required
                        id={"chief-complaint"}
                        value={history}
                        onChange={handleHistoryChange}
                        variant={"standard"}/>
                    <Typography sx={{marginLeft: 2, marginRight: 2, marginTop: 1}} variant={"body1"} gutterBottom component={"div"}>complaining of a</Typography>
                    <TextField
                        required
                        id={"chief-complaint"}
                        value={complaint}
                        onChange={handleComplaintChange}
                        variant={"standard"}/>
                    <Typography sx={{marginLeft: 2, marginTop: 1}} variant={"body1"} gutterBottom component={"div"}>.</Typography>
                </Box>
            </Box>

            <Box sx={{
                display:'flex',
                flexDirection:'column',
                m: 2,
            }}>
                <FormLabel sx={{marginLeft: -2}} component="general"> REVIEW OF SYSTEMS: </FormLabel>
                <Typography sx={{marginBottom: 2, marginTop: 1}} variant={"body1"} gutterBottom component={"div"}>{FormTxt.reviewOfSystems}</Typography>
            </Box>


            <FormLabel component="general"> PHYSICAL EXAM: </FormLabel>

            {/*---------GENERAL---------*/}
            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                <FormLabel component="general"> GENERAL </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={gen1} onChange={handleCheckboxChange} name="gen1" />}
                        label={FormTxt.gen1txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={gen2} onChange={handleCheckboxChange} name="gen2" />}
                        label={FormTxt.gen2txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={gen3} onChange={handleCheckboxChange} name="gen3" />}
                        label={FormTxt.gen3txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={gen4} onChange={handleCheckboxChange} name="gen4" />}
                        label={FormTxt.gen4txt}/>
                </FormGroup>
            </FormControl>

            {/*---------HEENT---------*/}
            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                <FormLabel component="heent"> HEENT </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={heent1} onChange={handleCheckboxChange} name="heent1" />}
                        label={FormTxt.heent1txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={heent2} onChange={handleCheckboxChange} name="heent2" />}
                        label={FormTxt.heent2txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={heent3} onChange={handleCheckboxChange} name="heent3" />}
                        label={FormTxt.heent3txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={heent4} onChange={handleCheckboxChange} name="heent4" />}
                        label={FormTxt.heent4txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={heent5} onChange={handleCheckboxChange} name="heent5" />}
                        label={FormTxt.heent5txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={heent6} onChange={handleCheckboxChange} name="heent6" />}
                        label={FormTxt.heent6txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={heent7} onChange={handleCheckboxChange} name="heent7" />}
                        label={FormTxt.heent7txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={heent8} onChange={handleCheckboxChange} name="heent8" />}
                        label={FormTxt.heent8txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={heent9} onChange={handleCheckboxChange} name="heent9" />}
                        label={FormTxt.heent9txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={heent10} onChange={handleCheckboxChange} name="heent10" />}
                        label={FormTxt.heent10txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={heent11} onChange={handleCheckboxChange} name="heent11" />}
                        label={FormTxt.heent11txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={heent12} onChange={handleCheckboxChange} name="heent12" />}
                        label={FormTxt.heent12txt}/>
                </FormGroup>
            </FormControl>

            {/*---------NECK---------*/}
            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                <FormLabel component="neck"> NECK </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={neck1} onChange={handleCheckboxChange} name="neck1" />}
                        label={FormTxt.neck1txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={neck2} onChange={handleCheckboxChange} name="neck2" />}
                        label={FormTxt.neck2txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={neck3} onChange={handleCheckboxChange} name="neck3" />}
                        label={FormTxt.neck3txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={neck4} onChange={handleCheckboxChange} name="neck4" />}
                        label={FormTxt.neck4txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={neck5} onChange={handleCheckboxChange} name="neck5" />}
                        label={FormTxt.neck5txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={neck6} onChange={handleCheckboxChange} name="neck6" />}
                        label={FormTxt.neck6txt}/>
                </FormGroup>
            </FormControl>

            {/*---------RESPIRATORY---------*/}
            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                <FormLabel component="respiratory"> RESPIRATORY </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={resp1} onChange={handleCheckboxChange} name="resp1" />}
                        label={FormTxt.resp1txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={resp2} onChange={handleCheckboxChange} name="resp2" />}
                        label={FormTxt.resp2txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={resp3} onChange={handleCheckboxChange} name="resp3" />}
                        label={FormTxt.resp3txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={resp4} onChange={handleCheckboxChange} name="resp4" />}
                        label={FormTxt.resp4txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={resp5} onChange={handleCheckboxChange} name="resp5" />}
                        label={FormTxt.resp5txt}/>
                </FormGroup>
            </FormControl>

            {/*---------CARDIOVASCULAR---------*/}
            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                <FormLabel component="cardiovascular"> CARDIOVASCULAR </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={cardio1} onChange={handleCheckboxChange} name="cardio1" />}
                        label={FormTxt.cardio1txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={cardio2} onChange={handleCheckboxChange} name="cardio2" />}
                        label={FormTxt.cardio2txt}/>
                </FormGroup>
            </FormControl>

            {/*---------ABDOMINAL---------*/}
            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                <FormLabel component="abdominal"> ABDOMINAL </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={abd1} onChange={handleCheckboxChange} name="abd1" />}
                        label={FormTxt.abd1txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={abd2} onChange={handleCheckboxChange} name="abd2" />}
                        label={FormTxt.abd2txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={abd3} onChange={handleCheckboxChange} name="abd3" />}
                        label={FormTxt.abd3txt}/>
                </FormGroup>
            </FormControl>

            {/*---------BACK---------*/}
            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                <FormLabel component="back"> BACK </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={back1} onChange={handleCheckboxChange} name="back1" />}
                        label={FormTxt.back1txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={back2} onChange={handleCheckboxChange} name="back2" />}
                        label={FormTxt.back2txt}/>
                </FormGroup>
            </FormControl>

            {/*---------EXTREMITIES---------*/}
            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                <FormLabel component="extremities"> EXTREMITIES </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={extrem1} onChange={handleCheckboxChange} name="extrem1" />}
                        label={FormTxt.extremities1txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={extrem2} onChange={handleCheckboxChange} name="extrem2" />}
                        label={FormTxt.extremities2txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={extrem3} onChange={handleCheckboxChange} name="extrem3" />}
                        label={FormTxt.extremities3txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={extrem4} onChange={handleCheckboxChange} name="extrem4" />}
                        label={FormTxt.extremities4txt}/>
                </FormGroup>
            </FormControl>

            {/*---------NEUROLOGICAL---------*/}
            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                <FormLabel component="neurological"> NEUROLOGICAL </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={neuro1} onChange={handleCheckboxChange} name="neuro1" />}
                        label={FormTxt.neuro1txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={neuro2} onChange={handleCheckboxChange} name="neuro2" />}
                        label={FormTxt.neuro2txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={neuro3} onChange={handleCheckboxChange} name="neuro3" />}
                        label={FormTxt.neuro3txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={neuro4} onChange={handleCheckboxChange} name="neuro4" />}
                        label={FormTxt.neuro4txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={neuro5} onChange={handleCheckboxChange} name="neuro5" />}
                        label={FormTxt.neuro5txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={neuro6} onChange={handleCheckboxChange} name="neuro6" />}
                        label={FormTxt.neuro6txt}/>
                    <FormControlLabel
                        control={<Checkbox checked={neuro7} onChange={handleCheckboxChange} name="neuro7" />}
                        label={FormTxt.neuro7txt}/>
                </FormGroup>
            </FormControl>

            {/*---------PSYCH---------*/}
            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                <FormLabel component="psych"> PSYCH </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={psych1} onChange={handleCheckboxChange} name="psych1" />}
                        label={FormTxt.psych1txt}/>
                </FormGroup>
            </FormControl>

            {/*---------ASSESSMENT---------*/}
            <TextField sx={{m: 2, marginLeft: 0}} id={"assessment"} value={assessment} onChange={handleAssessmentChange} label="Assessment"/>

            {/*---------PLAN---------*/}
            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                <FormLabel sx={{ marginLeft: -2 }} component="plan"> PLAN: </FormLabel>
                <Typography sx={{m: 1, marginLeft: 0}} variant={"body1"} gutterBottom component={"div"}>{FormTxt.planMainTxt}</Typography>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={plan1} onChange={handleCheckboxChange} name="plan1" />}
                        label={FormTxt.planTxt1}/>
                    <FormControlLabel
                        control={<Checkbox checked={plan2} onChange={handleCheckboxChange} name="plan2" />}
                        label={FormTxt.planTxt2}/>
                    <FormControlLabel
                        control={<Checkbox checked={plan3} onChange={handleCheckboxChange} name="plan3" />}
                        label={FormTxt.planTxt3}/>
                    <FormControlLabel
                        control={<Checkbox checked={plan4} onChange={handleCheckboxChange} name="plan4" />}
                        label={FormTxt.planTxt4}/>
                </FormGroup>
                <Typography sx={{m: 1, marginLeft: 0}} variant={"body1"} gutterBottom component={"div"}>{FormTxt.planMainTxt2}</Typography>
            </FormControl>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Button variant="outlined" onClick={onGenerateReport}>Generate Report</Button>
                <Button sx={{marginLeft: 1}} variant="outlined" onClick={onResetForm}>Reset Form</Button>
            </Box>
            <TextField sx={{m: 2, marginLeft: 0, }} multiline id={"report"} value={report} onChange={handleReportChange} label="Report"/>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Button variant="outlined">Save Report</Button>
            </Box>
        </Box>
    );
};

export default InputNewPatientVisit;