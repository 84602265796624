import React, { useState } from "react";
import "./Sidebar.css";
// MATERIAL UI
import Box from "@mui/material/Box";
// ASSETS
// import logo from "../assets/icons/DXMD.svg";
import logo from '../assets/icons/doublecheck-logo-cropped.svg'

import ActivityIcon from "../assets/icons/Activity.svg";
import ActivitySelected from "../assets/icons/ActivitySelected.svg";

import SettingIcon from "../assets/icons/Setting.svg";
import SettingSelected from '../assets/icons/SettingSelected.svg';
import logoutIcon from "../assets/icons/Logout.svg";
import ProfileIcon from '../assets/icons/Profile.svg';
import ProfileSelected from '../assets/icons/ProfileSelected.svg';
import { NavLink } from "react-router-dom";

const Sidebar = (props) => {
  const [showSideBar, setShowSidebar] = useState(false);

  return (
    <>
      <Box className={`sidebar ${showSideBar ? "active_sidebar" : ""}`}>
          <img src={logo} alt="logo" />
          <br />
        <ul className="nav_list">
          <li>
            <img src={props.selectedScreen === 'Records' ? ActivitySelected : ActivityIcon} alt="icon" />
            <div
                  style={props.selectedScreen === 'Records' ? {color: '#ff3386'} : null}
                  onClick={() => props.onDrawerItemClick('Records')}
              >
              Dashboard
            </div>
          </li>
          <li>
            <img src={props.selectedScreen === 'My Profile' ? ProfileSelected : ProfileIcon} alt="icon" style={{marginLeft: -5}} />
              <div
                  style={props.selectedScreen === 'My Profile' ? {color: '#ff3386'} : null}
                  onClick={() => props.onDrawerItemClick('My Profile')}
              >
                  My Profile
              </div>
          </li>
          <li>
            <img src={props.selectedScreen === 'Settings' ? SettingSelected : SettingIcon} alt="icon" />
              <div
                  style={props.selectedScreen === 'Settings' ? {color: '#ff3386'} : null}
                  onClick={() => props.onDrawerItemClick('Settings')}
              >
                  Settings
              </div>
          </li>
          <li>
            <img src={logoutIcon} alt="icon" />
            <div
              style={props.selectedScreen === 'Logout' ? {color: '#ff3386'} : null}
              onClick={() => props.onLogout()}
            >
              Log out
            </div>
          </li>
        </ul>
      </Box>

    </>
  );
};

export default Sidebar;
