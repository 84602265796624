import React, { useEffect, useState } from "react";
import {
    AgoraVideoPlayer,
    createClient,
    createMicrophoneAndCameraTracks,
} from "agora-rtc-react";
import Button from '@mui/material/Button';
import * as PropTypes from 'prop-types';
import {store} from "../../../redux/store";

const config = {
    mode: "rtc",
    codec: "vp8",
    appId: "cafb5f80bfa0473299cf6c823521ff87",
};

const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const VideoAppointment = (props) => {
    const [users, setUsers] = useState([]);
    const [start, setStart] = useState(false);
    const client = useClient();
    const {ready, tracks} = useMicrophoneAndCameraTracks();

    const [trackState, setTrackState] = useState({
        video: true,
        audio: true
    });

    const mute = async (type) => {
        if (type === "audio") {
            await tracks[0].setEnabled(!trackState.audio);
            setTrackState((ps) => {
                return { ...ps, audio: !ps.audio };
            });
        } else if (type === "video") {
            await tracks[1].setEnabled(!trackState.video);
            setTrackState((ps) => {
                return { ...ps, video: !ps.video };
            });
        }
    };

    const leaveChannel = async () => {
        await client.leave();
        client.removeAllListeners();
        tracks[0].close();
        tracks[1].close();
        setStart(false);
        props.onLeave();
    };

    useEffect(() => {
        let init = async (name) => {
            console.log("init", name);
            client.on("user-published", async (user, mediaType) => {
                await client.subscribe(user, mediaType);
                
                if (mediaType === "video") {
                    setUsers([...users, user]);
                }
                if (mediaType === "audio") {
                    user.audioTrack?.play();
                }
                // console.log("subscribe success", mediaType, users);
            });

            client.on("user-unpublished", (user, type) => {
                console.log("unpublished", user, type);
                if (type === "audio") {
                    user.audioTrack?.stop();
                }
                if (type === "video") {
                    setUsers((prevUsers) => {
                        return prevUsers.filter((User) => User.uid !== user.uid);
                    });
                }
            });

            client.on("user-left", (user) => {
                console.log("leaving", user);
                setUsers((prevUsers) => {
                    return prevUsers.filter((User) => User.uid !== user.uid);
                });
            });            

            console.log("PROPS", props);
            
            //props.agoraToken


            console.log("JOINING", name, props.agoraToken)

            //change to userID later - store.getState().userID
            await client.join(config.appId, name, props.agoraToken, store.getState().userID);
            // if (tracks) await client.publish([tracks[0], tracks[1]]);
            if (tracks[0]) await client.publish(tracks[0]);
            if (tracks[1]) await client.publish(tracks[1]);

            setStart(true);
        };

        if (ready && tracks) {
            console.log("init ready");
            init(props.channel);
        }

    }, [client, ready, tracks]);

    return (
        <div>
            {ready && tracks && start && (
                <div className="controls">
                    <Button className={trackState.audio ? "on" : ""}
                            onClick={() => mute("audio")}>
                        {trackState.audio ? "MuteAudio" : "UnmuteAudio"}
                    </Button>
                    <Button className={trackState.video ? "on" : ""}
                            onClick={() => mute("video")}>
                        {trackState.video ? "MuteVideo" : "UnmuteVideo"}
                    </Button>
                    {<Button onClick={() => leaveChannel()}>Leave</Button>}
                </div>
            )}
            {console.log("User", users)}
            {start && tracks && <Videos users={users} tracks={tracks} />}
        </div>
    );
};

VideoAppointment.propTypes = {
    onLeave: PropTypes.func,
    channel: PropTypes.string
};

const Videos = (props) => {
    const { users, tracks } = props;
    return (
        <div>
            <div id="videos">
                <AgoraVideoPlayer className='vid' videoTrack={tracks[1]} style={{height: '500px', width: '500px'}} />
                {users.length > 0 &&
                    users.map((user) => {
                        if (user.videoTrack) {
                            console.log("Trying", user)
                            return (
                                <AgoraVideoPlayer className='vid' videoTrack={user.videoTrack} style={{height: '500px', width: '500px'}} key={user.uid} />
                            );
                        } else return null;
                    })}
            </div>
        </div>
    );
};

export default VideoAppointment;