import * as React from 'react';
import { useState, useContext } from 'react';
import * as PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AccountContext } from "../../api/authorization/Account";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

const theme = createTheme();

function ChangeEmail(props) {

    const { getSession, authenticate } = useContext(AccountContext);

    const [password, setPassword] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        getSession(false)
            .then((res) => {
                const user = res.User;
                authenticate(user.getUsername(), password)
                    .then(() => {
                        const attributes = [
                            new CognitoUserAttribute({ Name: "email", Value: newEmail })
                        ];
                        user.updateAttributes(attributes, (err, results) => {
                            if (err) {
                                alert(err.message || JSON.stringify(err));
                                setMessage("Error changing email.")
                            } else {
                                setMessage("Email successfully changed.")
                            }
                        })
                    }).catch((err) => {
                        setMessage("Error authenticating user.")
                    });
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Change Email
                    </Typography>
                    <Typography color="secondary">
                        {message}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="New Email"
                            autoComplete={'off'}
                            autoFocus
                            value={newEmail}
                            onChange={(event) => { setNewEmail(event.target.value) }}
                        />
                        <TextField
                            margin="normal"
                            autoComplete={'off'}
                            required
                            fullWidth
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(event) => { setPassword(event.target.value) }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Update Email
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

ChangeEmail.propTypes = {
    isPatient: PropTypes.bool.isRequired
};

export default ChangeEmail;