import React, {useState, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Typography from "@mui/material/Typography/Typography";
import {pronouns, genders} from "../../../constants/Constants";

function EditBackground(props) {
    return (
        <Box sx={{m: 4}}>
            <Typography sx={{m: 1}} variant={'h6'}> Edit Background </Typography>
            <Grid container spacing={3} >
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="Preferred Name"
                        value={props.preferredName}
                        onChange={props.onPreferredNameChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        options={genders}
                        value={props.gender}
                        onChange={props.onGenderChange}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    fullWidth
                                    id={"gender"}
                                    label={"Gender"}
                                    autoFocus
                                    {...params}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        options={pronouns}
                        value={props.pronouns}
                        onChange={props.onPronounsChange}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    fullWidth
                                    id={"pronouns"}
                                    name={"pronouns"}
                                    label={"Pronouns"}
                                    autoFocus
                                    {...params}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="Age"
                        value={props.age}
                        onChange={props.onAgeChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="Height"
                        value={props.height}
                        onChange={props.onHeightChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="Weight"
                        value={props.weight}
                        onChange={props.onWeightChange}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

EditBackground.propTypes = {
    height: PropTypes.number,
    weight: PropTypes.number,
    age: PropTypes.number,
    pronouns: PropTypes.string,
    preferredName: PropTypes.string,
    gender: PropTypes.string,
    onHeightChange: PropTypes.func,
    onWeightChange: PropTypes.func,
    onAgeChange: PropTypes.func,
    onPronounsChange: PropTypes.func,
    onPreferredNameChange: PropTypes.func,
    onGenderChange: PropTypes.func
};

export default EditBackground;