import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import PastMedicalRecords from "../components/Profile/PatientProfileComponents/PastMedicalRecords";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography/Typography";
import {
  getPatientUploads,
  uploadPatientUpload,
  createPatientCase,
  getPatientCase,
  updatePatientCaseByPatient,
} from "../api/apigateway/apigateway";
import { store } from "../redux/store";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import dicomParser from "dicom-parser";

const dicomBytes = {
  patient_name: {
    name: "Patient Name",
    bytes: "x00100010"
  },
  patient_id: {
    name: "Patient ID",
    bytes: "x00100020"
  },
  patient_birth_date: {
    name: "Patient Birth Date",
    bytes: "x00100030"
  },
  patient_sex: {
    name: "Patient Sex",
    bytes: "x00100040"
  },
  study_description: {
    name: "Study Description",
    bytes: "x00081030"
  },
  protocol_name: {
    name: "Protocol Name",
    bytes: "x00181030"
  },
  accession_number: {
    name: "Accession #",
    bytes: "x00080050"
  },
  study_id: {
    name: "Study ID",
    bytes: "x00200010"
  },
  study_date: {
    name: "Study Date",
    bytes: "x00080020"
  },
  study_time: {
    name: "Study Time",
    bytes: "x00080030"
  },
  series_description: {
    name: "Series Description",
    bytes: "x0008103e"
  },
  series_number: {
    name: "Series #",
    bytes: "x00200011"
  },
  modality: {
    name: "Modality",
    bytes: "x00080060"
  },
  body_part: {
    name: "Body Part",
    bytes: "x00180015"
  },
  series_date: {
    name: "Series Date",
    bytes: "x00080021"
  },
  series_time: {
    name: "Series Time",
    bytes: "x00080031"
  },
  instance_number: {
    name: "Instance #",
    bytes: "x00200013"
  },
  acquisition_number: {
    name: "Acquisition #",
    bytes: "x00200012"
  },
  acquisition_date: {
    name: "Acquisition Date",
    bytes: "x00080022"
  },
  acquisition_time: {
    name: "Acquisition Time",
    bytes: "x00080032"
  },
  content_date: {
    name: "Content Date",
    bytes: "x00080023"
  },
  content_time: {
    name: "Content Time",
    bytes: "x00080033"
  },
  rows: {
    name: "Rows",
    bytes: "x00280010"
  },
  columns: {
    name: "Columns",
    bytes: "x00280011"
  },
  photometric_interpretation: {
    name: "Photometric Interpretation",
    bytes: "x00280004"
  },
  image_type: {
    name: "Image Type",
    bytes: "x00080008"
  },
  bits_allocated: {
    name: "Bits Allocated",
    bytes: "x00280100"
  },
  bits_stored: {
    name: "Bits Stored",
    bytes: "x00280101"
  },
  high_bit: {
    name: "HighBit",
    bytes: "x00280102"
  },
  pixel_representation: {
    name: "Pixel Representation (0=us)",
    bytes: "x00280103"
  },
  rescale_slope: {
    name: "Rescale Slope",
    bytes: "x00281053"
  },
  rescale_intercept: {
    name: "Rescale Intercept",
    bytes: "x00281052"
  },
  image_position_patient: {
    name: "Image Position Patient",
    bytes: "x00200032"
  },
  image_orientation_patient: {
    name: "Image Orientation Patient",
    bytes: "x00200037"
  },
  pixel_spacing: {
    name: "Pixel Spacing",
    bytes: "x00280030"
  },
  samples_per_pixel: {
    name: "Samples Per Pixel",
    bytes: "x00280002"
  },
  manufacturer: {
    name: "Manufacturer",
    bytes: "x00080070"
  },
  model: {
    name: "Model",
    bytes: "x00081090"
  },
  station_name: {
    name: "Station Name",
    bytes: "x00081010"
  },
  ae_title: {
    name: "AE Title",
    bytes: "x00020016"
  },
  institution_name: {
    name: "Institution Name",
    bytes: "x00080080"
  },
  software_version: {
    name: "Software Version",
    bytes: "x00181020"
  },
  implementation_version_name: {
    name: "Implementation Version Name",
    bytes: "x00020013"
  },
  study_uid: {
    name: "Study UID",
    bytes: "x0020000d"
  },
  series_uid: {
    name: "Series UID",
    bytes: "x0020000e"
  },
  instance_uid: {
    name: "Instance UID",
    bytes: "x00080018"
  },
  sop_class_uid: {
    name: "SOP Class UID",
    bytes: "x00080016"
  },
  transfer_syntax_uid: {
    name: "Transfer Syntax UID",
    bytes: "x00020010"
  },
  frame_of_reference_uid: {
    name: "Frame of Reference UID",
    bytes: "x00200052"
  }
};

// Example usage
console.log(dicomBytes.study_id.name); // Output: "Study ID"
console.log(dicomBytes.study_id.bytes); // Output: "x00200010"


function PatientRecords(props) {
  const [records, setRecords] = useState(null);

  const [patientId] = useState(store.getState().userID);
  const [files, setFiles] = useState([]);
  const [recordNotes, setRecordNotes] = useState("");
  const [date, setDate] = useState(new Date());
  const [caseInfo, setCaseInfo] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [currentStudyType, setCurrentStudyType] = useState("");


  useEffect(() => {
    getPatientUploads(patientId, setRecords);
    getPatientCase(patientId, setCaseInfo, setRecordNotes);
  }, []);

  useEffect(() => {
    console.log('files', files);
    if (files.length) {
      var reader = new FileReader();
      reader.onload = function () {
        var arrayBuffer = reader.result;
        // Here we have the file data as an ArrayBuffer.  dicomParser requires as input a
        // Uint8Array so we create that here
        var byteArray = new Uint8Array(arrayBuffer);
        const options = { TransferSyntaxUID: '1.2.840.10008.1.2' };
        // Parse the byte array to get a DataSet object that has the parsed contents
        var dataSet = dicomParser.parseDicom(byteArray, options);
        var studyInstanceUid = dataSet.string('x0020000d');
        console.log('study instance uid', studyInstanceUid);
        console.log('study description', dataSet.string('x00081030'));
        console.log(dicomBytes.patient_name.name, dataSet.string(dicomBytes.patient_name.bytes));
      }
      reader.readAsArrayBuffer(files[0]);
    }
  }, [files]);

  const handleDrop = (e) => {
    e.preventDefault();
    console.log(
      "handleDrop files",
      files.concat(Array.from(e.dataTransfer.files))
    );
    setFiles(files.concat(Array.from(e.dataTransfer.files)));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileChange = (e) => {
    console.log(
      "handleFileChange files",
      files.concat(Array.from(e.target.files))
    );
    setFiles(files.concat(Array.from(e.target.files)));
  };

  const handleRecordNotesChange = (e) => {
    setRecordNotes(e.target.value);
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const setCompleteCaseInfo = (caseInfo) => {
    setCaseInfo(caseInfo);
    setRecordNotes(caseInfo.caseNotes);
  };

  const updatePatientRecords = () => {
    getPatientUploads(patientId, setRecords);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (caseInfo && files.length == 0) {
      updatePatientCaseByPatient(
        {
          patientId: patientId,
          caseNotes: recordNotes,
          caseID: caseInfo.caseID,
        },
        setCompleteCaseInfo
      );
      return;
    }
    if (files.length == 0 || !recordNotes || !date) {
      alert("Please fill out all fields.");
      return;
    }
    setLoading(true);
    setLoadingMessage("Uploading...");
    if (caseInfo == null) {
      createPatientCase(patientId, recordNotes).then((newCaseInfo) => {
        setCaseInfo(newCaseInfo);
        uploadPatientUpload(
          files,
          date,
          patientId,
          newCaseInfo,
          currentStudyType,
          uuidv4(),
          setLoading,
          setLoadingMessage,
          updatePatientRecords
        );
        // getPatientUploads(patientId, setRecords);
      });
    } else {
      uploadPatientUpload(
        files,
        date,
        patientId,
        caseInfo,
        currentStudyType,
        uuidv4(),
        setLoading,
        setLoadingMessage,
        updatePatientRecords
      );
      // getPatientUploads(patientId, setRecords);
    }
  };

  return (
    <Paper sx={{ padding: 2, width: "650px" }}>
      <Typography variant="h3" gutterBottom align="center">
        Your Case
      </Typography>
      <Typography align="center" variant="subtitle1">You can drag and drop all files from your study disc.</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "750px",
          margin: "0 auto",
        }}
      >
        <TextField
          id="outlined-multiline-flexible"
          label="Case Notes"
          placeholder="What do you want to tell the doctor?"
          multiline
          maxRows={20}
          minRows={3}
          value={recordNotes}
          onChange={handleRecordNotesChange}
          sx={{ mt: 3 }}
        />
        <br />
        <div onDrop={handleDrop} onDragOver={handleDragOver}>
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            multiple
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
              border: "2px dashed grey",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => document.querySelector('input[type="file"]').click()}
          >
            <Typography variant="body1" color="text.secondary">
              Drag and drop or click to upload files
            </Typography>
          </Box>
          <br />
          {files.length > 0 && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file, index) => (
                    <TableRow key={file.name}>
                      <TableCell>{file.name}</TableCell>
                      <TableCell>
                        <Button>Delete</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <br />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date"
            value={date}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
            sx={{ mt: 3 }}
          />
        </LocalizationProvider>
        <hr />
        <Button
          disabled={!files || !recordNotes}
          variant="contained"
          onClick={handleSubmit}
          sx={{ mt: 3 }}
        >
          {caseInfo ? "Update" : "Upload"}
        </Button>
        <br />
        {isLoading && <CircularProgress sx={{ marginLeft: "47%" }} />}
        <p style={{ marginLeft: "47%" }}>{loadingMessage}</p>
      </Box>
      <hr />
      <PastMedicalRecords records={records} caseInfo={caseInfo} />
    </Paper>
  );
}

export default PatientRecords;
