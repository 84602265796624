import React, { useState } from "react";
import "./RightSidebar.css";
// MATERIAL UI
import Box from "@mui/material/Box";
// ASSETS
import YellowStar from "../assets/icons/YellowStar.svg";
import stat1 from "../assets/icons/stat1.svg";
import stat2 from "../assets/icons/stat2.svg";
import stat3 from "../assets/icons/stat3.svg";
import stat4 from "../assets/icons/stat4.svg";
import stat5 from "../assets/icons/stat5.svg";
import UserImg from "../assets/images/UserImg.svg";
import Chart from "../components/deprecated/chart/Chart";
import menuIcon from "../assets/icons/menu.png";
import closeIcon from "../assets/icons/close.png";
import Avatar from '@mui/material/Avatar';
//COMPONENT
import SelectBox from "../components/deprecated/Dashboard/SelectBox";
// DATA
import { selectOptionsSidebar } from "./SlectBoxData";
import UserBox from "../components/deprecated/Dashboard/UserBox";
import Rating from "@mui/material/Rating/Rating";

const RightSidebar = (props) => {
  const [showSideBar, setShowSidebar] = useState(true);

  return (
    <>
      <Box
        className={`right_sidebar ${showSideBar ? "active_rightsidebar" : ""}`}
      >
        <Avatar src={props.profilePhoto ? props.profilePhoto : ""} sx={{width: 100, height: 100, alignSelf: 'center'}}  />
        <Box className="user_content">
          <h2>{props.name}</h2>
          {props?.isPatient != true &&
            <>
                <Rating align={'center'} sx={{mt: 1}} precision={0.5} value={props.rating || 0} readOnly/>
                <p>0 Reviews</p>
            </>
          }
        </Box>
        {/* <Box className="user_stats_wrapper">
          <UserBox
            classes="us_box1"
            img={stat2}
            text="DXMD"
            currency="$"
            value={props.dxmdBalance || 0}
          />
          <UserBox
            classes="us_box2"
            img={stat3}
            text="SOL"
            currency="◎"
            value={props.solanaBalance || 0} 
            onClick={props.clickSol}
          /> */}
          {/* <UserBox
            classes="us_box3"
            img={stat4}
            text="USDC"
            currency="$"
            value={props.usdcBalance || 0}
          />
          <UserBox
            classes="us_box4"
            img={stat5}
            text="SRM"
            currency="◎"
            value={props.serumBalance || 0}
          /> */}
        {/* </Box> */}
        {false && <><Box className="h_stats">
          <h2>
            <b>Patient Visit</b>
          </h2>
          <Box>
            <SelectBox
              color="#83879b"
              border="0"
              width="120px"
              fontWeight="300"
              backgroundColor="#fff"
              BorderRadius="0"
              defaultValue={2}
              data={selectOptionsSidebar}
            />
          </Box>
        </Box>
        <Box className="chart_wrap">
          <Chart />
        </Box></>}
      </Box>
    </>
  );
};

export default RightSidebar;
