import * as React from 'react';
import Paper from '@mui/material/Paper';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  WeekView,
  EditRecurrenceMenu,
  DragDropProvider,
  ConfirmationDialog,
  Toolbar,
  DateNavigator,
  TodayButton
} from '@devexpress/dx-react-scheduler-material-ui';
import { setDoctorAppointment, getDoctorAppointments } from "../../../api/apigateway/apigateway";
import { store } from '../../../redux/store';

export default class ProviderSchedule extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentDate: new Date(),

      addedAppointment: {},
      appointmentChanges: {},
      editingAppointment: undefined,
      doctorId: store.getState().userID,
      wallet: null,
      price: 0.001,
    };

    this.myRef = React.createRef();

    this.getSchedule = this.getSchedule.bind(this);
    this.commitChanges = this.commitChanges.bind(this);
    this.changeAddedAppointment = this.changeAddedAppointment.bind(this);
    this.changeAppointmentChanges = this.changeAppointmentChanges.bind(this);
    this.changeEditingAppointment = this.changeEditingAppointment.bind(this);
  }

  componentDidMount() {
    getDoctorAppointments(this.state.doctorId, this.getSchedule);
    // this.connectWallet();
  }

  // connectWallet = async (clicked = false) => {
  //   const { solana } = window;

  //   if (solana) {
  //     const response = await solana.connect();
  //     this.setState({ wallet: response.publicKey.toString() });
  //   } else if (clicked) {
  //     window.open("https://phantom.app/", "_blank")
  //   }
  // };

  getSchedule(data) {
    this.setState({ data });
  }

  changeAddedAppointment(addedAppointment) {
    this.setState({ addedAppointment });
  }

  changeAppointmentChanges(appointmentChanges) {
    this.setState({ appointmentChanges });
  }

  changeEditingAppointment(editingAppointment) {
    this.setState({ editingAppointment });
  }

  commitChanges({ added, changed, deleted }) {
    if (this.state.wallet == null) {
      // this.connectWallet(true);
    } else {
      this.setState((state) => {
        let { data } = state;
        if (added) {
          let price = prompt("Please set the price of the appointment. It must be a number.", "0.25");
          const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
          const newAppointment = { id: startingAddedId.toString(), price: price, doctorId: this.state.doctorId, booked: "false", wallet: this.state.wallet, ...added };
          data = [...data, newAppointment];
          setDoctorAppointment(this.state.doctorId, "ADD", newAppointment, this.state.price, this.state.wallet);
        }
        if (changed) {
          data = data.map(appointment => (
            changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment));
          setDoctorAppointment(this.state.doctorId, "UPDATE", changed, this.state.price, this.state.wallet);
        }
        if (deleted !== undefined) {
          data = data.filter(appointment => appointment.id !== deleted);
          setDoctorAppointment(this.state.doctorId, "DELETE", deleted, this.state.price, this.state.wallet);
        }
        return { data };
      });
    }
  }

  render() {
    const {
      currentDate, data, addedAppointment, appointmentChanges, editingAppointment,
    } = this.state;

    return (
      <Paper style={{ "width": "100%" }}>
        <Scheduler
          data={data}
          height={500}
        >
          <ViewState
            defaultCurrentDate={currentDate}
          />
          <EditingState
            onCommitChanges={this.commitChanges}
            addedAppointment={addedAppointment}
            onAddedAppointmentChange={this.changeAddedAppointment}
            appointmentChanges={appointmentChanges}
            onAppointmentChangesChange={this.changeAppointmentChanges}
            editingAppointment={editingAppointment}
            onEditingAppointmentChange={this.changeEditingAppointment}
          />
          <WeekView
            startDayHour={6}
            endDayHour={24}
          />
          <EditRecurrenceMenu />
          <ConfirmationDialog />
          <Appointments />
          <AppointmentTooltip
            showOpenButton
            showDeleteButton
          />
          <DragDropProvider />
          <AppointmentForm />
          <Toolbar />
          {/* TODO (sid): Add price setting component here. */}
          <DateNavigator />
          <TodayButton />
        </Scheduler>
      </Paper>
    );
  }
}
