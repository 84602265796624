import * as React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
export default function SelectBox({
  color,
  border,
  fontWeight,
  backgroundColor,
  cursor,
  data,
  defaultValue,
  width,
}) {
  const useStyles = makeStyles({
    select: {
      color: color,
      border: border,
      width: width,
      fontWeight: fontWeight,
      backgroundColor: backgroundColor,
      cursor: cursor,
    },
  });
  const classes = useStyles();

  return (
    <Box>
      <FormControl>
        <Select
          className={`${classes.select} select_box`}
          //   onChange={handleChange}
          displayEmpty
          defaultValue={defaultValue}
          inputProps={{ "aria-label": "Without label" }}
        >
          {data.map((d) => {
            return (
              <MenuItem key={d.id} value={d.id}>
                {d.option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
