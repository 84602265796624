import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import logger from 'redux-logger';
import reducer from "../reducers";

const initialState = {
    userID: null,
    wallet: null
};

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(logger)));

export { store };