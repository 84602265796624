import React from 'react';

export const insuranceProviders = [
    'insurance1',
    'insurance2',
    'insurance3'
];

export const pronouns = [
    'She/Her/Hers',
    'He/Him/His',
    'They/Them/Theirs',
    'Per/Per/Pers',
    'Ze/Zir/Zirs',
    'Xe/Xem/Xyrs',
    'Ze/Hir/Hirs',
    'E/Em/Eirs',
    'Prefer Not to Say',
    'Other'
];

export const genders = [
    'Male',
    'Female',
    'Prefer Not To Say',
    'Other'
];

export const solanaNET = "mainnet-beta";