import React from 'react';
import { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import {
    Box,
    TableRow,
    TableCell,
    IconButton,
    Typography,
    Collapse,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PaginatedTable from '../../TableComponents/PaginatedTable';

const createData = (name, age, type, lastVisit, access) => {
    return {
        name,
        age,
        type,
        lastVisit,
        access,
    };
};

const rows = [
    createData('Provider 1', 'Nephrologist', 'current', new Date().getDate(), 'yes'),
    createData('Provider 2', 'Family Medicine', 'past', new Date().getDate(), 'yes'),
    createData('Provider 3', 'Cardiologist', 'past', new Date().getDate(), 'no'),
    createData('Provider 4', 'Cardiologist', 'past', new Date().getDate(), 'no'),
    createData('Provider 5', 'Primary Care', 'future', new Date().getDate(), 'yes'),
    createData('Provider 6', 'Dermatologist', 'future', new Date().getDate(), 'yes'),
];

const headCells = [
    {
        id: 'collapser',
        numeric: false,
        label: ''
    },
    {
        id: 'name',
        numeric: false,
        label: 'Provider Name',
    },
    {
        id: 'type',
        numeric: true,
        label: 'Type',
    },
    {
        id: 'status',
        numeric: true,
        label: 'Status',
    },
    {
        id: 'lastVisit',
        numeric: true,
        label: 'Last Visit',
    },
    {
        id: 'access',
        numeric: true,
        label: 'Record Access',
    },
];

function CustomTableRow(props) {
    const {row} = props;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow
                hover
                onClick={() => {}}
                key={row.name}
            >
                <TableCell>
                    <IconButton
                        size={'small'}
                        onClick={() => setOpen(!open)}
                    >
                        {open ?
                            <KeyboardArrowUpIcon/> :
                            <KeyboardArrowDownIcon/>
                        }
                    </IconButton>
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.name}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.type}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.status}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.lastVisit}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.access}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingTop: 0, paddingBottom: 0}} colSpan={6}>
                    <Collapse in={open} timeout={'auto'} unmountOnExit>
                        <Box sx={{padding: 1}}>
                            <Typography variant={'h6'}>
                                Patient Information
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>

    );
}

CustomTableRow.propTypes = {
    row: PropTypes.object.isRequired,
};

function ProviderList(props) {
    return (
        <PaginatedTable
            rows={rows}
            headCells={headCells}
            CustomTableRow={CustomTableRow}
            tableName={'Providers'}
            paginationOptions={[5, 10, 15, 25, 50]}
            maxHeight={600}
        />
    );
}

ProviderList.propTypes = {
    headCells: PropTypes.array,
    rows: PropTypes.array,
    CustomTableRow: PropTypes.element,
};

export default ProviderList;