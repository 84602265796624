import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import {insuranceProviders} from "../../../constants/Constants";
import Typography from "@mui/material/Typography/Typography";
import Button from "@mui/material/Button/Button";
import MedicationsInput from "../../LoginAndRegister/RegistrationForms/MedicationsInput";

function EditInsurance(props) {
    return (
        <Box sx={{m: 4}}>
            <Typography sx={{m: 1}} variant={'h6'}> Edit Insurance </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Autocomplete
                        options={insuranceProviders}
                        value={props.insurance}
                        onChange={props.onInsuranceChange}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    fullWidth
                                    label={"Insurance Provider"}
                                    autoFocus
                                    {...params}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Member ID"
                        value={props.memberID}
                        onChange={props.onMemberIDChange}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

EditInsurance.propTypes = {
    insurance: PropTypes.string,
    onInsuranceChange: PropTypes.func,
    memberID: PropTypes.string,
    onMemberIDChange: PropTypes.func
};

export default EditInsurance;

