export function setUser (user) {
    return {
        type: "UPDATE_USER",
        payload: {
            email: user.email,
            username: user.username,
            userID: user.sub,
            accessToken: user.accessToken,
            refreshToken: user.refreshToken,
            idToken: user.idToken,
            userPool: user.pool
        }
    }
}

export function setSession (Session) {
    return {
        type: "UPDATE_SESSION",
        payload: {
            email: Session.email,
            username: Session.User.username,
            userID: Session.accessToken.payload.sub,
            accessToken: Session.accessToken,
            refreshToken: Session.refreshToken,
            idToken: Session.idToken,
            userPool: Session.User.pool,
        }
    }
}

export function removeUser () {
    return {
        type: "REMOVE_USER",
        payload: {
            email: '',
            username: '',
            userID: '',
            accessToken: null,
            refreshToken: null,
            idToken: null,
            userPool: null,
        }
    }
}

export function updateWallet (key) {
    return {
        type: "UPDATE_WALLET",
        payload: {
            wallet: key
        }
    }
}