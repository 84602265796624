import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import PaginatedTable from "../../components/TableComponents/PaginatedTable";
import { TableCell, TableRow, Button } from "@mui/material";
import * as PropTypes from "prop-types";
import { store } from '../../redux/store';
import { getDoctorAppointments } from '../../api/apigateway/apigateway';
import { solanaNET } from '../../constants/Constants';

// const NETWORK = clusterApiUrl(solanaNET);
const headCells = [
    {
        id: 'name',
        numeric: false,
        label: 'Name',
    },
    {
        id: 'type',
        numeric: false,
        label: 'Type',
    },
    {
        id: 'date',
        numeric: true,
        label: 'Date'
    },
    {
        id: 'time',
        numeric: true,
        label: 'Time',
    },
    {
        id: 'price',
        numeric: true,
        label: 'Price',
    },
    // {
    //     id: 'payment',
    //     numeric: false,
    //     label: 'Payment Status',
    // },
];

// const getProvider = () => {
//     if ("solana" in window) {
//         const anyWindow = window;
//         const provider = anyWindow.solana;
//         if (provider.isPhantom) {
//             return provider;
//         }
//     }
//     return null;
// };

function AdminBilling() {
    const [rows, setRows] = useState([]);
    const provider = getProvider();
    const [logs, setLogs] = useState([]);
    // const connection = new Connection(NETWORK);
    const [walletAddress, setWalletAddress] = useState(null);
    const [addy, setAddy] = useState(null);
    const [balance, setBalance] = useState(0);
    const addLog = useCallback(
        (log) => setLogs((logs) => [...logs, "> " + log]),
        []
    );


    // const connectWallet = async (clicked=false) => {
    //     const { solana } = window;

    //     if (solana) {
    //         const response = await solana.connect();
    //         setAddy(response.publicKey);
    //         setWalletAddress(response.publicKey.toString());
    //     } else if (clicked) {
    //         window.open("https://phantom.app/", "_blank")
    //     }
    // };

    const renderNotConnectedContainer = () => (
        <div style={{textAlign: 'center'}}>
        <br />
        <button
            className="cta-button connect-wallet-button"
            // onClick={connectWallet}
            style={{ textAlign: 'center' }}
        >
            Connect to Wallet
        </button>
        <p style={{ textAlign: 'center' }}>A Phantom wallet must be connected in order to create and book appointments on the DXMD platform.</p>
        </div>
    );

    // const renderConnectedContainer = () => (
    //     <>
    //         <h4 style={{ textAlign: 'center' }}>
    //             Wallet Balance: {balance.toFixed(3)} SOL
    //         </h4>
    //         <p style={{ textAlign: 'center' }}>{provider.publicKey.toString() == "2shBLPKNBfnTXZ6ai4YQLMAFyMgdZGQqbDcFCTvFkaFs" ? "You are connected to the DXMD Care Wallet." : "You are not connected to the DXMD Care Wallet."}</p>
    //         <br />
    //         {/* <h5 style={{ textAlign: 'center' }}>
    //             Total unfulfilled payments: 0.01 SOL
    //         </h5> */}
    //         <br />
    //     </>
    // )



    useEffect(() => {
        const onLoad = async () => {
            await checkIfWalletIsConnected();
        };
        window.addEventListener("load", onLoad);
        return () => window.removeEventListener("load", onLoad);
    }, []);

    useEffect(() => {
        // connectWallet();
        getDoctorAppointments(store.getState().userID, setRows);
    }, []);

    function CustomTableRow(props) {
        const { row } = props;

        return (
            <TableRow
                hover
                onClick={() => { }}
                key={row.patientId}
            >
                <TableCell padding={'normal'} align={'left'}>
                    {row.patientId}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.title}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.startDate.substring(0, 10)}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.startDate.substring(12, 16)}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.price}
                </TableCell>
                {/* <TableCell padding={'normal'} align={'left'}>
                    Fulfilled
                </TableCell> */}
            </TableRow>
        );
    }

    return (
        <Box>
            <h1 style={{ textAlign: 'center' }}>Billing</h1>
            {walletAddress ? null : renderNotConnectedContainer()}
            <PaginatedTable
                tableName={'Billing'}
                headCells={headCells}
                rows={rows}
                CustomTableRow={CustomTableRow}
            />
        </Box>

    );
};

export default AdminBilling;