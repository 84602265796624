import React, {useState, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import School from '@mui/icons-material/School';
import Calendar from '@mui/icons-material/CalendarToday';
import Language from '@mui/icons-material/Language';
import Rating from '@mui/material/Rating';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import EditProfilePicture from "./EditProfilePicture";
import EditIcon from '@mui/icons-material/Edit';
import EditBio from "./ProviderProfileComponents/EditBio";
import EditBackground from "./ProviderProfileComponents/EditBackground";
import axios from 'axios';
import { store } from '../../redux/store';
import { getPicture } from '../../api/apigateway/apigateway';

function BackgroundStat(props) {
    return(
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 2
        }}>
            <IconButton> <props.icon/> </IconButton>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <Typography sx={{m: 0.5, mt: 0, fontWeight: 'bold'}} variant={'subtitle1'}>{props.label}</Typography>
                <Typography sx={{m: 0.5, mt: 0}} variant={'subtitle2'}>{props.value}</Typography>
            </Box>
        </Box>
    )
}

BackgroundStat.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func
};

function Background(props) {
    return(
        <Box sx={{m: 2}}>
            <Typography sx={{m: 1}} variant={'h6'}>Background</Typography>
            <BackgroundStat
                label={'Medical School'}
                value={props.school}
                icon={School}
                onClick={() => {}}
            />
            <BackgroundStat
                label={'Years of Experience'}
                value={props.yoe + " Years"}
                icon={Calendar}
                onClick={() => {}}
            />
            <BackgroundStat
                label={'Languages'}
                value={props.languages}
                icon={Language}
                onClick={() => {}}
            />
        </Box>
    )
}

Background.propTypes = {
    school: PropTypes.string,
    yoe: PropTypes.string,
    languages: PropTypes.string
};

function Bio(props) {
    return (
        <Box sx={{m: 2}}>
            <Typography sx={{m: 1}} variant={'h6'}>Bio</Typography>
            <Typography sx={{m: 1}} variant={'body2'}>{props.bio}</Typography>
        </Box>
    )
}

Bio.propTypes = {
    bio: PropTypes.string
};

function Reviews(props) {
    return (
        <Box sx={{m: 2}}>
            <Typography sx={{m: 1, mb: 0}} variant={'h6'}>Reviews</Typography>
            <Typography sx={{m: 1, mt: 0}} variant={'subtitle1'}>What patients are saying about me</Typography>
            <Typography sx={{m: 1, mb: 0, mt: 2}} variant={'h5'}>{props.rating}</Typography>
            <Rating sx={{ml: 0.5}} value={props.rating} precision={0.5} readOnly/>
            <Typography sx={{ml: 1, mt: 2}} variant={'subtitle1'}>{props.reviews?.length + ' Reviews'}</Typography>
            <List>
                {props.reviews && props.reviews.map((review, index) => {
                    return (
                        <ListItem>
                            <ListItemAvatar>
                                <Rating sx={{mr: 2}} size={'small'} value={review.rating} precision={0.5} readOnly/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={review.text}
                                secondary={review.gender + " | " + review.age + " | " + review.date}
                            />
                            <Divider/>
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}

Reviews.propTypes = {
    rating: PropTypes.number,
    reviews: PropTypes.array
};

const reviewsData = [
    {
        rating: 4.5,
        text: 'He was great.',
        date: 'January 2021',
        gender: 'M',
        age: 22
    },
    {
        rating: 2,
        text: 'He was eh.',
        date: 'November 2021',
        gender: 'F',
        age: 26
    },
    {
        rating: 5,
        text: 'He was excellent. Really helpful',
        date: 'February 2022',
        gender: 'F',
        age: 24
    },
];

function ProviderProfile(props) {

    const [profilePicture, setProfilePicture] = useState('');
    const [bio, setBio] = useState('');
    const [school, setSchool] = useState('');
    const [yoe, setYoe] = useState(0);
    const [languages, setLanguages] = useState('');
    const [rating, setRating] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const onLanguagesChange = (event) => {
        setLanguages(event.target.value);
    };

    const onYearsOfExperienceChange = (event) => {
        setYoe(event.target.value);
    };

    const onBioChange = (event) => {
        setBio(event.target.value);
    };

    const onSchoolChange = (event) => {
        setSchool(event.target.value);
    };

    const onEditClick = () => {
        setIsEditing(!isEditing);
    };

    const onSaveClick = () => {
        setIsEditing(!isEditing);
        //TODO: save new data to database and update parent state
        const data = {
            bio: bio,
            school: school,
            yoe: yoe,
            languages: languages
        };
        const ENDPOINT = 'https://ue7muhdaek.execute-api.us-west-1.amazonaws.com/dev/profile';
        axios.post(
            ENDPOINT,
            data
        ).then((result) => {
            console.log(result['status']);
        }).catch((e) =>
            console.error('Error saving profile data')
        );
    };

    useEffect(() => {
        //TODO: query API for profile data and update the state
        const ENDPOINT = 'https://ue7muhdaek.execute-api.us-west-1.amazonaws.com/dev/profile';
        axios.get(ENDPOINT, {
            params: {
                providerID: store.getState().userID
            }
        }).then((response) => {
            console.log(response);
            setFirstName(response.data['firstName']);
            setLastName(response.data['lastName']);
            setReviews(response.data['reviews']);
            setRating(response.data['rating']);
            setLanguages(response.data['languages']);
            setYoe(response.data['yoe']);
            setSchool(response.data['school']);
            setBio(response.data['bio']);
            getPicture(store.getState().userID, setProfilePicture);
        }).catch((err) => {
            console.log(err);
        })
    }, []);


    return (
        <Box sx={{
            m: 1,
            width: '90%'
        }}>
            {!isEditing &&
                <Paper sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    width: '100%',
                }}>
                    <Box sx={{
                        m: 1,
                        mr: 5
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={11}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Avatar sx={{ m: 1, width: 100, height: 100 }} src={profilePicture} />
                                    <Typography sx={{ ml: 2 }} variant={'h5'}>{"Dr. " + firstName + " " + lastName}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Fab
                                    color="secondary"
                                    aria-label="edit"
                                    onClick={onEditClick}
                                >
                                    <EditIcon />
                                </Fab>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider/>
                    <Bio bio={bio}/>
                    <Divider/>
                    <Background school={school} yoe={yoe} languages={languages}/>
                    <Divider/>
                    <Reviews rating={rating} reviews={reviews}/>
                </Paper>
            }
            {isEditing &&
                <Paper sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    width: '100%',
                }}>
                    <EditProfilePicture onSaveClick={onSaveClick} currentProfilePicture={profilePicture}/>
                    <Divider/>
                    <EditBio bio={bio} onBioChange={onBioChange}/>
                    <Divider/>
                    <EditBackground
                        school={school}
                        onSchoolChange={onSchoolChange}
                        languages={languages}
                        onLanguagesChange={onLanguagesChange}
                    />
                </Paper>
            }
        </Box>
    )
}

ProviderProfile.propTypes = {

};

export default ProviderProfile;