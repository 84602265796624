import React from 'react';
import { useState, useEffect } from 'react';
import ScheduleAppointment from './ScheduleAppointment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import UpcomingAppointments from "./UpcomingAppointments";
import VideoAppointment from '../../../components/deprecated/Video/VideoAppointment';

function PatientAppointments() {
    const [tabValue, setTabValue] = useState('1');
    const [currentAppointmentChannel, setCurrentAppointmentChannel] = useState('test');
    const [currentAgoraToken, setCurrentAgoraToken] = useState('');

    const handleTabValueChange = (event, newValue) => {
        setTabValue(newValue)
    };

    const onJoinCurrentAppointment = () => {
        setTabValue('2');
    };

    const onLeaveCurrentAppointment = () => {
        setTabValue('1');
    };

    const onCurrentAppointmentChannelChange = (newChannel) => {
        setCurrentAppointmentChannel(newChannel);
    };

    const onCurrentAgoraTokenChange = (newToken) => {
        setCurrentAgoraToken(newToken);
    };

    useEffect(() => {
        //TODO: Pull channel from the database and update the channel state
    });

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent:'flex-start', alignItems: 'flex-start', align:'left', ml:28}}>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs sx={{align: 'left'}} value={tabValue} onChange={handleTabValueChange}>
                        <Tab label={'Appointments'} index="1" value="1"/>
                        <Tab label={'Current'} index="2" value="2" disabled/>
                    </Tabs>
                </Box>
                <TabPanel index="1" value="1">
                    <UpcomingAppointments
                        onJoin={onJoinCurrentAppointment}
                        onChannelChange={onCurrentAppointmentChannelChange}
                        onTokenChange={onCurrentAgoraTokenChange}
                    />
                </TabPanel>
                <TabPanel index="2" value="2">
                    <VideoAppointment
                        channel={currentAppointmentChannel}
                        onLeave={onLeaveCurrentAppointment}
                        agoraToken={currentAgoraToken}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    );
}

export default PatientAppointments;