import React from 'react';
import { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import {
    Box,
    TableRow,
    TableCell,
    IconButton,
    Typography,
    Collapse,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PaginatedTable from '../../../TableComponents/PaginatedTable';
import { getPatientList } from '../../../../api/apigateway/apigateway';
import { store } from '../../../../redux/store';

const createData = (name, age, status, lastVisit, complaint) => {
    return {
        name,
        age,
        status,
        lastVisit,
        complaint,
    };
};

const rows = [
    createData('Patient 1', 25, 'current', new Date().getDate(), 'xyz'),
    createData('Patient 2', 2, 'past', new Date().getDate(), 'xyz'),
    createData('Patient 3', 25, 'past', new Date().getDate(), 'xyz'),
    createData('Patient 4', 25, 'past', new Date().getDate(), 'xyz'),
    createData('Patient 5', 25, 'future', new Date().getDate(), 'xyz'),
    createData('Patient 6', 25, 'future', new Date().getDate(), 'xyz'),
    createData('Patient 7', 25, 'current', new Date().getDate(), 'xyz'),
    createData('Patient 8', 25, 'past', new Date().getDate(), 'xyz'),
    createData('Patient 9', 25, 'past', new Date().getDate(), 'xyz'),
    createData('Patient 10', 25, 'future', new Date().getDate(), 'xyz'),
    createData('Patient 11', 25, 'past', new Date().getDate(), 'xyz'),
    createData('Patient 12', 25, 'future', new Date().getDate(), 'xyz'),
    createData('Patient 13', 25, 'current', new Date().getDate(), 'xyz'),
];

const headCells = [
    {
        id: 'collapser',
        numeric: false,
        label: ''
    },
    {
        id: 'name',
        numeric: false,
        label: 'Patient Name',
    },
    {
        id: 'age',
        numeric: true,
        label: 'Age',
    },
    {
        id: 'status',
        numeric: true,
        label: 'Status',
    },
    {
        id: 'lastVisit',
        numeric: true,
        label: 'Last Visit',
    },
    {
        id: 'complaint',
        numeric: true,
        label: 'Complaint',
    },
];

function CustomTableRow(props) {
    const {row} = props;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow
                hover
                onClick={() => {}}
                key={row.name}
            >
                <TableCell>
                    <IconButton
                        size={'small'}
                        onClick={() => setOpen(!open)}
                    >
                        {open ?
                            <KeyboardArrowUpIcon/> :
                            <KeyboardArrowDownIcon/>
                        }
                    </IconButton>
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.firstName + " " + row.lastName}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {Math.abs((new Date(Date.now() - new Date(row.dateOfBirth))).getUTCFullYear() - 1970)}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {"current"}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.lastVisit}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.complaint}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingTop: 0, paddingBottom: 0}} colSpan={6}>
                    <Collapse in={open} timeout={'auto'} unmountOnExit>
                        <Box sx={{padding: 1}}>
                            <Typography variant={'h6'}>
                                Patient Information
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>

    );
}

CustomTableRow.propTypes = {
    row: PropTypes.object.isRequired,
};

function PatientList(props) {

    const [rows, setRows] = useState([]);

    useEffect(() => {
        getPatientList(store.getState().userID, (rows) => setRows(rows))
    }, []);

    return (
        <PaginatedTable
            rows={rows}
            headCells={headCells}
            CustomTableRow={CustomTableRow}
            tableName={'Patients'}
            paginationOptions={[5, 10, 15, 25, 50]}
            maxHeight={600}
        />
    );
}

PatientList.propTypes = {
    headCells: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    CustomTableRow: PropTypes.element,
};

export default PatientList;