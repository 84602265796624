import * as React from 'react';
import * as PropTypes from 'prop-types';
import Grid from "@mui/material/Grid/Grid";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";

function ConditionsInput(props) {

    const onConditionNameChange = (event) => {
        props.onChange({
            condition: event.target.value,
            description: props.conditions[props.index].description,
            treatment: props.conditions[props.index].treatment
        });
    };

    const onDescriptionChange = (event) => {
        props.onChange({
            condition: props.conditions[props.index].condition,
            description: event.target.value,
            treatment: props.conditions[props.index].treatment
        });
    };

    const onTreatmentChange = (event) => {
        props.onChange({
            condition: props.conditions[props.index].condition,
            description: props.conditions[props.index].description,
            treatment: event.target.value
        });
    };

    const onItemDelete = () => {
        props.onDelete();
    };

    return (
        <Grid container sx={{mt: 3}}>
            <Grid item xs={9}>
                <TextField
                    fullWidth
                    label="Condition"
                    value={props.conditions[props.index].condition}
                    onChange={onConditionNameChange}
                    sx={{mt: 1}}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Button
                    variant={"contained"}
                    sx={{ml: 2, mt: 2}}
                    onClick={onItemDelete}
                    color={'secondary'}
                >
                    X
                </Button>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    sx={{mt: 1}}
                    fullWidth
                    label="Description"
                    value={props.conditions[props.index].description}
                    onChange={onDescriptionChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    sx={{mt: 1}}
                    fullWidth
                    label="Treatment"
                    value={props.conditions[props.index].treatment}
                    onChange={onTreatmentChange}
                />
            </Grid>
        </Grid>
    );
};

ConditionsInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    conditions: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired
};

export default ConditionsInput;