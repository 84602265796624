import * as React from 'react';
import { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { store } from '../../redux/store';
import { getPicture } from '../../api/apigateway/apigateway';
// import {
//     Account
// } from '@mui/icons-material';

function PortalAppBar(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [profilePicture, setProfilePicture] = useState('');

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onProfileClick = () => {
        props.onMenuItemClick("My Profile");
        setAnchorEl(null);
    };

    const onSettingsClick = () => {
        props.onMenuItemClick("Settings");
        setAnchorEl(null);
    };

    useEffect(() => {
        //TODO: Api Call to retrieve profile information
        getPicture(store.getState().userID, setProfilePicture);
    }, []);

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant={"h5"} component={"div"} sx={{ flexGrow: 1}}>
                        {props.header}
                    </Typography>
                    <div>
                        <IconButton
                            size={"large"}
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            {profilePicture ? <img src={'' + profilePicture} width="30" height="30" borderRadius="15"></img> : <AccountCircle />}
                            {/* <img src={'' + profilePicture} width="30" height="30" borderRadius="15"></img> */}
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={onProfileClick}>Profile</MenuItem>
                            <MenuItem onClick={onSettingsClick}>Settings</MenuItem>
                            <MenuItem onClick={props.onLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

PortalAppBar.propTypes = {
    onMenuItemClick: PropTypes.func,
    onLogout: PropTypes.func,
    header: PropTypes.string,
};

export default PortalAppBar;