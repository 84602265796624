export default function reducer (state, action) {
    switch (action.type) {
        case 'UPDATE_USER':
            return {
                ...state,
                email: action.payload.email,
                username: action.payload.username,
                userID: action.payload.userID,
                accessToken: action.payload.accessToken,
                idToken: action.payload.idToken,
                refreshToken: action.payload.idToken,
                userPool: action.payload.userPool
            };
        case 'UPDATE_SESSION':
            return {
                ...state,
                email: action.payload.email,
                username: action.payload.username,
                userID: action.payload.userID,
                accessToken: action.payload.accessToken,
                idToken: action.payload.idToken,
                refreshToken: action.payload.idToken,
                userPool: action.payload.userPool
            };
        case 'REMOVE_USER':
            return {
                ...state,
                email: action.payload.email,
                username: action.payload.username,
                userID: action.payload.userID,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                idToken: action.payload.idToken,
                userPool: action.payload.userPool
            };
        case 'UPDATE_WALLET':
            return {
                ...state,
                wallet: action.payload.wallet
            }
        default:
            return state;
    }
}