import React, { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
// import {
//     Connection,
//     PublicKey,
//     Transaction,
//     clusterApiUrl,
//     SystemProgram,
// } from '@solana/web3.js';
import { Button } from '@mui/material';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
import Switch from '@mui/material/Switch';
import { store } from '../../redux/store';
import { solanaNET } from '../../constants/Constants';

// const NETWORK = clusterApiUrl(solanaNET);


export function Pricing(props) {

    const content = {
        '01_suffix': ' / mo',
        '01_benefit1': '3 Emails',
        '01_benefit2': '1 Database',
        '01_benefit3': 'Unlimited Domains',
        '01_benefit4': '10 GB Storage',
        '02_suffix': '$DXMD',
        '02_benefit1': '6 Emails',
        '02_benefit2': '2 Database',
        '02_benefit3': 'Unlimited Domains',
        '02_benefit4': '25 GB Storage',
        '03_suffix': ' $DXMD',
        '03_benefit1': '9 Emails',
        '03_benefit2': '3 Database',
        '03_benefit3': 'Unlimited Domains',
        '03_benefit4': '50 GB Storage',
        ...props.content
    };

    const [state, setState] = React.useState({
        checkbox: true,
    });

    const handleChange = (event) => {
        setState({ ...state, checkbox: event.target.checked });
    };


    return (
        <section>
            <Container maxWidth="lg">
                <Box py={8} textAlign="center">
                    <Box mb={3}>
                        <Container maxWidth="sm">
                            <Typography variant="h3" component="h2" gutterBottom={true}>
                                <Typography variant="h3" component="span" color="primary">Pick a membership.</Typography>
                            </Typography>

                        </Container>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Card variant="outlined">
                                <CardHeader title={"Free"}></CardHeader>
                                <CardContent >
                                    <Box px={1}>
                                        <Typography variant="h3" component="h2" gutterBottom={true}>
                                            0
                                        </Typography>
                                        {/* <Typography color="textSecondary" variant="subtitle1" component="p">{content['01_benefit1']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">{content['01_benefit2']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">{content['01_benefit3']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p" paragraph={true}>{content['01_benefit4']}</Typography> */}
                                    </Box>
                                    <Button variant="contained" disabled color="primary">Current</Button>
                                    <Box mt={2}>
                                        <Link href="#" color="primary">Learn more</Link>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card variant="outlined">
                                <CardHeader title={"Premium"}></CardHeader>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography variant="h3" component="h2" gutterBottom={true}>
                                            10
                                            {"\n"}
                                            <Typography variant="h6" color="textSecondary" component="span">{content['02_suffix']}</Typography>
                                        </Typography>
                                        {/* <Typography color="textSecondary" variant="subtitle1" component="p">{content['02_benefit1']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">{content['02_benefit2']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">{content['02_benefit3']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p" paragraph={true}>{content['02_benefit4']}</Typography> */}
                                    </Box>
                                    <Button variant="outlined" color="primary">Select Plan</Button>
                                    <Box mt={2}>
                                        <Link href="#" color="primary">Learn more</Link>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card variant="outlined">
                                <CardHeader title={"VIP"}></CardHeader>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography variant="h3" component="h2" gutterBottom={true}>
                                            100
                                            {"\n"}
                                            <Typography variant="h6" color="textSecondary" component="span">{content['03_suffix']}</Typography>
                                        </Typography>
                                        {/* <Typography color="textSecondary" variant="subtitle1" component="p">{content['03_benefit1']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">{content['03_benefit2']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">{content['03_benefit3']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p" paragraph={true}>{content['03_benefit4']}</Typography> */}
                                    </Box>
                                    <Button variant="outlined" color="primary">Select Plan</Button>
                                    <Box mt={2}>
                                        <Link href="#" color="primary">Learn more</Link>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </section>
    );
}

const getProvider = () => {
    if ("solana" in window) {
        const anyWindow = window;
        const provider = anyWindow.solana;
        if (provider.isPhantom) {
            return provider;
        }
    }
    return null;
};

function PatientBilling() {
    // const provider = getProvider();
    const [logs, setLogs] = useState([]);
    const addLog = useCallback(
        (log) => setLogs((logs) => [...logs, "> " + log]),
        []
    );
    // const connection = new Connection(NETWORK);
    const [walletAddress, setWalletAddress] = useState(null);
    const [addy, setAddy] = useState(null);
    const [balance, setBalance] = useState(0);
    // const checkIfWalletIsConnected = async () => {
    //     try {
    //         const { solana } = window;

    //         if (solana) {
    //             if (solana.isPhantom) {
    //                 console.log("Phantom wallet found!");
    //                 const response = await solana.connect({});
    //                 console.log(
    //                     "Connected with Public Key:",
    //                     response.publicKey.toString()
    //                 );
    //                 setAddy(response.publicKey);
    //                 setWalletAddress(response.publicKey.toString());
    //                 if (response.publicKey != null) {
    //                     connection.getBalance(response.publicKey).then((res) => { console.log('did something', res); setBalance(res / 1000000000) });
    //                 }
    //             }
    //         } else {
    //             alert("Solana object not found! Get a Phantom Wallet 👻");
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // const connectWallet = async (clicked=false) => {
    //     const { solana } = window;

    //     if (solana) {
    //         const response = await solana.connect();
    //         console.log('did something Connected with Public Key:', response.publicKey.toString());
    //         console.log('did something got response:', response);
    //         setAddy(response.publicKey);
    //         setWalletAddress(response.publicKey.toString());
    //         if (response.publicKey != null) {
    //             connection.getBalance(response.publicKey).then((res) => { console.log('did something', res); setBalance(res / 1000000000) });
    //         }
    //     } else if (clicked) {
    //         window.open("https://phantom.app/", "_blank")
    //     }
    // };

    const renderNotConnectedContainer = () => (
        <div style={{textAlign: 'center'}}>
        <br />
        <button
            className="cta-button connect-wallet-button"
            // onClick={connectWallet}
            style={{ textAlign: 'center' }}
        >
            Connect to Wallet
        </button>
        <p style={{ textAlign: 'center' }}>A Phantom wallet must be connected in order to create and book appointments on the DXMD platform.</p>
        </div>
    );

    const createTransferTransaction = async () => {
        if (!provider.publicKey) return;
        console.log('did something public key', provider.publicKey);
        const dxmdWallet = new PublicKey("6MY9bicoRqmuxPWGxo3sPTsuVTq3Pvj1gkzfuZg6Xv1H");
        const escrowWallet = new PublicKey("2shBLPKNBfnTXZ6ai4YQLMAFyMgdZGQqbDcFCTvFkaFs");
        const primohWallet = new PublicKey("233wbsvkFFJF2xw1vHwfcvYhyNxEm8kvCBHVBf9sidNk");
        const primoh2TestWallet = new PublicKey("FibzGwtWccJuq2Lry5atm8TSKgLtofaY9VYnvDHQeamY");
        const treasuryWallet = new PublicKey("5RXmpYzMiaUEqbQg7dfSJQNYm15qcQcLotuGjuNehnEG");
        let transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: provider.publicKey,
                toPubkey: primohWallet,
                lamports: 1000,
            })
        ).add(
            SystemProgram.transfer({
                fromPubkey: provider.publicKey,
                toPubkey: primoh2TestWallet,
                lamports: 1000,
            })
        );
        transaction.feePayer = provider.publicKey;
        addLog("Getting recent blockhash");
        const anyTransaction = transaction;
        anyTransaction.recentBlockhash = (
            await connection.getRecentBlockhash()
        ).blockhash;
        return transaction;
    };
    const sendTransaction = async () => {
        try {
            const transaction = await createTransferTransaction();
            console.log('transaction', transaction);
            if (!transaction) return;
            let signed = await provider.signTransaction(transaction);
            console.log('transaction signed', signed);
            addLog("Got signature, submitting transaction");
            let signature = await connection.sendRawTransaction(signed.serialize());
            console.log('transaction signature', signature);
            addLog("Submitted transaction " + signature + ", awaiting confirmation");
            const confirmation = await connection.confirmTransaction(signature);
            console.log('transaction confirmation', confirmation);
            addLog("Transaction " + signature + " confirmed");
            return Promise.resolve("Transaction confirmed");
        } catch (err) {
            console.warn(err);
            addLog("[error] sendTransaction: " + JSON.stringify(err));
            console.log('transaction was rejected here in catch statement');
            return Promise.reject("transaction was rejected");
        }
    };

    const renderConnectedContainer = () => (
        <h4 style={{ textAlign: 'center' }}>
            You currently have: {balance.toFixed(3)} SOL
            <br />
            {/* <Button onClick={() => sendTransaction().then(res => console.log('transaction completed.')).catch(err => console.log('transaction caught.'))}>Make a Payment</Button> */}
        </h4>
    )



    useEffect(() => {
        // connectWallet();
    }, []);
    return (
        <Box style={{ justifyContent: 'center' }}>
            <h1 style={{ textAlign: 'center' }}>Billing</h1>
            {/* <p style={{ textAlign: 'center' }}>{walletAddress && "Connected as:"}{addy?.toBase58()}</p>
            <br /> */}
            {/* {walletAddress ? renderConnectedContainer() : renderNotConnectedContainer()} */}
            <br />

            {/* <Button onClick={() => doSomething()}>Test</Button> */}
            {/* <Pricing /> */}
        </Box>
    );
};

export default PatientBilling;

