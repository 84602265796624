import React from 'react';
import { useEffect } from 'react';
import ProviderList from "../../components/deprecated/ProviderData/ProviderList";

function PatientProviders() {
    return (
        <ProviderList/>
    );
}

export default PatientProviders;