export const SelectOptionsHome = [
    {
        id: 1,
        option: "Past 10 Days",
    },
    {
        id: 2,
        option: "Past 20 Days",
    },
    {
        id: 3,
        option: "Past 30 Days",
    },
]

export const selectOptionsSidebar = [
    {
        id: 1,
        option: "Days",
    },
    {
        id: 2,
        option: "Weekly",
    },
    {
        id: 3,
        option: "Monthly",
    },
]
