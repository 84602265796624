import * as React from 'react';
import * as PropTypes from 'prop-types';
import Grid from "@mui/material/Grid/Grid";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";

function MedicationsInput(props) {

    const onNameChange = (event) => {
        props.onChange({
            name: event.target.value,
            dosage: props.medications[props.index].dosage,
            instructions: props.medications[props.index].instructions,
            status: props.medications[props.index].status
        })
    };

    const onStatusChange = (event, val) => {
        props.onChange({
            name: props.medications[props.index].name,
            dosage: props.medications[props.index].dosage,
            instructions: props.medications[props.index].instructions,
            status: val
        })
    };

    const onDosageChange = (event) => {
        props.onChange({
            name: props.medications[props.index].name,
            dosage: event.target.value,
            instructions: props.medications[props.index].instructions,
            status: props.medications[props.index].status
        })
    };

    const onInstructionsChange = (event, val) => {
        props.onChange({
            name: props.medications[props.index].name,
            dosage: props.medications[props.index].dosage,
            instructions: val,
            status: props.medications[props.index].status
        })
    };

    const onItemDelete = () => {
        props.onDelete();
    };

    return (
        <Grid container sx={{mt: 3}}>
            <Grid item xs={9}>
                <TextField
                    fullWidth
                    label="Name"
                    value={props.medications[props.index].name}
                    onChange={onNameChange}
                    sx={{mt: 1}}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Button
                    variant={"contained"}
                    sx={{ml: 2, mt: 2}}
                    onClick={onItemDelete}
                    color={'secondary'}
                >
                    X
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    disablePortal
                    options={[
                        'As Needed',
                        'Daily',
                        'Twice a day',
                        'Three times a day',
                        'Four times a day',
                        'At bed time',
                        'other'
                    ]}
                    value={props.medications[props.index].instructions}
                    onChange={onInstructionsChange}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => {
                        return (
                            <TextField
                                fullWidth
                                sx={{mt: 1}}
                                label={"Instructions"}
                                autoFocus

                                {...params}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    sx={{mt: 1}}
                    fullWidth
                    label="Dosage"
                    value={props.medications[props.index].dosage}
                    onChange={onDosageChange}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Autocomplete
                    disablePortal
                    options={[
                        'Current',
                        'Past',
                    ]}
                    getOptionLabel={(option) => option}
                    value={props.medications[props.index].status}
                    onChange={onStatusChange}
                    renderInput={(params) => {
                        return (
                            <TextField
                                fullWidth
                                sx={{mt: 1}}
                                label={"Status"}
                                autoFocus
                                {...params}
                            />
                        );
                    }}
                />
            </Grid>
        </Grid>
    );
};

MedicationsInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    medications: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired
};

export default MedicationsInput;