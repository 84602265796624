import React, {useState, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Typography from "@mui/material/Typography/Typography";
import {pronouns, genders} from "../../../constants/Constants";

function EditBackground(props) {
    return (
        <Box sx={{m: 4}}>
            <Typography sx={{m: 1}} variant={'h6'}> Edit Background </Typography>
            <Grid container spacing={3} >
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="Medical School"
                        value={props.school}
                        onChange={props.onSchoolChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="Years of Experience"
                        value={props.yoe}
                        onChange={props.onYoeChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="Languages"
                        value={props.languages}
                        onChange={props.onLanguagesChange}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

EditBackground.propTypes = {
    school: PropTypes.string,
    languages: PropTypes.string,
    yoe: PropTypes.number,
    onSchoolChange: PropTypes.func,
    onLanguagesChange: PropTypes.func,
    onYoeChange: PropTypes.func,
};

export default EditBackground;