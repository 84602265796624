import * as React from 'react';
import * as PropTypes from 'prop-types';
import '../../styles/PatientSidebar.css';
import { useHistory } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CategoryIcon from "../../assets/icons/Category.svg";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Sidebar from '../../pages/Sidebar';
import {
    Dashboard,
    Message,
    CalendarViewMonth,
    Assessment,
    Summarize,
    LocalHospital,
    Medication,
    Money,
    Search,
    Logout,
    AccountCircle
} from '@mui/icons-material'
import logo from "../../assets/icons/DXMD.svg";
import WorkIcon from "../../assets/icons/Work.svg";
import settingIcon from "../../assets/icons/Setting.svg";
import activityIcon from "../../assets/icons/Activity.svg";
import logoutIcon from "../../assets/icons/Logout.svg";
import messageIcon from "../../assets/icons/Message.svg";


function PatientNavigationDrawer(props) {
    const history = useHistory();

    const [open, setOpen] = React.useState(true);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const pages = [
        {
            text: "Dashboard",
            icon: <img src={CategoryIcon} alt={'icon'}/>,
            onClick: () => props.onDrawerItemClick('Dashboard')
        },
        // {
        //     text: "Appointments",
        //     icon: <img src={WorkIcon} alt={'icon'}/>,
        //     onClick: () => props.onDrawerItemClick('Appointments')
        // },
        // {
        //     text: "Appointments-Old",
        //     icon: <CalendarViewMonth/>,
        //     onClick: () => props.onDrawerItemClick('Appointments-Old')
        // },
        // {
        //     text: "Providers",
        //     icon: <LocalHospital/>,
        //     onClick: () => props.onDrawerItemClick('Providers')
        // },
        // {
        //     text: "Messages",
        //     icon: <img src={messageIcon} alt={'icon'}/>,
        //     onClick: () => props.onDrawerItemClick('Messages')
        // },
        // {
        //     text: "Lab Results",
        //     icon: <Assessment/>,
        //     onClick: () => props.onDrawerItemClick('Lab Results')
        // },
        {
            text: "Records",
            icon: <img src={activityIcon} alt={'icon'}/>,
            onClick: () => props.onDrawerItemClick('Records')
        },
        // {
        //     text: "Prescriptions",
        //     icon: <Medication/>,
        //     onClick: () => props.onDrawerItemClick('Prescriptions')
        // },
        {
            text: "Billing",
            icon: <Money/>,
            onClick: () => props.onDrawerItemClick('Billing')
        },
        {
            text: "WebMD",
            icon: <Search/>,
            onClick: () => window.open("https://www.webmd.com")
        },

    ];

    const options = [
        {
            text: "My Profile",
            icon: <AccountCircle />,
            onClick: () => props.onDrawerItemClick('My Profile')
        },
        {
            text: "Settings",
            icon: <img src={settingIcon} alt={'icon'}/>,
            onClick: () => props.onDrawerItemClick('Settings')
        },

        {
            text: "Logout",
            icon: <img src={logoutIcon} alt={'icon'}/>,
            onClick: () => props.onLogout()
        },
    ];
    return (
        <Drawer
            variant='permanent'
            anchor='left'
        >
            <Sidebar
                isPatient={true}
                onLogout={props.onLogout} 
                onDrawerItemClick={props.onDrawerItemClick}
                selectedScreen={props.selectedScreen}
            />
            {/* <Box className={'sidebar'}>
                <Box className="nav_logo">
                    <img src={logo} alt="logo" />
                </Box>
                <List className={'nav_list'}>
                    {pages.map((item, index) => {
                        const {text, icon, onClick} = item;
                        return (
                          <ListItem button key={text} onClick={onClick}>
                              {icon && <ListItemIcon>{icon}</ListItemIcon>}
                              <ListItemText primary={text} className={''}/>
                          </ListItem>
                        );
                    })}
                </List>
                <Divider sx={{m: 1}}/>
                <List>
                    {options.map((item, index) => {
                        const {text, icon, onClick} = item;
                        return (
                            <ListItem button key={text} onClick={onClick}>
                                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                                <ListItemText primary={text}/>
                            </ListItem>
                        );
                    })}
                </List>
            </Box> */}
        </Drawer>
    )

}

PatientNavigationDrawer.propTypes = {
    onDrawerItemClick: PropTypes.func
};

export default PatientNavigationDrawer;