import * as React from 'react';
import {useState} from 'react';
import Container from "@mui/material/Container/Container";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import Box from "@mui/material/Box/Box";
import Avatar from "@mui/material/Avatar/Avatar";
import ProfileIcon from "@mui/material/SvgIcon/SvgIcon";
import Typography from "@mui/material/Typography/Typography";
import Grid from "@mui/material/Grid/Grid";
import Divider from "@mui/material/Divider/Divider";
import {createTheme, ThemeProvider} from "@mui/material";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ProviderGeneralMedLicensing from './RegistrationForms/ProviderGeneralMedLicensing';
import ProviderPsychologistLicenseInput from './RegistrationForms/ProviderPsychologistLicenseInput';
import ProviderDietitianLicenseInput from './RegistrationForms/ProviderDieticianLicenseInput';
import ProviderHealthcareConsultantLicenseInput from './RegistrationForms/ProviderHealthcareConsultantLicenseInput';
import ProviderWellnessSpecialistLicenseInput from './RegistrationForms/ProviderWellnessSpecialistLicenseInput';
import {useHistory} from "react-router-dom";
import axios from 'axios';
import { store } from '../../redux/store';

const theme = createTheme();

function ProviderRegistration() {

    const history = useHistory();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [preferredName, setPreferredName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [extension, setExtension] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [lang, setLang] = useState("");
    const [gender, setGender] = useState(null);
    const [pronouns, setPronouns] = useState(null);
    const [typeOfProvider, setTypeOfProvider] = useState({
        isGeneralMed: false,
        isSpecialtyCare: false,
        isPsychiatrist: false,
        isPsychologist: false,
        isDietitian: false,
        isWellnessSpecialist: false,
        isHealthcareConsultant: false
    });
    const [subFormFields, setSubFormFields] = useState({});

    let providerType;
    if (typeOfProvider.isGeneralMed) {
        providerType = "General Medicine";
    } else if (typeOfProvider.isSpecialtyCare) {
        providerType = "Specialty Care";
    } else if (typeOfProvider.isPsychiatrist) {
        providerType = "Psychiatrist";
    } else if (typeOfProvider.isPsychologist) {
        providerType = "Psychologist";
    } else if (typeOfProvider.isDietitian) {
        providerType = "Dietitian";
    } else if (typeOfProvider.isWellnessSpecialist) {
        providerType = "Wellness Specialist";
    } else if (typeOfProvider.isHealthcareConsultant) {
        providerType = "Health Care Consultant"
    }

    const onFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const onLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const onPreferredNameChange = (event) => {
        setPreferredName(event.target.value);
    };

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const onPhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const onExtensionChange = (event) => {
        setExtension(event.target.value);
    };

    const onCityChange = (event) => {
        setCity(event.target.value);
    };

    const onStateChange = (event) => {
        setState(event.target.value);
    };

    const onCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const onLangChange = (event) => {
        setLang(event.target.value);
    };

    const onGenderChange = (event, val) => {
        setGender(val);
    };

    const onPronounsChange = (event, val) => {
        setPronouns(val);
    };

    const onGeneralMedChange = (event) => {
        setTypeOfProvider({
            isGeneralMed: event.target.checked,
            isSpecialtyCare: false,
            isPsychiatrist: false,
            isPsychologist: false,
            isDietitian: false,
            isWellnessSpecialist: false,
            isHealthcareConsultant: false
        });
    };

    const onSpecialtyCareChange = (event) => {
        setTypeOfProvider({
            isGeneralMed: false,
            isSpecialtyCare: event.target.checked,
            isPsychiatrist: false,
            isPsychologist: false,
            isDietitian: false,
            isWellnessSpecialist: false,
            isHealthcareConsultant: false
        });
    };

    const onPsychiatristChange = (event) => {
        setTypeOfProvider({
            isGeneralMed: false,
            isSpecialtyCare: false,
            isPsychiatrist: event.target.checked,
            isPsychologist: false,
            isDietitian: false,
            isWellnessSpecialist: false,
            isHealthcareConsultant: false
        });
    };

    const onPsychologistChange = (event) => {
        setTypeOfProvider({
            isGeneralMed: false,
            isSpecialtyCare: false,
            isPsychiatrist: false,
            isPsychologist: event.target.checked,
            isDietitian: false,
            isWellnessSpecialist: false,
            isHealthcareConsultant: false
        });
    };

    const onDietitianChange = (event) => {
        setTypeOfProvider({
            isGeneralMed: false,
            isSpecialtyCare: false,
            isPsychiatrist: false,
            isPsychologist: false,
            isDietitian: event.target.checked,
            isWellnessSpecialist: false,
            isHealthcareConsultant: false
        });
    };

    const onWellnessSpecialistChange = (event) => {
        setTypeOfProvider({
            isGeneralMed: false,
            isSpecialtyCare: false,
            isPsychiatrist: false,
            isPsychologist: false,
            isDietitian: false,
            isWellnessSpecialist: event.target.checked,
            isHealthcareConsultant: false
        });
    };

    const onHealthcareConsultantChange = (event) => {
        setTypeOfProvider({
            isGeneralMed: false,
            isSpecialtyCare: false,
            isPsychiatrist: false,
            isPsychologist: false,
            isDietitian: false,
            isWellnessSpecialist: false,
            isHealthcareConsultant: event.target.checked
        });
    };

    const onSubFormFieldsChange = (fields) => {
        setSubFormFields(fields);
    };

    const handleSave = () => {
        const data = {
            uuid: store.getState().userID,
            firstName: firstName,
            lastName: lastName,
            profile: {
                preferredName: preferredName,
                email: email,
                phone: phone,
                extension: extension,
                preferredLanguage: lang,
                gender: gender,
                pronouns: pronouns,
            },
            address: {
                city: city,
                state: state,
                country: country
            },
            typeOfProvider: providerType,
            ...subFormFields
        };

        axios.post(
            "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/provider/register",
            data
        ).then((response) => {
            console.log(response["status"]);
            history.push('/provider');
        }).catch((error) => {
            console.error(error);
        });
    };

    return(
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <ProfileIcon />
                    </Avatar>
                    <Typography component="h1" variant="h4" sx={{m: 1}}>
                        My Profile
                    </Typography>
                    <Typography component="h1" variant="body1" align='center' sx={{m:1}}>
                        Please complete your profile. This can also be completed/edited in the MyProfile tab later.
                    </Typography>
                    {/*-----------------------FORM-----------------------------------------*/}
                    <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 1 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Divider dark sx={{marginTop: 1, marginBottom: 1, borderBottomWidth: 3}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="h1" variant="body1" align='left' sx={{marginTop: 2, fontWeight:'bold'}}>
                                    General:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="normal"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                    value={firstName}
                                    onChange={onFirstNameChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    value={lastName}
                                    onChange={onLastNameChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="preferredName"
                                    label="Preferred Name"
                                    name="preferredName"
                                    value={preferredName}
                                    onChange={onPreferredNameChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    autoFocus
                                    value={email}
                                    onChange={onEmailChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField
                                    fullWidth
                                    label="Phone"
                                    autoFocus
                                    value={phone}
                                    onChange={onPhoneChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    fullWidth
                                    label="Ext."
                                    autoFocus
                                    value={extension}
                                    onChange={onExtensionChange}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin={"normal"}
                                    required
                                    fullWidth
                                    id="city"
                                    label="City"
                                    name="city"
                                    value={city}
                                    onChange={onCityChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    margin={"normal"}
                                    required
                                    fullWidth
                                    id="state"
                                    label="State"
                                    name="state"
                                    value={state}
                                    onChange={onStateChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    margin={"normal"}
                                    required
                                    fullWidth
                                    id="country"
                                    label="Country"
                                    name="country"
                                    value={country}
                                    onChange={onCountryChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin={"normal"}
                                    required
                                    fullWidth
                                    id="language"
                                    label="Preferred Language"
                                    name="language"
                                    value={lang}
                                    onChange={onLangChange}
                                    sx={{marginTop: 3}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="genderDropdown"
                                    options={[
                                        'Male',
                                        'Female',
                                        'Prefer Not To Say',
                                        'Other'
                                    ]}
                                    value={gender}
                                    onChange={onGenderChange}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                fullWidth
                                                margin={"normal"}
                                                required
                                                id={"gender"}
                                                name={"gender"}
                                                label={"Gender"}
                                                autoFocus
                                                {...params}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="pronounsDropdown"
                                    options={[
                                        'She/Her/Hers',
                                        'He/Him/His',
                                        'They/Them/Theirs',
                                        'Per/Per/Pers',
                                        'Ze/Zir/Zirs',
                                        'Xe/Xem/Xyrs',
                                        'Ze/Hir/Hirs',
                                        'E/Em/Eirs',
                                        'Prefer Not to Say',
                                        'Other'
                                    ]}
                                    value={pronouns}
                                    onChange={onPronounsChange}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                fullWidth
                                                margin={"normal"}
                                                required
                                                id={"pronouns"}
                                                name={"pronouns"}
                                                label={"Pronouns"}
                                                autoFocus
                                                {...params}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider dark sx={{marginTop: 1, marginBottom: 1, borderBottomWidth: 3}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="h1" variant="body1" align='left' sx={{marginTop: 2, fontWeight:'bold'}}>
                                    Licensing:
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    // error={error}
                                    component="fieldset"
                                    sx={{ m: 3 }}
                                    variant="standard"
                                >
                                    <FormLabel component="legend">Categories of Providers (Select One)</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel sx={{m:1}}
                                            control={
                                                <Checkbox
                                                    checked={typeOfProvider.isGeneralMed}
                                                    onChange={onGeneralMedChange}
                                                />
                                            }
                                            label="General Medicine and Primary Care (non-emergent care)"
                                        />
                                        <FormControlLabel sx={{m:1}}
                                            control={
                                                <Checkbox
                                                    checked={typeOfProvider.isSpecialtyCare}
                                                    onChange={onSpecialtyCareChange}
                                                />
                                            }
                                            label="Specialty Care (second opinions and expert opinions on specialty care)"
                                        />
                                        <FormControlLabel sx={{m:1}}
                                            control={
                                                <Checkbox
                                                    checked={typeOfProvider.isPsychiatrist}
                                                    onChange={onPsychiatristChange}
                                                    sx={{position: 'top'}}
                                                />
                                            }
                                            label="Psychiatrists"
                                        />
                                        <FormControlLabel sx={{m:1}}
                                            control={
                                                <Checkbox
                                                    checked={typeOfProvider.isPsychologist}
                                                    onChange={onPsychologistChange}
                                                />
                                            }
                                            label="Psychologists and Mental Health Therapists"
                                        />
                                        <FormControlLabel sx={{m:1}}
                                            control={
                                                <Checkbox
                                                    checked={typeOfProvider.isDietitian}
                                                    onChange={onDietitianChange}
                                                />
                                            }
                                            label="Registered Dietitian"
                                        />
                                        <FormControlLabel sx={{m:1}}
                                            control={
                                                <Checkbox
                                                    checked={typeOfProvider.isWellnessSpecialist}
                                                    onChange={onWellnessSpecialistChange}
                                                />
                                            }
                                            label="Wellness Specialist (nutrition coaches, smoking cessation coaches, workout Instructors, weight loss specialists, sex therapists, other wellness specialists)"
                                        />
                                        <FormControlLabel sx={{m:1}}
                                            control={
                                                <Checkbox
                                                    checked={typeOfProvider.isHealthcareConsultant}
                                                    onChange={onHealthcareConsultantChange}
                                                />
                                            }
                                            label="Healthcare Consulting (healthcare experts providing consulting to businesses, financial institutions, hospitals, and other businesses)"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            {(typeOfProvider.isGeneralMed ||
                                typeOfProvider.isPsychiatrist ||
                                typeOfProvider.isSpecialtyCare ||
                                typeOfProvider.isHealthcareConsultant) &&
                                <ProviderGeneralMedLicensing fields={subFormFields} onFieldsChange={onSubFormFieldsChange}/>
                            }
                            {(typeOfProvider.isPsychologist) &&
                                <ProviderPsychologistLicenseInput fields={subFormFields} onFieldsChange={onSubFormFieldsChange}/>
                            }
                            {typeOfProvider.isDietitian &&
                                <ProviderDietitianLicenseInput fields={subFormFields} onFieldsChange={onSubFormFieldsChange}/>
                            }
                            {typeOfProvider.isHealthcareConsultant &&
                                <ProviderHealthcareConsultantLicenseInput fields={subFormFields} onFieldsChange={onSubFormFieldsChange}/>
                            }
                            {typeOfProvider.isWellnessSpecialist &&
                                <ProviderWellnessSpecialistLicenseInput fields={subFormFields} onFieldsChange={onSubFormFieldsChange}/>
                            }
                        </Grid>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default ProviderRegistration;