import React from 'react';
import Box from '@mui/material/Box';
import PatientProfile from "../components/Profile/PatientProfile";

function PatientMyProfile() {
    return (
        <Box>
            <PatientProfile/>
        </Box>
    )
}

export default PatientMyProfile;