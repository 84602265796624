import * as React from 'react';
import PropTypes from 'prop-types';
import { useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import GeneralPatientData from './GeneralPatientData';
import Paper from "@mui/material/Paper/Paper";
import Table from "@mui/material/Table/Table";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import TableContainer from "@mui/material/TableContainer/TableContainer";

function PastData() {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function createData(item, value) {
        return {item, value};
    }

    const rowsVisitData = [
        createData('Date', "1/19/2021"),
        createData('Physician', "Dr. Ashkan"),
        createData('Symptoms', "Constant bowel movement"),
        createData('Diagnosis', "Patient experiencing diarrhea. Prescribed xyz."),
    ];

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', height: '100%' , width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{borderRight: 1, borderColor: 'divider' }}>
                    <Tabs onChange={handleChange}
                          aria-label="patient data"
                          textColor={'secondary'}
                          indicatorColor={'blue'}
                          orientation={'vertical'}
                          variant={'scrollable'}>
                        <Tab label="1/19/2020" index="1" value="1" />
                        <Tab label="1/19/2021" index="2" value="2" />
                        <Tab label="1/19/2022" index="3" value="3" />
                    </Tabs>
                </Box>
                <TabPanel index="1" value="1"></TabPanel>
                <TabPanel index="2" value="2">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="basic">
                            <TableBody>
                                {rowsVisitData.map((row) => (
                                    <TableRow
                                        key={row.item}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">{row.item}</TableCell>
                                        <TableCell align="right">{row.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel index="3" value="3">Item Three</TabPanel>
            </TabContext>
        </Box>
    )
}

export default PastData;