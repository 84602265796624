import * as React from 'react';
import { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AccountContext } from "../../api/authorization/Account";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import { store } from "../../redux/store";
import { setUser } from "../../redux/actions";

const theme = createTheme();

function Register() {

    const [isPatient, setIsPatient] = useState(true);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const history = useHistory();
    const { register, authenticate } = useContext(AccountContext);
    const gridSize = 12;

    const onPatientStatusChange = (event) => {
        setIsPatient(event.target.checked);
    };

    const onUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleFirstSubmit = (event) => {
        event.preventDefault();
        register(
            username,
            password,
            email,
            isPatient
        ).then((data) => {
            authenticate(
                username,
                password,
                isPatient
            ).then((data) => {
                store.dispatch(
                    setUser({
                        username: username,
                        sub: data.data.accessToken.payload.sub,
                        accessToken: data.data.accessToken,
                        refreshToken: data.data.refreshToken,
                        idToken: data.data.idToken,
                        pool: data.pool,
                        email: ''
                    })
                );
                history.push(isPatient ? '/patient' : '/provider')
            }).catch((err) => {
                alert(err.message || JSON.stringify(err));
                console.log(err);
            })
            console.log(data);
        }).catch((err) => {
            alert(err.message || JSON.stringify(err));
            console.log(err);
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Registration
                    </Typography>
                    <Box component="form" onSubmit={handleFirstSubmit} noValidate sx={{ mt: 1 }}>
                        <Grid container>
                            <FormControlLabel
                                control={<Switch
                                    checked={isPatient}
                                    onChange={onPatientStatusChange} />}
                                label={isPatient ? "Register as a patient" : "Register as a provider"}
                            />
                            <Grid item xs={gridSize}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Username"
                                    value={username}
                                    onChange={onUsernameChange}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={gridSize}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Email Address"
                                    value={email}
                                    onChange={onEmailChange}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={gridSize}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Password"
                                    type="password"
                                    value={password}
                                    onChange={onPasswordChange}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Register
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link variant="body2" to={'/login'}>
                                    Already have an account? Log in instead.
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Register;