import axios from "axios";

const SCHEDULE_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/schedule";
const ALL_UNBOOKED_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/schedule/allUnbooked";
const BOOK_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/schedule/book";
const DOCTOR_UPCOMING_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/schedule/doctorupcoming";
const DOCTOR_UPCOMING_TODAY_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/schedule/doctorupcomingtoday";
const PATIENT_UPCOMING_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/patient/upcomingappointments";
const PATIENT_PAST_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/patient/pastappointments";
const ALL_APPOINTMENTS_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/schedule/all";
const UPLOAD_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/uploadmedicaldata";
const RECORD_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/uploadmedicaldata/getmedicalrecord";
const PATIENT_APPOINTMENTS_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/schedule/patientappointments";
const NOTE_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/schedule/addpatientvisit";
const UPLOAD_PICTURE_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/uploadpicture";
const PICTURE_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/uploadpicture/getpicture";
const PROVIDER_DASHBOARD =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/schedule/dashboard";
const PATIENT_DASHBOARD_ENDPOINT =
  "https://706dh7yrbg.execute-api.us-west-1.amazonaws.com/dev/dashboard";
const PROVIDER_PATIENT_LIST_ENDPOINT =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/provider/patientList";
const PATIENT_GET_UPLOADS =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/patient-get-records";
const DOCTOR_GET_UPLOADS =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/doctor-get-uploads";
const DOCTOR_EDIT_UPLOAD =
  "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/doctor-edit-upload";

const DOCTOR_EDIT_CASE = "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/doctor-edit-case";

const PATIENT_CREATE_CASE = "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/patient-create-case";
const DOCTOR_GET_CASES = "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/doctor-get-cases";

const GET_CASE = "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/get-case";
const WIX_UPLOAD = "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/doublecheck-wix-upload"

const getDateString = () => {
  return new Date(Date.now() - (new Date().getTimezoneOffset() * 1000 * 60)).toJSON().slice(0, 10).replaceAll("-", "");
}

export async function getCase(caseId, doctorId) {
  try {
    const response = await axios.get(GET_CASE, {
      params: {
        caseId: caseId,
        doctorId: doctorId,
      },
    });

    // Check if the response status is OK (status code 200)
    if (response.status === 200) {
      return response.data; // Return the response data (an array in this case)
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    // Handle any errors that occurred during the request
    console.error("Error fetching data:", error);
    throw error; // You can choose to handle or propagate the error as needed
  }
}

export function getProviderDashboard(doctorId, callback) {
  axios
    .get(PROVIDER_DASHBOARD, {
      params: {
        doctorId: doctorId,
      },
    })
    .then(function (response) {
      const upcoming = response["data"];
      callback(upcoming);
    })
    .catch(function (error) {
      callback([]);
    });
}

export function addPatientNote(note, appointmentId) {
  axios.post(NOTE_ENDPOINT, {
    appointmentId: appointmentId,
    note: note,
  });
}

export function getAvailableAppointments(date, callback, callback2) {
  axios
    .get(SCHEDULE_ENDPOINT, {
      params: {
        date: date,
      },
    })
    .then(function (response) {
      const slots = response["data"]["Appointments"];
      const providerInfo = response["data"]["provider-info"];
      callback(slots);
      callback2(providerInfo);
    })
    .catch(function (error) {
      callback([]);
    });
}

export function getPatientList(doctorId, callback) {
  axios
    .get(PROVIDER_PATIENT_LIST_ENDPOINT, {
      params: {
        doctorId: doctorId,
      },
    })
    .then(function (response) {
      const patients = response["data"]["patient-users"];
      callback(patients);
    })
    .catch(function (error) {
      callback([]);
    });
}

export function getAllUnbooked(callback, callback2) {
  axios
    .get(ALL_UNBOOKED_ENDPOINT, {
      params: {},
    })
    .then(function (response) {
      const slots = response["data"]["Appointments"];
      const providerInfo = response["data"]["provider-info"];
      callback(slots);
      callback2(providerInfo);
    })
    .catch(function (error) {
      callback([]);
    });
}

export function getPatientAppointments(
  patientId,
  callbackUpcoming,
  callbackPast,
  callbackProviderInfo
) {
  axios
    .get(PATIENT_APPOINTMENTS_ENDPOINT, {
      params: {
        patientId: patientId,
      },
    })
    .then(function (response) {
      const slots = response["data"];
      callbackUpcoming(slots["Upcoming"]);
      callbackPast(slots["Past"]);
      callbackProviderInfo(slots["provider-users"]);
    })
    .catch(function (error) {
      console.error("ERROR at getPatientAppointments");
    });
}

export function getPatientDashboardData(patientId, callback) {
  axios
    .get(PATIENT_DASHBOARD_ENDPOINT, {
      params: {
        patientId: patientId,
      },
    })
    .then(function (response) {
      callback(response["data"]);
    })
    .catch(function (error) {
      console.error("ERROR at getPatientDashboardData", error);
    });
}

export function getAllAppointments(callback) {
  axios
    .get(ALL_APPOINTMENTS_ENDPOINT)
    .then(function (response) {
      const appts = response["data"]["Items"];
      callback(appts);
    })
    .catch(function (error) {
      callback([]);
    });
}

export function getDoctorAppointments(doctorId, callback) {
  axios
    .get(SCHEDULE_ENDPOINT, {
      params: {
        doctorId: doctorId,
      },
    })
    .then(function (response) {
      const slots = response["data"]["Appointments"];
      callback(slots);
    })
    .catch(function (error) {
      callback([]);
    });
}

export function setDoctorAppointment(
  doctorId,
  operation,
  appointment,
  price,
  wallet
) {
  axios.post(SCHEDULE_ENDPOINT, {
    doctorId: doctorId,
    operation: operation,
    appointment: appointment,
    price: price,
    wallet: wallet,
  });
}

export function bookAppointment(patientId, doctorId, appointmentId) {
  axios.post(BOOK_ENDPOINT, {
    patientId: patientId,
    doctorId: doctorId,
    appointmentId: appointmentId,
  });
}

export function getDoctorUpcoming(doctorId, callback, callbackPatientInfo) {
  axios
    .get(DOCTOR_UPCOMING_ENDPOINT, {
      params: {
        doctorId: doctorId,
      },
    })
    .then(function (response) {
      const upcoming = response["data"]["Appointments"];
      callback(upcoming);
      callbackPatientInfo(response["data"]["patient-info"]);
    })
    .catch(function (error) {
      callback([]);
    });
}

export function getDoctorUpcomingToday(
  doctorId,
  callback,
  callbackPatientInfo
) {
  axios
    .get(DOCTOR_UPCOMING_TODAY_ENDPOINT, {
      params: {
        doctorId: doctorId,
      },
    })
    .then(function (response) {
      const upcoming = response["data"]["Appointments"];
      callback(upcoming);
      callbackPatientInfo(response["data"]["patient-info"]);
    })
    .catch(function (error) {
      callback([]);
    });
}

export function getPatientUpcoming(patientID, callback) {
  axios
    .get(PATIENT_UPCOMING_ENDPOINT, {
      params: {
        doctorId: patientID,
      },
    })
    .then(function (response) {
      const upcoming = response["data"]["Appointments"];
      callback(upcoming);
    })
    .catch(function (error) {
      console.error(error);
      callback([]);
    });
}

export function getPatientPast(patientID, callback) {
  axios
    .get(PATIENT_PAST_ENDPOINT, {
      params: {
        doctorId: patientID,
      },
    })
    .then(function (response) {
      const upcoming = response["data"]["Appointments"];
      callback(upcoming);
    })
    .catch(function (error) {
      callback([]);
    });
}

export function getPatientCase(patientId, setCaseInfo, setCaseNotes) {
  // Patient Get Cases
  axios.get("https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/patient-get-cases", {
    params: {patientId: patientId}
  }).then((res) => {
    console.log("get patient cases", res.data);
    if (res.data.length > 0) {
      setCaseInfo(res.data[0]);
      setCaseNotes(res.data[0].caseNotes)
    } else {
      setCaseInfo(null);
    }
  }).catch((err) => console.error("Error in getPatientCase", err))
}

export function getPatientUploads(patientId, callback) {
  axios
    .get(PATIENT_GET_UPLOADS, {
      params: { patientId: patientId },
    })
    .then((res) => {
      
      callback(res.data);
    })
    .catch((err) => console.log("Error in getPatientUploads", err));
}

export function getPatientCasesByDoctor(doctorId, callback) {
  axios
    .get(DOCTOR_GET_CASES, {
      params: { doctorId },
    })
    .then((res) => {
      console.log("getPatientCasesByDoctor data", res);
      callback(res.data);
    })
    .catch((err) => console.log("Error in getPatientCasesByDoctor", err));
}

export function getPatientUploadsByDoctor(doctorId, callback) {
  axios
    .get(DOCTOR_GET_UPLOADS, {
      params: { doctorId },
    })
    .then((res) => {
      console.log("getPatientUploadsByDoctor data", res);
      callback(res.data);
    })
    .catch((err) => console.log("Error in getPatientUploadsByDoctor", err));
}

export function updatePatientUploadByDoctor(params, callback) {
  console.log("updatePatientUploadByDoctor params", params);
  axios
    .post(DOCTOR_EDIT_UPLOAD, params)
    .then((res) => {
      console.log("updatePatientUploadByDoctor data", res);
      callback(res.data);
    })
    .catch((err) => console.log("Error in updatePatientUploadByDoctor", err));
}

export function updatePatientCaseByDoctor(params, callback) {
  console.log("updatePatientCaseByDoctor params", params);
  axios
    .post(DOCTOR_EDIT_CASE, params)
    .then((res) => {
      console.log("updatePatientCaseByDoctor data", res);
      callback(res.data);
    })
    .catch((err) => console.log("Error in updatePatientCaseByDoctor", err));
}

export function updatePatientCaseByPatient(params, callback) {
  console.log("updatePatientCaseByPatient params", params);
  axios
    .post("https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/patient-edit-case", params)
    .then((res) => {
      console.log("updatePatientCaseByPatient data", res);
      callback(res.data);
    })
    .catch((err) => console.log("Error in updatePatientCaseByPatient", err));
}

export const createPatientCase = async (patientId, caseNotes) => {
  try {
    const response = await axios.post(PATIENT_CREATE_CASE, {
      patientId,
      caseNotes
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export function uploadPatientUpload(
  files,
  date,
  patientId,
  caseInfo,
  studyType,
  studyID,
  setLoading,
  setLoadingMessage,
  updatePatientRecords
) {
  for (let file of files) {
    axios
      .get(UPLOAD_ENDPOINT, {
        params: {
          file: patientId + '-' + getDateString() + '-' + file.name
        },
      })
      .then(async function (response) {
        // Presigned URL: {URL.fields has the info needed}
        // const presigned_url = response.data.URL;
        console.log("presigned response", response);

        uploadMedicalRecord(
          response.data,
          file,
          {
            s3key: patientId + '-' + getDateString() + '-' + file.name,
            fileName: file.name,
            recordType: studyType,
            date,
            patientId,
            caseID: caseInfo.caseID,
            studyID
          },
          setLoading,
          setLoadingMessage,
          updatePatientRecords
        );
      })
      .catch((error) => {
        console.log("Presigned URL error", error);
      });
  }
}

export function uploadWixUpload(
  files,
  date,
  patientEmail,
  setLoading,
  setLoadingMessage,
) {
  for (let file of files) {
    axios
      .get(WIX_UPLOAD, {
        params: {
          file: patientEmail + '-' + getDateString() + '-' + file.name
        },
      })
      .then(async function (response) {
        // Presigned URL: {URL.fields has the info needed}
        // const presigned_url = response.data.URL;
        console.log("presigned response", response);

        uploadWixMedicalRecord(
          response.data,
          file,
          {
            s3key: patientEmail + '-' + getDateString() + '-' + file.name,
            fileName: file.name,
            date,
            patientEmail,
          },
          setLoading,
          setLoadingMessage,
        );
      })
      .catch((error) => {
        console.log("Presigned URL error", error);
        setLoadingMessage("Upload failed.")
        setLoading(false);
      });
  }
}

export function getPresignedURL(name, callback) {
  axios
    .get(UPLOAD_ENDPOINT, {
      params: {
        file: name,
      },
    })
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      callback([]);
    });
}

export function getPresignedURLPicture(name, callback) {
  axios
    .get(UPLOAD_PICTURE_ENDPOINT, {
      params: {
        file: name,
      },
    })
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      callback([]);
    });
}

export function uploadWixMedicalRecord(
  options,
  file,
  formData,
  setLoading,
  setLoadingMessage,
) {
  if (options) {
    const uploadData = new FormData();
    uploadData.append("key", options.URL.fields.key);
    uploadData.append("AWSAccessKeyId", options.URL.fields.AWSAccessKeyId);
    uploadData.append(
      "x-amz-security-token",
      options.URL.fields["x-amz-security-token"]
    );
    uploadData.append("policy", options.URL.fields.policy);
    uploadData.append("signature", options.URL.fields.signature);
    uploadData.append("file", file);
    setLoadingMessage("Uploading: " + String(file.name));
    axios
      .post(options.URL.url, uploadData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        axios
          .post(
            WIX_UPLOAD,
            formData
          )
          .then((res) => {
            console.log("UPLOAD SUCCESS", res.data);
            setLoading(false);
            setLoadingMessage("Upload Successful!");
          })
          .catch((err) => {
            console.log("UPLOAD ERROR", err);
            setLoading(false);
            setLoadingMessage("Upload failed.")
          });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setLoadingMessage("Upload failed.")
      });
  }
}

export function uploadMedicalRecord(
  options,
  file,
  formData,
  setLoading,
  setLoadingMessage,
  updatePatientRecords
) {
  if (options) {
    const uploadData = new FormData();
    uploadData.append("key", options.URL.fields.key);
    uploadData.append("AWSAccessKeyId", options.URL.fields.AWSAccessKeyId);
    uploadData.append(
      "x-amz-security-token",
      options.URL.fields["x-amz-security-token"]
    );
    uploadData.append("policy", options.URL.fields.policy);
    uploadData.append("signature", options.URL.fields.signature);
    uploadData.append("file", file);
    setLoadingMessage("Uploading: " + String(file.name));
    axios
      .post(options.URL.url, uploadData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("test uploadmedicalrecord", response);
        axios
          .post(
            UPLOAD_ENDPOINT,
            formData
          )
          .then((res) => {
            console.log("UPLOAD SUCCESS", res.data);
            setLoading(false);
            setLoadingMessage("Upload Successful!");
            updatePatientRecords();
          })
          .catch((err) => {
            console.log("UPLOAD ERROR", err);
            setLoading(false);
            setLoadingMessage("Upload failed.")
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export function uploadPicture(options, file) {
  if (options) {
    const uploadData = new FormData();
    uploadData.append("key", options.URL.fields.key);
    uploadData.append("AWSAccessKeyId", options.URL.fields.AWSAccessKeyId);
    uploadData.append(
      "x-amz-security-token",
      options.URL.fields["x-amz-security-token"]
    );
    uploadData.append("policy", options.URL.fields.policy);
    uploadData.append("signature", options.URL.fields.signature);
    uploadData.append("file", file);

    axios
      .post(options.URL.url, uploadData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) { })
      .catch((error) => {
        console.error(error);
      });
  }
}

export function uploadToUsers(id, file, isPicture, uploadPatientData = {}) {
  if (id && file && !isPicture) {
    axios
      .post(UPLOAD_ENDPOINT, {
        patientId: id,
        s3key: id + '-' + getDateString() + '-' + file.name,
        recordNotes: uploadPatientData?.recordNotes,
        recordType: uploadPatientData?.recordType,
      })
      .then(function (response) { })
      .catch((error) => {
        console.error(error);
      });
  } else if (id && file && isPicture) {
    axios
      .post(UPLOAD_PICTURE_ENDPOINT, {
        patientId: id,
        s3key: id + '-' + getDateString() + '-' + file.name
      })
      .then(function (response) { })
      .catch((error) => {
        console.error(error);
      });
  }
}

export function getMedicalRecord(id, callback) {
  axios
    .get(RECORD_ENDPOINT, {
      params: {
        patientId: id,
      },
    })
    .then(function (response) {
      callback([response.data]);
    })
    .catch((error) => {
      console.error(error);
      callback([]);
    });
}

export function getPicture(id, callback) {
  axios
    .get(PICTURE_ENDPOINT, {
      params: {
        patientId: id,
      },
    })
    .then(function (response) {
      callback(response.data.link);
    })
    .catch((error) => {
      console.error(error);
      callback([]);
    });
}
