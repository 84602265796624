import * as React from 'react';
import { useState } from 'react';
import {
    Box,
} from '@mui/material';
import ProviderNavigationDrawer from "./components/NavigationDrawer/ProviderNavigationDrawer";
import PortalAppBar from "./components/NavigationDrawer/PortalAppBar";
import ProviderPatients from "./pages/ProviderPatients";
import {useHistory} from "react-router-dom";
import ProviderAppointments from "./pages/deprecated/ProviderAppointments/ProviderAppointments";
import {useContext} from "react";
import {AccountContext} from "./api/authorization/Account";
import { store } from './redux/store';
import ProviderDashboard from "./pages/ProviderDashboard";
import ProviderMyProfile from "./pages/ProviderMyProfile";
import ProviderSchedule from './components/deprecated/Scheduling/ProviderSchedule';
import ProviderBilling from './pages/deprecated/ProviderBilling';
import AdminBilling from './pages/deprecated/AdminBilling';
import * as pools from "./api/authorization/PoolCredentials";
import ProviderSettings from "./pages/ProviderSettings";
import ComingSoon from "./pages/deprecated/ComingSoon";
import ProviderUploads from './pages/ProviderUploads';

function ProviderPortal() {

    const [screenToRender, setScreenToRender] = useState('Records');
    const [loggedIn, setLoggedInStatus] = useState(true);
    const history = useHistory();

    const { signOut } = useContext(AccountContext);

    const handleScreenChange = (newScreen) => {
        setScreenToRender(newScreen);
    };

    const onLogout = () => {
        setLoggedInStatus(false);
        const isPatient = store.getState().userPool === pools.patientUserPool;
        signOut(isPatient, () => {history.push("/login")});
    };

    return (
        <Box>
            {/* {(screenToRender != 'Scheduling') &&
                <PortalAppBar
                    onMenuItemClick={handleScreenChange}
                    loggedIn={loggedIn}
                    onLogout={onLogout}
                    header={'DXMD Provider Portal'}
                />
            } */}
            <ProviderNavigationDrawer selectedScreen={screenToRender} onDrawerItemClick={handleScreenChange} onLogout={onLogout} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Box width={255}/>
                {(screenToRender==='Dashboard') &&
                    <ProviderDashboard/>
                }
                {(screenToRender==='Patients') &&
                    <ProviderPatients/>
                }
                {(screenToRender==='Appointments') &&
                    <ProviderAppointments/>
                }
                {(screenToRender === 'Scheduling') &&
                    <ProviderSchedule/>
                }
                {(screenToRender === 'Billing') &&
                    <ProviderBilling />
                }
                {(screenToRender === 'Records') &&
                    <ProviderUploads />
                }
                {(screenToRender === 'Notes') &&
                    <ComingSoon provider={true} />
                }
                {(screenToRender === 'Prescriptions') &&
                    <ComingSoon provider={true} />
                }
                {(screenToRender === 'Reviews') &&
                    <ComingSoon provider={true} />
                }
                {(screenToRender==='My Profile') &&
                    <ProviderMyProfile/>
                }
                {(screenToRender==='Admin Billing') &&
                    <AdminBilling />
                }
                {(screenToRender==='Settings') &&
                    <ProviderSettings />
                }
            </Box>
        </Box>
    );
};

export default ProviderPortal;