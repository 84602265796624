import React, {useState, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid/Grid";
import Fab from "@mui/material/Fab/Fab";
import SaveIcon from "@mui/icons-material/Save";
import { getPresignedURLPicture, uploadPicture, uploadToUsers } from '../../api/apigateway/apigateway';
import { store } from '../../redux/store';

function EditProfilePicture(props) {

    const [picToUpload, setPicToUpload] = useState(null);
    const [s3Info, sets3Info] = useState(null);
    const [patientId] = useState(store.getState().userID);

    useEffect(() => {
        if (picToUpload && s3Info) {
            console.log(s3Info);
            uploadPicture(s3Info, picToUpload);
            uploadToUsers(patientId, picToUpload, true);
        }
    }, [s3Info]);

    const pictureSelectedHandler = (event) => {
        console.log(event.target.files[0]);
        setPicToUpload(event.target.files[0]);
        //TODO: upload picture to S3, store profile photo in user table, update the profile picture URL in parent state
        if (event.target.files[0].type.split('/')[0] != "image") {
            alert('Not an image, please reselect file.');
        } else {
            getPresignedURLPicture(event.target.files[0].name, sets3Info);
        }
    };

    return (
        <Box sx={{mr: 4}}>
            <Grid container spacing={2}>
                <Grid item xs={11}>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', m: 2}}>
                        <Avatar sx={{height: 100, width: 100, m: 1, mr: 4}} src={''}/>
                        {/*<Button type={input} onChange={pictureSelectedHandler}/>*/}
                        <input
                            type={'file'}
                            onChange={pictureSelectedHandler}
                            style={{}}
                        />
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Fab
                        color="secondary"
                        aria-label="edit"
                        onClick={props.onSaveClick}
                        sx={{}}
                    >
                        <SaveIcon/>
                    </Fab>
                </Grid>
            </Grid>
        </Box>

    );
}

EditProfilePicture.propTypes = {
    currentProfilePicture: PropTypes.string,
    onSaveClick: PropTypes.func
};

export default EditProfilePicture;

//sx={{display: 'flex', flexDirection: 'row'}}