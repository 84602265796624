import * as React from "react"
import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Drawer,
  TextField,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Box,
  MenuItem,
  Select,
} from "@mui/material";
import {
  getPatientUploadsByDoctor,
  updatePatientUploadByDoctor,
  getPatientCasesByDoctor,
  updatePatientCaseByDoctor,
  getCase
} from "../api/apigateway/apigateway";
import { store } from "../redux/store";

const stripPatientId = (patientId, s3key) => {
  // Create a regular expression to match the case ID
  const regex = new RegExp(patientId + '-', 'g');

  // Use replace to remove the case ID from the filename
  const strippedFilename = s3key.replace(regex, '');
  return strippedFilename;
}


const ProviderUploads = () => {
  const [allData, setAllData] = useState([]);
  const [cases, setCases] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [doctorCaseNotes, setDoctorCaseNotes] = useState("");
  const [doctorCaseNoteDialogOpen, setDoctorCaseNoteDialogOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);

  // Case Files Dialog State Variables
  const [caseFilesDialogCaseId, setCaseFilesDialogCaseId] = useState('');
  const [caseFilesDialogCaseObject, setCaseFilesDialogCaseObject] = useState(null);
  const [openCaseFilesDialog, setOpenCaseFilesDialog] = useState(false);
  const [selectedCaseFiles, setSelectedCaseFiles] = useState([]);

  const doctorId = store.getState().userID;

  useEffect(() => {
    setLoading(false)
  }, [cases]);

  useEffect(() => {
    setLoading(true)
    getPatientCasesByDoctor(doctorId, setCases);
  }, []);

  const handleDoctorCaseNoteOpen = () => {
    setDoctorCaseNoteDialogOpen(true);
  };

  const handleDoctorCaseNoteClose = () => {
    setDoctorCaseNoteDialogOpen(false);
  };

  const handleDoctorCaseNoteSave = () => {
    updatePatientCaseByDoctor({
      doctorId,
      doctorCaseNotes,
      lastUpdated: new Date().toISOString(),
      completed: false,
      caseID: selectedId,
    });
    const updatedCases = cases.map((c) => {
      if (c.caseID === selectedId) {
        // Create a new object with the updated doctorCaseNotes
        return { ...c, doctorCaseNotes: doctorCaseNotes };
      } else {
        return c; // Return the original object for other cases
      }
    });

    // Update the state with the new array
    setCases(updatedCases);
    setSelectedId("");
    handleDoctorCaseNoteClose();
  };

  const handleOpenCaseFilesDialog = (caseObject) => {
    setCaseFilesDialogCaseId(caseObject.caseID);
    setCaseFilesDialogCaseObject(caseObject);
    getCase(caseObject.caseID, doctorId).then((resultArray) => {
      console.log("Received data:", resultArray);
      setSelectedCaseFiles(resultArray);
      setOpenCaseFilesDialog(true)
    })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCloseCaseFilesDialog = () => {
    setOpenCaseFilesDialog(false);
    setCaseFilesDialogCaseId('');
    setSelectedCaseFiles([]);
    setCaseFilesDialogCaseObject(null)
  }

  if (true) {
    return (
      <Box alignItems="center" marginRight={28} marginLeft={35}>
        <Typography align={'center'} sx={{ m: 1 }} variant={"h3"}>
          Patient Cases
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Case Notes</TableCell>
                <TableCell>Upload Date</TableCell>
                <TableCell>Doctor Feedback (click to edit)</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {/* <TableCell>Last Updated By Doctor</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {cases.sort((a, b) => new Date(b.created_date) - new Date(a.created_date)).map(row => (
                <TableRow key={row.caseID}>
                  <TableCell>{row.caseNotes}</TableCell>
                  <TableCell>{new Date(row.created_date).toDateString()}</TableCell>
                  <TableCell onClick={() => {
                    setDoctorCaseNotes(row?.doctorCaseNotes || "");
                    setSelectedId(row.caseID);
                    handleDoctorCaseNoteOpen();
                  }} style={{ cursor: 'pointer' }}>{row?.doctorCaseNotes || "None provided yet. Click to edit."}</TableCell>
                  <TableCell style={{ justifyContent: 'center' }}>
                    {(row.caseID) ?
                      <a href={'http://ec2-52-53-129-54.us-west-1.compute.amazonaws.com:3000/?did=' + doctorId + '&cid=' + (row.caseID || "")} target="_blank">
                        <Button variant="contained">View Case in DICOM Viewer</Button>
                      </a> :
                      (loading ? "Loading..." : "No uploads found.")}
                  </TableCell>
                  <TableCell><Button variant="outlined" onClick={() => handleOpenCaseFilesDialog(row)}>View Case Files</Button></TableCell>
                  {/* <TableCell>{row?.lastUpdated ? new Date(row?.lastUpdated).toDateString() : "- "}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Doctor Case Notes */}
        <Dialog
          fullWidth
          open={doctorCaseNoteDialogOpen}
          onClose={handleDoctorCaseNoteClose}
        >
          <DialogTitle>Case Notes</DialogTitle>
          <DialogContent>
            <DialogContentText>Edit notes for this case:</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Case Notes"
              fullWidth
              multiline
              minRows={5}
              value={doctorCaseNotes}
              onChange={(e) => setDoctorCaseNotes(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDoctorCaseNoteClose}>Cancel</Button>
            <Button onClick={() => handleDoctorCaseNoteSave()} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* Case Files Dialog */}
        <Dialog open={openCaseFilesDialog} onClose={handleCloseCaseFilesDialog}>
          <DialogTitle sx={{paddingRight: 15}}>Case Files</DialogTitle>
          
          <DialogContent>
          <Button sx={{
            position: 'absolute',
            right: 8,
            top: 8}} onClick={handleCloseCaseFilesDialog} variant={'contained'}>Close</Button>
            {selectedCaseFiles.map((caseItem, index) => {
              return (
                <>
                <p style={{paddingLeft: 25, paddingRight: 25}}><a href={caseItem?.link}>Download {caseItem?.fileName || stripPatientId(caseFilesDialogCaseObject.patientID, caseItem.s3key)}</a></p>
                <br />
                </>
              )
            })}
            {selectedCaseFiles.length == 0 && <p>No case files uploaded.</p>}
          </DialogContent>
        </Dialog>
      </Box>
    )
  }
};

export default ProviderUploads;
