import React, { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Button, TextField, Paper, Grid, Typography, Container } from '@mui/material';
import * as pools from "../../api/authorization/PoolCredentials";
import { useHistory } from 'react-router-dom'; // Import useHistory
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

const PasswordRecovery = () => {
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const history = useHistory(); // Initialize useHistory

    const onSubmit = event => {
        event.preventDefault();

        const user = new CognitoUser({
            Username: email,
            Pool: pools.providerUserPool,
        });

        user.confirmPassword(code, password, {
            onSuccess: () => {
                setMessage('Password reset successful. You can now login with your new password.');
            },
            onFailure: err => {
                setMessage(`Error: ${err.message}`);
            },
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container spacing={10} justify="center" alignItems="center" style={{ minHeight: '100vh' }}>
                    <Paper style={{ padding: 20 }}>
                        <Typography variant="h5">Reset Your Password</Typography>
                        <p>Check your email for a reset code.</p>
                        {message && <Typography color="secondary">{message}</Typography>}
                        {message == 'Password reset successful. You can now login with your new password.' && <Button variant="contained" color="secondary" onClick={() => history.push('/login')}>Login</Button>}
                        <form onSubmit={onSubmit}>
                            <TextField
                                label="Username"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Reset Code"
                                value={code}
                                onChange={e => setCode(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="New Password"
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Reset Password
                            </Button>
                        </form>
                    </Paper>
            </Container>
        </ThemeProvider>
    );
};

export default PasswordRecovery;
