import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: 'M',
        Medicine: 600,
        Consultation: 1200,
        Specialty: 800,
        amt: 1000,
    },
    {
        name: 'T',
        Medicine: 1000,
        Consultation: 1398,
        Specialty: 1000,
        amt: 2210,
    },
    {
        name: 'W',
        Medicine: 2000,
        Consultation: 1800,
        Specialty: 1000,
        amt: 2290,
    },
    {
        name: 'T',
        Medicine: 1780,
        Consultation: 1908,
        Specialty: 1000,
        amt: 2000,
    },
    {
        name: 'F',
        Medicine: 1890,
        Consultation: 1800,
        Specialty: 1000,
        amt: 2181,
    },
    {
        name: 'S',
        Medicine: 1390,
        Consultation: 1800,
        Specialty: 1000,
        amt: 2500,
    },
    {
        name: 'S',
        Medicine: 1490,
        Consultation: 1300,
        Specialty: 1000,
        amt: 2100,
    },
];

export default class Chart extends PureComponent {
    static demoUrl = 'https://codesandbox.io/s/stacked-bar-chart-s47i2';

    render() {
        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    barSize={10}
                    barCategoryGap={10}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                     <Bar dataKey="Consultation" stackId="a" fill="#0277FA" />
                    <Bar dataKey="Medicine" stackId="a" fill="#27955B" />
                    <Bar dataKey="Specialty" stackId="a" fill="#F7AA16" />
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="name" height={15} />
                    <YAxis />
                    <Tooltip />
                   
                    <Legend />
                    
                </BarChart>
            </ResponsiveContainer>
        );
    }
}
