import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography/Typography";
import Button from "@mui/material/Button/Button";
import MedicationsInput from "../../LoginAndRegister/RegistrationForms/MedicationsInput";

function EditMedications(props) {

    return (
        <Box sx={{m: 4}}>
            <Typography sx={{m: 1}} variant={'h6'}> Edit Medications </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant={"contained"}
                        sx={{mt: 2, mb: 1}}
                        onClick={props.onAddMedication}
                        color={'secondary'}
                    >
                        Add Medication
                    </Button>
                </Grid>
                {props.medications?.map(function (element, index) {
                    return(
                        <MedicationsInput
                            medications={props.medications}
                            index={index}
                            onChange={(med) => {
                                const newMedications = [...props.medications];
                                newMedications[index] = med;
                                props.onMedicationsChange(newMedications);
                            }}
                            onDelete={() => {
                                const newMedications = [...props.medications];
                                newMedications.splice(index, 1);
                                props.onMedicationsChange(newMedications);
                            }}
                            key={index}
                        />
                    )
                })}
            </Grid>
        </Box>
    )
}

EditMedications.propTypes = {
    medications: PropTypes.array,
    onMedicationsChange: PropTypes.func,
    onAddMedication: PropTypes.func,
};

export default EditMedications;