import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import PaginatedTable from "../../../components/TableComponents/PaginatedTable";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getPatientAppointments } from '../../../api/apigateway/apigateway';
import { store } from '../../../redux/store';


const createDataUpcoming = (picture, doctor, specialty, date, time, complaint) => {
    return {
        picture,
        doctor,
        specialty,
        date,
        time,
        complaint
    };
};

const upcomingApptsRows = [
    createDataUpcoming("https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        'Sourish Agarwal', 'General Medicine', new Date().getDate(), '10:00AM', 'xyz'),
    createDataUpcoming("https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        'Priyam Mohanty', 'Family Medicine', new Date().getDate(), '10:00AM', 'xyz'),
    createDataUpcoming("https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        'Siddharth Bansal', 'Psychiatry', new Date().getDate(), '10:00AM', 'xyz'),
    createDataUpcoming("https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        'Rohan Hajela', 'Cardiologist', new Date().getDate(), '10:00AM', 'xyz'),
    createDataUpcoming("https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        'Andy Zhao', 'Physical Therapy', new Date().getDate(), '10:00AM', 'xyz'),
    createDataUpcoming("https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        'Mark Ashkan', 'Health care Consultation', new Date().getDate(), '10:00AM', 'xyz'),
    createDataUpcoming("https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        'Doctor 7', '', new Date().getDate(), '10:00AM', 'xyz'),
    createDataUpcoming("https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        'Doctor 8', '', new Date().getDate(), '10:00AM', 'xyz'),
    createDataUpcoming("https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        'Doctor 9', '', new Date().getDate(), '10:00AM', 'xyz'),
    createDataUpcoming("https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        'Doctor 10', '', new Date().getDate(), '10:00AM', 'xyz'),
];

const upcomingApptsHeadcells = [
    {
        id: 'collapser',
        numeric: false,
        label: ''
    },
    {
        id: 'picture',
        numeric: false,
        label: '',
    },
    {
        id: 'provider',
        numeric: false,
        label: 'Doctor',
    },
    {
        id: 'specialty',
        numeric: false,
        label: 'Specialty',
    },
    {
        id: 'date',
        numeric: false,
        label: 'Date',
    },
    {
        id: 'time',
        numeric: false,
        label: 'Time',
    },
    {
        id: 'complaint',
        numeric: false,
        label: 'Complaint',
    },
];

function CustomTableRow(props) {
    const row = props.row;
    const userInfo = props.userInfo;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow
                hover
                onClick={() => {}}
                key={row.name}
            >
                <TableCell>
                    <IconButton
                        size={'small'}
                        onClick={() => setOpen(!open)}
                    >
                        {open ?
                            <KeyboardArrowRightIcon/> :
                            <KeyboardArrowDownIcon/>
                        }
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Avatar src={userInfo[row.doctorId] ? userInfo[row.doctorId].picture : null} sx={{}}/>
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {userInfo[row.doctorId] ? userInfo[row.doctorId].firstName + " " + userInfo[row.doctorId].lastName : null}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {userInfo[row.doctorId] ? userInfo[row.doctorId].specialty : null}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {new Date(row.startDate).toLocaleString().split(",")[0]}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {new Date(row.startDate).toLocaleString().split(",")[1]}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.complaint}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingTop: 0, paddingBottom: 0}} colSpan={7}>
                    <Collapse in={open} timeout={'auto'} unmountOnExit>
                        <Box sx={{padding: 1}}>
                            <Typography variant={'h6'}>
                                Appointment Information
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


function UpcomingAppointments(props) {
    const [upcomingRows, setUpcomingRows] = useState([]);
    const [pastRows, setPastRows] = useState([]);
    const [nextAppt, setNextAppt] = useState([]);
    const [providerInfo, setProviderInfo] = useState({});

    useEffect(() => {
        getPatientAppointments(store.getState().userID, (rows) => setUpcomingRows(rows), (rows) => setPastRows(rows), (info) => setProviderInfo(info));
        console.log('next appt', nextAppt);
    }, []);

    useEffect(() => {
        if (upcomingRows.length > 0) {
            setNextAppt(upcomingRows[0]);
        }
    }, [upcomingRows]);

    useEffect(() => {
        props.onChannelChange(nextAppt.channelName);
        props.onTokenChange(nextAppt.agoraToken);
    }, [nextAppt]);


    return (
        <Box sx={{}}>
            <Grid container spacing={3}>
            {nextAppt?.length !== 0 &&
                <Grid item xs={12}>
                    <Paper sx={{padding: 2}}>
                        <Typography variant={'h6'}>
                            Next Appointment
                        </Typography>
                        <Box sx={{mt: 2, display: 'flex', flexDirection: 'row'}}>
                            <Avatar sx={{width:35, height:35}} src={providerInfo[nextAppt.doctorId] ? providerInfo[nextAppt.doctorId].picture : null}/>
                            <Typography sx={{ml: 1.6, mt: 0.6}}>Dr. {providerInfo[nextAppt.doctorId] ? providerInfo[nextAppt.doctorId].firstName + " " + providerInfo[nextAppt.doctorId].lastName : null}</Typography>
                        </Box>
                        <Box sx={{mt: 2, mb: 2, ml: 1, mr: 1}}>
                            <Typography variant={'subtitle2'}> Appointment: </Typography>
                            <Typography variant={'body2'}> {nextAppt.title} </Typography>
                            {/* <Typography variant={'body2'}> {nextAppt.agoraToken} </Typography> */}
                        </Box>
                        <Button variant={'contained'} onClick={props.onJoin}>
                            Join Now!
                        </Button>
                    </Paper>
                </Grid>}
                <Grid item xs={6}>
                    <PaginatedTable
                        tableName={'Upcoming Appointments'}
                        headCells={upcomingApptsHeadcells}
                        rows={upcomingRows}
                        userInfo={providerInfo}
                        CustomTableRow={CustomTableRow}
                        paginationOptions={[5, 10, 15]}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PaginatedTable
                        tableName={'Past Appointments'}
                        headCells={upcomingApptsHeadcells}
                        rows={pastRows}
                        userInfo={providerInfo}
                        CustomTableRow={CustomTableRow}
                        paginationOptions={[5, 10, 15]}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

UpcomingAppointments.propTypes = {

};

export default UpcomingAppointments;