import React, { useState, useEffect } from "react";
import * as PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box/Box";
import Drawer from "@mui/material/Drawer/Drawer";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import IconButton from "@mui/material/IconButton/IconButton";
import Avatar from "@mui/material/Avatar/Avatar";
import Collapse from "@mui/material/Collapse/Collapse";
import Typography from "@mui/material/Typography/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { store } from "../../redux/store";
import * as api from "../../api/apigateway/apigateway";
import RightSidebar from "../RightSidebar";
import "../../styles/PatientDashboard.css";
import { getPicture, getPatientUploads } from "../../api/apigateway/apigateway";
import calendarIcon from "../../assets/icons/Calendar.svg";
import PastMedicalRecords from "../../components/Profile/PatientProfileComponents/PastMedicalRecords";

function CustomTableRow(props) {
  const row = props.row;
  const userInfo = props.userInfo;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow hover onClick={() => {}} key={row.name}>
        <TableCell>
          <IconButton size={"small"} onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Avatar
            src={userInfo[row.doctorId] ? userInfo[row.doctorId].picture : null}
            sx={{}}
          />
        </TableCell>
        <TableCell padding={"normal"} align={"left"}>
          {userInfo[row.doctorId]
            ? userInfo[row.doctorId].firstName +
              " " +
              userInfo[row.doctorId].lastName
            : null}
        </TableCell>
        <TableCell padding={"normal"} align={"left"}>
          {userInfo[row.doctorId] ? userInfo[row.doctorId].specialty : null}
        </TableCell>
        <TableCell padding={"normal"} align={"left"}>
          {new Date(row.startDate).toLocaleString().split(",")[0]}
        </TableCell>
        <TableCell padding={"normal"} align={"left"}>
          {new Date(row.startDate).toLocaleString().split(",")[1]}
        </TableCell>
        <TableCell padding={"normal"} align={"left"}>
          {row.complaint}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} colSpan={7}>
          <Collapse in={open} timeout={"auto"} unmountOnExit>
            <Box sx={{ padding: 1 }}>
              <Typography variant={"h6"}>Appointment Information</Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function PatientDashboard() {
  const [upcomingRows, setUpcomingRows] = useState([]);
  const [pastRows, setPastRows] = useState([]);
  const [providerInfo, setProviderInfo] = useState({});
  const [walletAddress, setWalletAddress] = useState(null);
  const [addy, setAddy] = useState(null);
  const [balance, setBalance] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profilePicture, setProfilePicture] = useState();
  const [records, setRecords] = useState(null);
  const [patientId] = useState(store.getState().userID);

  useEffect(() => {
    api.getPatientAppointments(
      store.getState().userID,
      (rows) => setUpcomingRows(rows),
      (rows) => setPastRows(rows),
      (info) => setProviderInfo(info)
    );
    api.getPatientDashboardData(store.getState().userID, (data) => {
      setFirstName(data["firstName"]);
      setLastName(data["lastName"]);
    });
    getPicture(store.getState().userID, setProfilePicture);
    getPatientUploads(patientId, setRecords);
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Box sx={{ ml: 30 }}>
        <Box
          className="h_head"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 3, mt: 4 }}
        >
          <h2>
            <b>Hello</b>
            {", " + firstName + " " + lastName} 👋
          </h2>
          <Box>
            <img src={calendarIcon} alt="..." />
            <span>{new Date().toDateString()}</span>
          </Box>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box className="total_appointments">
              <Box className="ta_head">
                <PastMedicalRecords records={records} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Drawer
          sx={{
            width: 300,
          }}
          variant="permanent"
          anchor="right"
        >
          <RightSidebar
            isPatient={true}
            solanaBalance={balance.toFixed(3)}
            name={firstName && lastName ? firstName + " " + lastName : null}
            profilePhoto={profilePicture}
          />
        </Drawer>
      </Box>
    </Box>
  );
}

export default PatientDashboard;
