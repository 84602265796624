import React, { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Button, TextField, Paper, Container, Typography } from '@mui/material';
import * as pools from "../../api/authorization/PoolCredentials";
import { useHistory } from 'react-router-dom'; // Import useHistory

const ForgotPassword = () => {
    const [username, setUsername] = useState('');
    const [message, setMessage] = useState('');
    const history = useHistory(); // Initialize useHistory

    const onSubmit = event => {
        event.preventDefault();

        const user = new CognitoUser({
            Username: username,
            Pool: pools.providerUserPool,
        });

        user.forgotPassword({
            onSuccess: () => {
                setMessage('Reset code sent to your email.');
                history.push('/recoverpassword'); // Redirect to /recoverpassword
            },
            onFailure: err => {
                setMessage(`Error: ${err.message}`);
            },
        });
    };

    return (
        <Container spacing={10} justify="center" alignItems="center" style={{ minHeight: '100vh' }}>
                <Paper style={{ padding: 20 }}>
                    <Typography variant="h5">Forgot Password</Typography>
                    {message && <Typography color="secondary">{message}</Typography>}
                    <form onSubmit={onSubmit}>
                        <TextField 
                            label="Username" 
                            value={username} 
                            onChange={e => setUsername(e.target.value)} 
                            fullWidth 
                            margin="normal"
                        />
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Send Reset Code
                        </Button>
                    </form>
                </Paper>
            </Container>
    );
};

export default ForgotPassword;
