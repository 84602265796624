import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Switch } from "@mui/material";
import { AccountContext } from "../../api/authorization/Account";
import { store } from "../../redux/store";
import { setPool, setUser } from "../../redux/actions";
import dxmdLogo from '../../assets/icons/DXMDLogo.svg';

const theme = createTheme();

function Login() {

    const history = useHistory();
    const { authenticate, getSession } = useContext(AccountContext);

    const [isPatient, setIsPatient] = useState(window.location.href.includes('portal'));
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const onPatientStatusChange = (event) => {
        setIsPatient(event.target.checked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const portalRoute = isPatient ? '/patient' : '/provider';
        authenticate(username, password, isPatient)
            .then((data) => {
                store.dispatch(
                    setUser({
                        username: username,
                        sub: data.data.accessToken.payload.sub,
                        accessToken: data.data.accessToken,
                        refreshToken: data.data.refreshToken,
                        idToken: data.data.idToken,
                        pool: data.pool,
                        email: ''
                    })
                );
                history.push(portalRoute);
            })
            .catch((err) => {
                alert(err.message || JSON.stringify(err));
                console.log("Failed to login: ", err);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <img src={dxmdLogo} width="100" />
                    <br />
                    <Typography component="h1" variant="h5">
                        Double Check Care
                    </Typography>
                    <Typography component="h1" variant="h5">
                        Secure {isPatient ? "Patient" : "Provider"} Portal
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        {(window.location.href.includes("localhost") || window.location.href.includes("vercel.app")) && <FormControlLabel
                            control={<Switch
                                checked={isPatient}
                                onChange={onPatientStatusChange} />}
                            label={isPatient ? "I am a patient" : "I am a provider"}
                        />}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={username}
                            onChange={(event) => { setUsername(event.target.value) }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(event) => { setPassword(event.target.value) }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                {(window.location.href.includes("localhost") || window.location.href.includes("vercel.app")) && <Link href="" variant="body2" to={'/register'}>
                                    Register
                                </Link>}
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2" to={'/recoveraccount'}>
                                    Forgot Password
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Login;
