import * as React from 'react';
import { useState, useContext } from 'react';
import * as PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AccountContext } from "../../api/authorization/Account";

const theme = createTheme();

function ChangePassword(props) {

    const { getSession } = useContext(AccountContext);

    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        getSession(false).then((res) => {
            const user = res.User;
            user.changePassword(password, newPassword, (err, result) => {
                if (err) {
                    setMessage("Error changing password.")
                } else {
                    setMessage("Password successfully changed.")
                }
            });
        })
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Change Password
                    </Typography>
                    <Typography color="secondary">
                        {message}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            autoComplete='current-password'
                            required
                            fullWidth
                            label="Current Password"
                            type="password"
                            autoFocus
                            value={password}
                            onChange={(event) => {setPassword(event.target.value)}}
                        />
                        <TextField
                            margin="normal"
                            autoComplete='new-password'
                            required
                            fullWidth
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={(event) => {setNewPassword(event.target.value)}}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={(e) => handleSubmit(e)}
                        >
                            Update Password
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

ChangePassword.propTypes = {
    isPatient: PropTypes.bool.isRequired
};

export default ChangePassword;