import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    TableSortLabel
} from "@mui/material";
import CustomTableToolbar from './CustomTableToolbar';
import CustomTableHead from './CustomTableHead';

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    } else if (b[orderBy] > a[orderBy]) {
        return 1;
    } else {
        return 0;
    }
};

const getComparator = (order, orderBy) => {
    return order === 'desc' ?
        (a, b) => descendingComparator(a, b, orderBy) :
        (a, b) => -descendingComparator(a, b, orderBy);
};

const sort = (arr, comparator) => {
    if (arr) {
        const withIndices = arr.map((element, index) => [element, index]);
        withIndices.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            } else {
                return a[1] - b[1];
            }
        });
        const sorted = withIndices.map((element) => element[0]);
        return sorted;
    }
    return [];
};

function PaginatedTable(props) {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('lastVisit');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [query, setQuery] = useState('');

    const onPageChange = (event, newPage) => {
        setPage(newPage);
    };

    const onQueryChange = (event) => {
        setQuery(event.target.value);
    };

    const onRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

    return (
        <Box sx={props.sx}>
            <Paper sx={{overflow: 'hidden'}}>
                <CustomTableToolbar
                    title={props.tableName}
                    query={query}
                    onQueryChange={onQueryChange}
                />
                <TableContainer sx={{maxHeight: props.maxHeight?props.maxHeight:750}}>
                    <Table
                        aria-labelledby={'Patients'}
                        size={'medium'}
                        stickyHeader
                        aria-label={"sticky table"}
                    >
                        <CustomTableHead
                            orderBy={orderBy}
                            order={order}
                            createSortHandler={createSortHandler}
                            headCells={props.headCells}
                        />
                        <TableBody>
                            {sort(query ?
                                props.rows.filter((r) => r['name'].includes(query)) :
                                props.rows,
                                getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <props.CustomTableRow userInfo={props.userInfo} row={row} key={index}/>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.paginationOptions?props.paginationOptions:[5, 10, 15, 25, 50]}
                    component={'div'}
                    count={props.rows ? props.rows.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                />
            </Paper>
        </Box>
    );
}

PaginatedTable.propTypes = {
    tableName: PropTypes.string.isRequired,
    headCells: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    userInfo: PropTypes.object.isRequired,
    CustomTableRow: PropTypes.func.isRequired,
    paginationOptions: PropTypes.array,
    maxHeight: PropTypes.number,
    sx: PropTypes.object
};

export default PaginatedTable;