export const disclaimer = "Patient’s rights, confidentiality, limits and exceptions to confidentiality, and use of electronic medical records were reviewed with patient. Verification of patient identity was established with patient. Patient consents to telemedicine.";

export const reviewOfSystems = "Review of at least 10 organ systems is otherwise negative except as stated above.";

export const gen1txt = "well-developed and well-nourished";
export const gen2txt = "alert and cooperative";
export const gen3txt = "in no acute distress";
export const gen4txt = "well-appearing and nontoxic";

export const heent1txt = "normocephalic";
export const heent2txt = "atraumatic";
export const heent3txt = "EOMI";
export const heent4txt = "vision grossly intact bilaterally";
export const heent5txt = "no conjunctival injection or visible discharge";
export const heent6txt = "hearing grossly intact bilaterally";
export const heent7txt = "no frontal sinus tenderness bilaterally";
export const heent8txt = "no maxillary sinus tenderness bilaterally";
export const heent9txt = "no oropharyngeal erythema/edema/exudate";
export const heent10txt = "no uvular deviation";
export const heent11txt = "speaks in clear and unmuffled voice";
export const heent12txt = "lips/tongue/dentition/gingiva within normal limits";

export const neck1txt = "supple with full range of motion";
export const neck2txt = "no stridor";
export const neck3txt = "no midline tenderness of stepoff";
export const neck4txt = "no cervical lymph node tenderness bilaterally";
export const neck5txt = "no cervical lymph node swelling bilaterally";
export const neck6txt = "no visible thyromegaly or other masses";

export const resp1txt = "no audible rales/rhonchi/wheezing";
export const resp2txt = "normal respiratory effort";
export const resp3txt = "speaking full sentence";
export const resp4txt = "no visible signs of tachypnea";
export const resp5txt = "no retractions";

export const cardio1txt = "peripheral pulses intact and palpable";
export const cardio2txt = "pulse felt to be regular rate and rhythm by patient";

export const abd1txt = "no focal abdominal tenderness elicited upon palpation";
export const abd2txt = "no visible abdominal distension";
export const abd3txt = "no abnormal masses found upon palpation";

export const back1txt = "no CVAT elicited bilaterally";
export const back2txt = "no midline tenderness or stepoff upon palpation";

export const extremities1txt = "no bony tenderness";
export const extremities2txt = "no visible gross deformity";
export const extremities3txt = "no edema";
export const extremities4txt = "normal range of motion";

export const neuro1txt = "CN III-XII grossly intact";
export const neuro2txt = "normal-appearing mental status";
export const neuro3txt = "moving all extremities normally";
export const neuro4txt = "grossly normal motor strength throughout";
export const neuro5txt = "sensation to light touch grossly intact throughout";
export const neuro6txt = "normal speech";
export const neuro7txt = "normal gait";

export const psych1txt = "normal-appearing affect";

export const planMainTxt = "Diagnostic rationale, follow up instructions, and strict precautions/indications for emergent direct evaluation were discussed with the patient. The patient agrees with the plan, and understands to follow up with their primary care physician or other healthcare provider";
export const planTxt1 = "within 24-48 hours";
export const planTxt2 = "within 48-72 hours";
export const planTxt3 = "within 1 week";
export const planTxt4 = "as needed";
export const planMainTxt2 = "for reevaluation. The patient understands to report immediately to the nearest Emergency Department or Urgent Care Center for any concerning/worsening signs or symptoms.";

