import * as React from 'react';
import {useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {createTheme, Switch, ThemeProvider} from "@mui/material";
import Container from "@mui/material/Container/Container";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import Box from "@mui/material/Box/Box";
import Avatar from "@mui/material/Avatar/Avatar";
import ProfileIcon from "@mui/material/SvgIcon/SvgIcon";
import Typography from "@mui/material/Typography/Typography";
import Grid from "@mui/material/Grid/Grid";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField/TextField";
import Divider from "@mui/material/Divider/Divider";
import LocalizationProvider from "@mui/lab/LocalizationProvider/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker/DatePicker";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import AllergyInput from './RegistrationForms/AllergyInput';
import MedicationsInput from "./RegistrationForms/MedicationsInput";
import PhysicianInput from './RegistrationForms/PhysicianInput';
import ConditionsInput from './RegistrationForms/ConditionsInput';
import axios from 'axios';
import { store } from '../../redux/store';

const theme = createTheme();

function PatientRegistration() {
    const gridSize = 12;

    const history = useHistory();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [preferredName, setPreferredName] = useState("");
    const [dob, setDOB] = React.useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [lang, setLang] = useState("");
    const [gender, setGender] = useState(null);
    const [pronouns, setPronouns] = useState(null);
    const [hasInsurance, setHasInsurance] = useState(false);
    const [insurance, setInsurance] = useState(null);
    const [memberID, setMemberID] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [hasAllergies, setHasAllergies] = useState(false);
    const [allergies, setAllergies] = useState([]);
    const [doesSmokeTobacco, setDoesSmokeTobacco] = useState(false);
    const [frequencyOfTobacco, setFrequencyOfTobacco] = useState(null);
    const [historyOfTobaccoUse, setHistoryOfTobaccoUse] = useState(null);
    const [doesSmokeMarijuana, setDoesSmokeMarijuana] = useState(false);
    const [frequencyOfMarijuana, setFrequencyOfMarijuana] = useState(null);
    const [historyOfMarijuanaUse, setHistoryOfMarijuanaUse] = useState(null);
    const [doesDrinkAlcohol, setDoesDrinkAlcohol] = useState(false);
    const [frequencyOfAlcohol, setFrequencyOfAlcohol] = useState(null);
    const [isTakingMedications, setIsTakingMedications] = useState(false);
    const [medications, setMedications] = useState([]);
    const [hasConditions, setHasConditions] = useState(false);
    const [conditions, setConditions] = useState([]);
    const [hasAPhysician, setHasAPhysician] = useState(false);
    const [physicians, setPhysicians] = useState([]);

    const onFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const onLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const onPreferredNameChange = (event) => {
        setPreferredName(event.target.value);
    };

    const onDOBChange = (val) => {
        setDOB(val);
    };

    const onStreetChange = (event) => {
        setStreet(event.target.value);
    };

    const onCityChange = (event) => {
        setCity(event.target.value);
    };

    const onStateChange = (event) => {
        setState(event.target.value);
    };

    const onZipChange = (event) => {
        setZip(event.target.value);
    };

    const onLangChange = (event) => {
        setLang(event.target.value);
    };

    const onGenderChange = (event, val) => {
        setGender(val);
    };

    const onPronounsChange = (event, newValue) => {
        setPronouns(newValue);
    };

    const onHasInsuranceChange = (event) => {
        setHasInsurance(event.target.checked);
    };

    const onInsuranceChange = (event, val) => {
        setInsurance(val);
    };

    const onMemberIdChange = (event) => {
        setMemberID(event.target.value);
    };

    const onHeightChange = (event) => {
        setHeight(event.target.value);
    };

    const onWeightChange = (event) => {
        setWeight(event.target.value);
    };

    const onHasAllergiesChange = (event) => {
        setHasAllergies(event.target.checked);
    };

    const onAddAllergy = () => {
        const newAllergies = [...allergies];
        newAllergies.push({
            type: null,
            allergy: "",
            reaction: ""
        });
        setAllergies(newAllergies);
    };

    const onDoesSmokeTobaccoChange = (event) => {
        setDoesSmokeTobacco(event.target.checked);
    };

    const onFrequencyOfTobaccoChange = (event, val) => {
        setFrequencyOfTobacco(val);
    };

    const onHistoryOfTobaccoChange = (event, val) => {
        setHistoryOfTobaccoUse(val);
    };

    const onDoesSmokeMarijuanaChange = (event) => {
        setDoesSmokeMarijuana(event.target.checked);
    };

    const onFrequencyOfMarijuanaChange = (event, val) => {
        setFrequencyOfMarijuana(val);
    };

    const onHistoryOfMarijuanaChange = (event, val) => {
        setHistoryOfMarijuanaUse(val);
    };

    const onDoesDrinkAlcoholChange = (event) => {
        setDoesDrinkAlcohol(event.target.checked);
    };

    const onFrequencyOfAlcoholChange = (event, val) => {
        setFrequencyOfAlcohol(val);
    };

    const onHasConditionsChange = (event) => {
        setHasConditions(event.target.checked);
    };

    const onAddCondition = () => {
        const newConditions = [...conditions];
        newConditions.push({
            name: "",
            dosage: "",
            instructions: null,
            status: null
        });
        setConditions(newConditions);
    };

    const onIsTakingMedicationsChange = (event) => {
        setIsTakingMedications(event.target.checked);
    };

    const onAddMedication = () => {
        const newMedications = [...medications];
        newMedications.push({
            name: "",
            dosage: "",
            instructions: null,
            status: null
        });
        setMedications(newMedications);
    };

    const onHasAPhysicianChange = (event) => {
        setHasAPhysician(event.target.checked);
    };

    const onAddPhysician = () => {
        const newPhysicians = [...physicians];
        newPhysicians.push({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            extension: '',
            fax: '',
            faxExtension: '',
            specialty: null
        });
        setPhysicians(newPhysicians);
    };

    const handleSave = (event) => {
        const data = {
            uuid: store.getState().userID,
            firstName: firstName,
            lastName: lastName,
            dateOfBirth: dob,
            profilePicture: "",
            insurance: {
                insuranceProvider: insurance,
                insuranceMemberID: memberID
            },
            address: {
                street: street,
                city: city,
                state: state,
                zipCode: zip,
            },
            profile: {
                preferredName: preferredName,
                preferredLanguage: lang,
                gender: gender,
                pronouns: pronouns,
                height: height,
                weight: weight,
                allergies: allergies,
                smokesTobacco: doesSmokeTobacco,
                frequencyOfTobaccoUse: frequencyOfTobacco,
                historyOfTobaccoUse: historyOfTobaccoUse,
                smokesMarijuana: doesSmokeMarijuana,
                frequencyOfMarijuanaUse: frequencyOfMarijuana,
                historyOfMarijuanaUse: historyOfMarijuanaUse,
                drinksAlcohol: doesDrinkAlcohol,
                frequencyOfAlcoholConsumption: frequencyOfAlcohol,
                conditions: conditions,
                medications: medications,
                physicians: physicians
            }
        };

        console.log(data);

        console.log(JSON.stringify(data, null, '    '));

        axios.post(
            "https://4ibcrbb1p2.execute-api.us-west-1.amazonaws.com/dev/registerpatient",
            data
        ).then(function (response) {
            console.log(response["status"]);
        }).catch(function (error) {
            console.error(error);
        });

        history.push("/patient");
    };

    useEffect(() => {
        console.log(gender);
    },[gender]);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <ProfileIcon />
                    </Avatar>
                    <Typography component="h1" variant="h4" sx={{m: 1}}>
                        My Profile
                    </Typography>
                    <Typography component="h1" variant="body1" align='center' sx={{m:1}}>
                        Please complete your profile. This can also be completed/edited in the MyProfile tab later.
                    </Typography>
                    {/*-----------------------FORM-----------------------------------------*/}
                    <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 1 }}>
                        <Grid container>
                            <Grid item xs={gridSize}>
                                <Divider dark sx={{marginTop: 1, marginBottom: 1, borderBottomWidth: 3}}/>
                            </Grid>
                            <Grid item xs={gridSize}>
                                <Typography component="h1" variant="body1" align='left' sx={{marginTop: 2, fontWeight:'bold'}}>
                                    General:
                                </Typography>
                            </Grid>
                            <Grid item xs={gridSize} sm={gridSize/2}>
                                <TextField
                                    margin="normal"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                    value={firstName}
                                    onChange={onFirstNameChange}
                                />
                            </Grid>
                            <Grid item xs={gridSize} sm={gridSize/2}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    value={lastName}
                                    onChange={onLastNameChange}
                                />
                            </Grid>
                            <Grid item xs={gridSize}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="preferredName"
                                    label="Preferred Name"
                                    name="preferredName"
                                    value={preferredName}
                                    onChange={onPreferredNameChange}
                                />
                            </Grid>
                            <Grid item xs={gridSize}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Birthday"
                                        value={dob}
                                        onChange={onDOBChange}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    fullWidth
                                                    margin={"normal"}
                                                    required
                                                    id={"dob"}
                                                    name={"dob"}
                                                    autoFocus
                                                    {...params}
                                                />
                                            );
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={gridSize}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="street"
                                    label="Street"
                                    name="street"
                                    value={street}
                                    onChange={onStreetChange}
                                />
                            </Grid>
                            <Grid item xs={gridSize} sm={gridSize/2}>
                                <TextField
                                    required
                                    fullWidth
                                    id="city"
                                    label="City"
                                    name="city"
                                    value={city}
                                    onChange={onCityChange}
                                />
                            </Grid>
                            <Grid item xs={gridSize} sm={gridSize/4}>
                                <TextField
                                    required
                                    fullWidth
                                    id="state"
                                    label="State"
                                    name="state"
                                    value={state}
                                    onChange={onStateChange}
                                />
                            </Grid>
                            <Grid item xs={gridSize} sm={gridSize/4}>
                                <TextField
                                    required
                                    fullWidth
                                    id="zip"
                                    label="Zip Code"
                                    name="zip"
                                    value={zip}
                                    onChange={onZipChange}
                                />
                            </Grid>
                            <Grid item xs={gridSize}>
                                <TextField
                                    margin={"normal"}
                                    required
                                    fullWidth
                                    id="language"
                                    label="Preferred Language"
                                    name="language"
                                    value={lang}
                                    onChange={onLangChange}
                                    sx={{marginTop: 3}}
                                />
                            </Grid>
                            <Grid item xs={gridSize}>
                                <Autocomplete
                                    disablePortal
                                    id="genderDropdown"
                                    options={[
                                        'Male',
                                        'Female',
                                        'Prefer Not To Say',
                                        'Other'
                                    ]}
                                    value={gender}
                                    onChange={onGenderChange}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                fullWidth
                                                margin={"normal"}
                                                required
                                                id={"gender"}
                                                name={"gender"}
                                                label={"Gender"}
                                                autoFocus
                                                {...params}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={gridSize}>
                                <Autocomplete
                                    disablePortal
                                    id="pronounsDropdown"
                                    options={[
                                        'She/Her/Hers',
                                        'He/Him/His',
                                        'They/Them/Theirs',
                                        'Per/Per/Pers',
                                        'Ze/Zir/Zirs',
                                        'Xe/Xem/Xyrs',
                                        'Ze/Hir/Hirs',
                                        'E/Em/Eirs',
                                        'Prefer Not to Say',
                                        'Other'
                                    ]}
                                    value={pronouns}
                                    onChange={onPronounsChange}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                fullWidth
                                                margin={"normal"}
                                                required
                                                id={"pronouns"}
                                                name={"pronouns"}
                                                label={"Pronouns"}
                                                autoFocus
                                                {...params}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={gridSize}>
                                <Divider dark sx={{marginTop: 2, marginBottom: 1, borderBottomWidth: 3}}/>
                            </Grid>
                            <Grid item xs={gridSize}>
                                <Typography component="h1" variant="body1" align='left' sx={{marginTop: 2, fontWeight:'bold'}}>
                                    Insurance:
                                </Typography>
                            </Grid>
                            <Grid item xs={gridSize}>
                                <FormControlLabel
                                    control={<Switch
                                        checked={hasInsurance}
                                        onChange={onHasInsuranceChange}/>}
                                    label={"I have health insurance."}
                                />
                            </Grid>
                            {hasInsurance &&
                                <Grid item xs={gridSize}>
                                    <Autocomplete
                                        disablePortal
                                        options={[
                                            'insurance1',
                                            'insurance2',
                                            'insurance3'
                                        ]}
                                        value={insurance}
                                        onChange={onInsuranceChange}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    fullWidth
                                                    margin={"normal"}
                                                    id={"insurance"}
                                                    name={"insurance"}
                                                    label={"Insurance Provider"}
                                                    autoFocus
                                                    {...params}
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            }
                            {hasInsurance &&
                                <Grid item xs={gridSize}>
                                    <TextField
                                        margin={"normal"}
                                        fullWidth
                                        id="memberId"
                                        label="Member ID"
                                        name="memberId"
                                        value={memberID}
                                        onChange={onMemberIdChange}
                                    />
                                </Grid>
                            }

                            <Grid item xs={gridSize}>
                                <Divider dark sx={{marginTop: 2, marginBottom: 1, borderBottomWidth: 3}}/>
                            </Grid>
                            <Grid item xs={gridSize}>
                                <Typography component="h1" variant="body1" align='left' sx={{marginTop: 2, fontWeight:'bold'}}>
                                    Medical Information:
                                </Typography>
                            </Grid>
                            <Grid item xs={gridSize} sm={gridSize/2}>
                                <TextField
                                    margin={"normal"}
                                    fullWidth
                                    id="height"
                                    label="Height"
                                    name="height"
                                    value={height}
                                    onChange={onHeightChange}
                                />
                            </Grid>
                            <Grid item xs={gridSize} sm={gridSize/2}>
                                <TextField
                                    margin={"normal"}
                                    fullWidth
                                    id="weight"
                                    label="Weight"
                                    name="weight"
                                    value={weight}
                                    onChange={onWeightChange}
                                />
                            </Grid>
                            <Grid item xs={gridSize}>
                                <FormControlLabel
                                    control={<Switch
                                        checked={hasAllergies}
                                        onChange={onHasAllergiesChange}/>}
                                    label={"I have allergies."}
                                />
                            </Grid>
                            {hasAllergies &&
                                <Grid item xs={gridSize} sm={6}>
                                    <Button
                                        fullWidth
                                        variant={"contained"}
                                        sx={{mt: 2, mb: 1}}
                                        onClick={onAddAllergy}
                                        color={'secondary'}
                                    >
                                        Add Allergy
                                    </Button>
                                </Grid>
                            }
                            {hasAllergies &&
                                [...allergies].map(function (element, i) {
                                    return(
                                        <AllergyInput
                                            onChange={function (allergyObj, index=i) {
                                                const newAllergies = [...allergies];
                                                newAllergies[index] = allergyObj;
                                                setAllergies(newAllergies);
                                            }}
                                            onDelete={() => {
                                                const newAllergies = [...allergies];
                                                newAllergies.splice(i, 1);
                                                setAllergies(newAllergies);
                                            }}
                                            allergies={allergies}
                                            index={i}
                                            key={i}
                                        />
                                    )
                            })}
                            <Grid item xs={gridSize}>
                                <FormControlLabel
                                    control={<Switch
                                        checked={doesSmokeTobacco}
                                        onChange={onDoesSmokeTobaccoChange}/>}
                                    label={"I smoke/use tobacco."}
                                />
                            </Grid>
                            {doesSmokeTobacco &&
                                <Grid container>
                                    <Grid item xs={gridSize}>
                                        <Autocomplete
                                            disablePortal
                                            options={[
                                                'Daily',
                                                'Weekly',
                                                'Occasionally',
                                                'Socially'
                                            ]}
                                            value={frequencyOfTobacco}
                                            onChange={onFrequencyOfTobaccoChange}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        fullWidth
                                                        margin={"normal"}
                                                        required
                                                        id={"frequencyOfTobacco"}
                                                        name={"frequencyOfTobacco"}
                                                        label={"How Often?"}
                                                        autoFocus
                                                        {...params}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={gridSize}>
                                        <Autocomplete
                                            disablePortal
                                            options={[
                                                '1-5',
                                                '6-10',
                                                '11-15',
                                                '15-20',
                                                'More than 20'
                                            ]}
                                            value={historyOfTobaccoUse}
                                            onChange={onHistoryOfTobaccoChange}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        fullWidth
                                                        margin={"normal"}
                                                        required
                                                        id={"historyOfTobacco"}
                                                        name={"historyOfTobacco"}
                                                        label={"For how many years?"}
                                                        autoFocus
                                                        {...params}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={gridSize}>
                                <FormControlLabel
                                    control={<Switch
                                        checked={doesSmokeMarijuana}
                                        onChange={onDoesSmokeMarijuanaChange}/>}
                                    label={"I smoke/use marijuana."}
                                />
                            </Grid>
                            {doesSmokeMarijuana &&
                                <Grid container>
                                    <Grid item xs={gridSize}>
                                        <Autocomplete
                                            disablePortal
                                            options={[
                                                'Daily',
                                                'Weekly',
                                                'Occasionally',
                                                'Socially'
                                            ]}
                                            value={frequencyOfMarijuana}
                                            onChange={onFrequencyOfMarijuanaChange}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        fullWidth
                                                        margin={"normal"}
                                                        required
                                                        id={"frequencyOfMarijuana"}
                                                        name={"frequencyOfMarijuana"}
                                                        label={"How Often?"}
                                                        autoFocus
                                                        {...params}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={gridSize}>
                                        <Autocomplete
                                            disablePortal
                                            options={[
                                                '1-5',
                                                '6-10',
                                                '11-15',
                                                '15-20',
                                                'More than 20'
                                            ]}
                                            value={historyOfMarijuanaUse}
                                            onChange={onHistoryOfMarijuanaChange}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        fullWidth
                                                        margin={"normal"}
                                                        required
                                                        id={"historyOfMarijuana"}
                                                        name={"historyOfMarijuana"}
                                                        label={"For how many years?"}
                                                        autoFocus
                                                        {...params}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={gridSize}>
                                <FormControlLabel
                                    control={<Switch
                                        checked={doesDrinkAlcohol}
                                        onChange={onDoesDrinkAlcoholChange}/>}
                                    label={"I drink alcohol."}
                                />
                            </Grid>
                            {doesDrinkAlcohol &&
                                <Grid item xs={gridSize}>
                                    <Autocomplete
                                        disablePortal
                                        options={[
                                            '1-5',
                                            '6-10',
                                            '11-15',
                                            '15-20',
                                            'More than 20'
                                        ]}
                                        value={frequencyOfAlcohol}
                                        onChange={onFrequencyOfAlcoholChange}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    fullWidth
                                                    margin={"normal"}
                                                    required
                                                    id={"frequencyOfAlcohol"}
                                                    name={"frequencyOfAlcohol"}
                                                    label={"How often?"}
                                                    autoFocus
                                                    {...params}
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={gridSize}>
                                <FormControlLabel
                                    control={<Switch
                                        checked={hasConditions}
                                        onChange={onHasConditionsChange}/>}
                                    label={"I have pre-existing medical conditions."}
                                />
                            </Grid>
                            {hasConditions &&
                                <Grid item xs={gridSize} sm={6}>
                                    <Button
                                        fullWidth
                                        variant={"contained"}
                                        sx={{mt: 2, mb: 1}}
                                        onClick={onAddCondition}
                                        color={'secondary'}
                                    >
                                        Add Condition
                                    </Button>
                                </Grid>
                            }
                            {hasConditions &&
                                [...conditions].map(function (element, index) {
                                    return(
                                        <ConditionsInput
                                            conditions={conditions}
                                            index={index}
                                            onChange={(cond) => {
                                                const newConditions = [...conditions];
                                                newConditions[index] = cond;
                                                setConditions(newConditions);
                                            }}
                                            onDelete={() => {
                                                const newConditions = [...conditions];
                                                newConditions.splice(index, 1);
                                                setConditions(newConditions);
                                            }}
                                            key={index}
                                        />
                                    )
                                })
                            }
                            <Grid item xs={gridSize}>
                                <FormControlLabel
                                    control={<Switch
                                        checked={isTakingMedications}
                                        onChange={onIsTakingMedicationsChange}/>}
                                    label={"I am taking medications."}
                                />
                            </Grid>
                            {isTakingMedications &&
                                <Grid item xs={gridSize} sm={6}>
                                    <Button
                                        fullWidth
                                        variant={"contained"}
                                        sx={{mt: 2, mb: 1}}
                                        onClick={onAddMedication}
                                        color={'secondary'}
                                    >
                                        Add Medication
                                    </Button>
                                </Grid>
                            }
                            {isTakingMedications &&
                                [...medications].map(function (element, index) {
                                    return(
                                        <MedicationsInput
                                            medications={medications}
                                            index={index}
                                            onChange={(med) => {
                                                const newMedications = [...medications];
                                                newMedications[index] = med;
                                                setMedications(newMedications);
                                            }}
                                            onDelete={() => {
                                                const newMedications = [...medications];
                                                newMedications.splice(index, 1);
                                                setMedications(newMedications);
                                            }}
                                            key={index}
                                        />
                                    )
                                })
                            }
                            <Grid item xs={gridSize}>
                                <FormControlLabel
                                    control={<Switch
                                        checked={hasAPhysician}
                                        onChange={onHasAPhysicianChange}/>}
                                    label={"I have a physician/care provider."}
                                />
                            </Grid>
                            {hasAPhysician &&
                                <Grid item xs={gridSize} sm={6}>
                                    <Button
                                        fullWidth
                                        variant={"contained"}
                                        sx={{mt: 2, mb: 1}}
                                        onClick={onAddPhysician}
                                        color={'secondary'}
                                    >
                                        Add Physician
                                    </Button>
                                </Grid>
                            }
                            {hasAPhysician &&
                                [...physicians].map((element, index) => {
                                    return (
                                        <PhysicianInput
                                            physicians={physicians}
                                            index={index}
                                            onChange={(physician) => {
                                                const newPhysicians = [...physicians];
                                                newPhysicians[index] = physician;
                                                setPhysicians(newPhysicians);
                                            }}
                                            onDelete={() => {
                                                const newPhysicians = [...physicians];
                                                newPhysicians.splice(index, 1);
                                                setPhysicians(newPhysicians);
                                            }}
                                            key={index}
                                        />
                                    );
                                })

                            }
                        </Grid>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default PatientRegistration;