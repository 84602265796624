import * as React from 'react';
import { createContext } from 'react';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import * as pools from "./PoolCredentials";
import { store } from '../../redux/store';
import {removeUser, setPool, setSession, setUser} from '../../redux/actions';

const AccountContext = createContext();

function Account(props) {

    const register = async (Username, Password, Email, isPatient) => {
        return await new Promise((resolve, reject) => {

            let attributeList = [new AmazonCognitoIdentity.CognitoUserAttribute({
                Name: "email",
                Value: Email
            })];

            const userPool = isPatient ? pools.patientUserPool : pools.providerUserPool;

            userPool.signUp(Username, Password, attributeList, null, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    };

    const confirmRegisteredUser = async (Username, Code, isPatient) => {
        return await new Promise((resolve, reject) => {
            const userPool = isPatient ? pools.patientUserPool : pools.providerUserPool;

            let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: Username,
                Pool: userPool,
            });
            cognitoUser.confirmRegistration(Code, true, function(err, result) {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            });
        });

    };

    const authenticate = async (Username, Password, isPatient) => {
        return await new Promise((resolve, reject) => {
            let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
                Username,
                Password
            });
            const pool = isPatient ? pools.patientUserPool : pools.providerUserPool;
            let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username,
                Pool: pool
            });

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    resolve({data: result, pool: pool});
                },

                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    };

    const getSession = async (isPatient) => {
        return await new Promise((resolve, reject) => {
            const Pool = isPatient ? pools.patientUserPool : pools.providerUserPool;
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession(async (err, session) => {
                    if (err) {
                        reject();
                    } else {
                        console.log('Session: ', session);
                        const attributes = await new Promise((resolve, reject) => {
                            user.getUserAttributes((err, attributes) => {
                               if (err) {
                                   reject(err);
                               } else {
                                   const results = {};
                                   for (let attribute of attributes) {
                                       const { Name, Value } = attribute;
                                       results[Name] = Value;
                                   }
                                   resolve(results);
                               }
                            });
                        });
                        resolve({User: user, ...session, ...attributes});
                    }
                });
            } else {
                reject();
            }
        });
    };

    const signOut = (isPatient, callBack) => {
        const Pool = isPatient ? pools.patientUserPool : pools.providerUserPool;
        const user = Pool.getCurrentUser();
        if (user) {
            store.dispatch(removeUser());
            user.signOut(() => {
                callBack();
            });
        }
    };

    return (
        <AccountContext.Provider value={{register, confirmRegisteredUser, authenticate, getSession, signOut}}>
            {props.children}
        </AccountContext.Provider>
    )
}

export { Account, AccountContext };