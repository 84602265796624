import * as React from 'react';
import * as PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid/Grid";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import {Switch} from "@mui/material";

function ProviderPsychologistLicenseInput(props) {

    const [licensedCountry, setLicensedCountry] = useState("");
    const [npi, setNPI] = useState("");
    const [holdsActiveStateLicense, setHoldsActiveStateLicense] = useState(false);
    const [canWorkWithoutSupervision, setCanWorkWithoutSupervision] = useState(false);
    const [licensedStates, setLicensedStates] = useState([]);
    const [stateLicensures, setStateLicensures] = useState([]);
    const [isMedicareProvider, setIsMedicareProvider] = useState(null);
    const [wantsHealthcareConsultingAppts, setWantsHealthcareConsultingAppts] = useState(false);
    const [yrsOfExp, setYrsOfExp] = useState("");
    const [expectedHoursPerWeek, setExpectedHoursPerWeek] = useState("");

    useEffect(() => {
        props.onFieldsChange({
            licensedCountry,
            npi,
            holdsActiveStateLicense,
            canWorkWithoutSupervision,
            licensedStates,
            stateLicensures,
            isMedicareProvider,
            wantsHealthcareConsultingAppts,
            yrsOfExp,
            expectedHoursPerWeek
        });
    }, [
        licensedCountry,
        npi,
        holdsActiveStateLicense,
        canWorkWithoutSupervision,
        licensedStates,
        stateLicensures,
        isMedicareProvider,
        wantsHealthcareConsultingAppts,
        yrsOfExp,
        expectedHoursPerWeek
    ]);

    const states = [
        'AK', 'AL', 'AR',
        'AZ', 'CA', 'CO',
        'CT', 'DE', 'DC',
        'FL', 'GA', 'HI',
        'IA', 'ID', 'IL',
        'IN', 'KS', 'KY',
        'LA', 'MA', 'MD',
        'ME', 'MI', 'MN',
        'MO', 'MS', 'MT',
        'NC', 'ND', 'NE',
        'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH',
        'OK', 'OR', 'PA',
        'RI', 'SC', 'SD',
        'TN', 'TX', 'UT',
        'VA', 'VT', 'WA',
        'WV', 'WI', 'WY',
        'GU', 'MP', 'PR',
        'VI'
    ];

    const licensureOptions = [
        'LCSW',
        'LCSW-C',
        'LICSW or LISW',
        'LMSW',
        'CSW-PIP',
        'LIMHP',
        'LMHC or similar',
        'LPC or similar',
        'LMFT or similar',
        'Psychologist',
        'Other',
    ];

    const onLicensedCountryChange = (event) => {
        setLicensedCountry(event.target.value);
    };

    const onNPIChange = (event) => {
        setNPI(event.target.value);
    };

    const onHoldsActiveStateLicenseChange = (event) => {
        setHoldsActiveStateLicense(event.target.checked);
    };

    const onCanWorkWithoutSupervisionChange = (event) => {
        setCanWorkWithoutSupervision(event.target.checked);
    };

    const onLicensedStatesChange = (event) => {
        const {
            target: { value },
        } = event;
        setLicensedStates(typeof value === 'string' ? value.split(',') : value);
    };

    const onLicensuresChange = (event) => {
        const {
            target: { value },
        } = event;
        setStateLicensures(typeof value === 'string' ? value.split(',') : value);
    };

    const onMedicareProviderChange = (event, val) => {
        setIsMedicareProvider(val);
    };

    const onWantsHealthcareConsultingApptsChange = (event) => {
        setWantsHealthcareConsultingAppts(event.target.checked);
    };

    const onYearsOfExperienceChange = (event) => {
        setYrsOfExp(event.target.value);
    };

    const onExpectedHoursPerWeekChange = (event) => {
        setExpectedHoursPerWeek(event.target.value);
    };

    return (
        <Grid container sx={{mt: 3}}>
            <Grid item xs={12}>
                <TextField
                    margin={'normal'}
                    required
                    fullWidth
                    label="Licensed Country"
                    autoFocus
                    value={licensedCountry}
                    onChange={onLicensedCountryChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography component="h1" variant="body2" align='left' sx={{m:1}}>
                    Please provide your 10-digit NPI (National Provider Identifier) number (for USA). If Licensed in another country, provide your national license number.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    label="NPI/License #"
                    autoFocus
                    value={npi}
                    onChange={onNPIChange}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Switch
                        checked={holdsActiveStateLicense}
                        onChange={onHoldsActiveStateLicenseChange}/>}
                    label={"I hold an active state license as a Psychologist or Mental Health Therapist"}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Switch
                        checked={canWorkWithoutSupervision}
                        onChange={onCanWorkWithoutSupervisionChange}/>}
                    label={"Under my license, I can work without supervision."}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography component="h1" variant="body2" align='left' sx={{m:1, mt: 2}}>
                    Select all states you're licensed to practice in.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={licensedStates}
                    onChange={onLicensedStatesChange}
                    input={<OutlinedInput
                        sx={{mb: 2}}
                        fullWidth
                        label="Licensed States"
                    />}
                >
                    {states.map((state) => (
                        <MenuItem
                            key={state}
                            value={state}
                        >
                            {state}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>

            <Grid item xs={12}>
                <Typography component="h1" variant="body2" align='left' sx={{m:1, mt: 2}}>
                    Select your state licensure below (check all that apply).
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={stateLicensures}
                    onChange={onLicensuresChange}
                    input={<OutlinedInput
                        sx={{mb: 2}}
                        fullWidth
                        label="Licensures"
                    />}
                >
                    {licensureOptions.map((licensure) => (
                        <MenuItem
                            key={licensure}
                            value={licensure}
                        >
                            {licensure}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    disablePortal
                    options={[
                        'Medicare',
                        'Medicaid',
                        'Both',
                        'None'
                    ]}
                    value={isMedicareProvider}
                    onChange={onMedicareProviderChange}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => {
                        return (
                            <TextField
                                margin={'normal'}
                                fullWidth
                                sx={{mt: 1}}
                                label={"Medicare or Medicaid Provider?"}
                                autoFocus
                                {...params}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography component="h1" variant="body2" align='left' sx={{m:1, mt: 2}}>
                    Are you willing to take healthcare consulting appointments? You’ll provide advice to individuals, businesses, financial institutions, hospitals, and other businesses about your specialty. You have an option to change this in the future.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Switch
                        checked={wantsHealthcareConsultingAppts}
                        onChange={onWantsHealthcareConsultingApptsChange}/>}
                    label={"I want healthcare consulting appointments."}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin={'normal'}
                    fullWidth
                    label="Years of Experience"
                    autoFocus
                    value={yrsOfExp}
                    onChange={onYearsOfExperienceChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin={'normal'}
                    fullWidth
                    label="Expected # of working hours on DXMD"
                    autoFocus
                    value={expectedHoursPerWeek}
                    onChange={onExpectedHoursPerWeekChange}
                />
            </Grid>
        </Grid>
    );
};

ProviderPsychologistLicenseInput.propTypes = {
    fields: PropTypes.object,
    onFieldsChange: PropTypes.func
};

export default ProviderPsychologistLicenseInput;