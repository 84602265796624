import * as React from 'react';
import * as PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid/Grid";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";

function ProviderWellnessSpecialistLicenseInput(props) {

    const [category, setCategory] = useState(null);
    const [certifications, setCertifications] = useState("");
    const [skillsAndExperiences, setSkillsAndExperiences] = useState("");
    const [yrsOfExp, setYrsOfExp] = useState("");
    const [expectedHoursPerWeek, setExpectedHoursPerWeek] = useState("");

    useEffect(() => {
        props.onFieldsChange({
            category,
            certifications,
            skillsAndExperiences,
            yrsOfExp,
            expectedHoursPerWeek
        });
    }, [
        category,
        certifications,
        skillsAndExperiences,
        yrsOfExp,
        expectedHoursPerWeek
    ]);

    const categoriesOptions = [
        'Nutrition Coaches',
        'Smoking Cessation Coaches',
        'Workout Instructors',
        'Weight Loss Specialists',
        'Sex Therapists',
        'Other Wellness Specialist'
    ];

    const onCertificationsChange = (event) => {
        setCertifications(event.target.value);
    };

    const onCategoryChange = (event, val) => {
        setCategory(val);
    };

    const onSkillsAndExperiencesChange = (event) => {
        setSkillsAndExperiences(event.target.value);
    };

    const onYearsOfExperienceChange = (event) => {
        setYrsOfExp(event.target.value);
    };

    const onExpectedHoursPerWeekChange = (event) => {
        setExpectedHoursPerWeek(event.target.value);
    };

    return (
        <Grid container sx={{mt: 3}}>
            <Grid item xs={12}>
                <Autocomplete
                    disablePortal
                    options={categoriesOptions}
                    value={category}
                    onChange={onCategoryChange}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => {
                        return (
                            <TextField
                                margin={'normal'}
                                fullWidth
                                sx={{mt: 1}}
                                label={"Select your Category"}
                                autoFocus
                                {...params}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography component="h1" variant="body2" align='left' sx={{m:1, mt: 2}}>
                    Do you hold any additional certifications or licenses? Please list them.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    label="Additional Certifications/Licenses"
                    autoFocus
                    value={certifications}
                    onChange={onCertificationsChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography component="h1" variant="body2" align='left' sx={{m:1, mt: 2}}>
                    List your skills and experiences here for customers to view.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    label="Skills and Experiences"
                    autoFocus
                    value={skillsAndExperiences}
                    onChange={onSkillsAndExperiencesChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin={'normal'}
                    fullWidth
                    label="Years of Experience"
                    autoFocus
                    value={yrsOfExp}
                    onChange={onYearsOfExperienceChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin={'normal'}
                    fullWidth
                    label="Expected # of working hours on DXMD"
                    autoFocus
                    value={expectedHoursPerWeek}
                    onChange={onExpectedHoursPerWeekChange}
                />
            </Grid>
        </Grid>
    );
}

ProviderWellnessSpecialistLicenseInput.propTypes = {
    fields: PropTypes.object,
    onFieldsChange: PropTypes.func
};

export default ProviderWellnessSpecialistLicenseInput;