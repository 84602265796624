import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import Sidebar from '../../pages/Sidebar'
import {
    Dashboard,
    CalendarViewMonth,
    Assessment,
    Money,
    Search,
    AdminPanelSettings,
    People,
    Logout
} from '@mui/icons-material'
import { store } from '../../redux/store';



function ProviderNavigationDrawer(props) {
    const history = useHistory();

    const [open, setOpen] = React.useState(true);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const pages = [
        {
            text: "Dashboard",
            icon: <Dashboard/>,
            onClick: () => props.onDrawerItemClick('Dashboard')
        },
        // {
        //     text: "Appointments",
        //     icon: <People/>,
        //     onClick: () => props.onDrawerItemClick('Appointments')
        // },
        {
            text: "Scheduling",
            icon: <CalendarViewMonth/>,
            onClick: () => props.onDrawerItemClick('Scheduling')
        },
        // {
        //     text: "Messages",
        //     icon: <Message/>,
        //     onClick: () => props.onDrawerItemClick('Messages')
        // },
        {
            text: "Patients",
            icon: <Assessment/>,
            onClick: () => props.onDrawerItemClick('Patients')
        },
        // {
        //     text: "Analytics/ML",
        //     icon: <Assessment/>,
        //     onClick: () => props.onDrawerItemClick('Analytics/ML')
        // },
        // {
        //     text: "Referrals",
        //     icon: <Summarize/>,
        //     onClick: () => props.onDrawerItemClick('Referrals')
        // },
        {
            text: "Billing",
            icon: <Money/>,
            onClick: () => props.onDrawerItemClick('Billing')
        },
        {
            text: "WebMD",
            icon: <Search/>,
            onClick: () => window.open("https://www.webmd.com/")
        },

    ];

    const options = [
        {
            text: "My Profile",
            icon: <Logout />,
            onClick: () => props.onDrawerItemClick('My Profile')
        },
        {
            text: "Settings",
            icon: <Logout />,
            onClick: () => props.onDrawerItemClick('Settings')
        },
        {
            text: "Logout",
            icon: <Logout />,
            onClick: () => props.onLogout()
        }
    ];

    if (store.getState()?.User?.username?.startsWith('dxmduser')) {
        pages.push({
            text: "Admin Billing",
            icon: <AdminPanelSettings />,
            onClick: () => props.onDrawerItemClick('Admin Billing')
        })
    } 
    return (
        <Drawer
            variant='permanent'
            anchor='left'
        >
            <Sidebar
                onLogout={props.onLogout} 
                onDrawerItemClick={props.onDrawerItemClick}
                selectedScreen={props.selectedScreen}
            />
        </Drawer>
    )

}

ProviderNavigationDrawer.propTypes = {
    onDrawerItemClick: PropTypes.func
};

export default ProviderNavigationDrawer;