import * as React from 'react';
import PatientData from "../components/deprecated/PatientData/PatientData";
import PatientList from "../components/deprecated/PatientData/PatientList/PatientList";

function ProviderPatients() {
    return (
        <PatientList/>
    );
};

export default ProviderPatients;


{/*<PatientData/>*/}