import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import PastMedicalRecords from "../components/Profile/PatientProfileComponents/PastMedicalRecords";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography/Typography";
import {
  getPatientUploads,
  uploadPatientUpload,
  createPatientCase,
  getPatientCase,
  updatePatientCaseByPatient,
  uploadWixUpload,
} from "../api/apigateway/apigateway";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";


function UploadPage(props) {
  const [files, setFiles] = useState([]);
  const [recordNotes, setRecordNotes] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(new Date());
  const [isLoading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");


  useEffect(() => {
    console.log('files', files);

  }, [files]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paramValue = urlParams.get('email');
    if (paramValue != undefined) {
      setEmail(paramValue);
    }
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();
    console.log(
      "handleDrop files",
      files.concat(Array.from(e.dataTransfer.files))
    );
    setFiles(files.concat(Array.from(e.dataTransfer.files)));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileChange = (e) => {
    console.log(
      "handleFileChange files",
      files.concat(Array.from(e.target.files))
    );
    setFiles(files.concat(Array.from(e.target.files)));
  };

  const handleRecordNotesChange = (e) => {
    setRecordNotes(e.target.value);
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (files.length == 0) {
      alert("Please upload files.");
      return;
    }
    if (!email) {
      alert("Invalid URL. Please contact DoubleCheckCare.");
      return;
    }
    setLoading(true);
    setLoadingMessage("Uploading...");
    uploadWixUpload(
      files,
      date,
      email,
      setLoading,
      setLoadingMessage,
    );
  };

  if (email == "") {
    return (
      <Paper sx={{ padding: 2, width: "650px" }}>
        <Typography variant="h3" gutterBottom align="center">
          Unauthorized user.
        </Typography>
      </Paper>
    )
  }

  return (
    <Paper sx={{ padding: 2, width: "650px" }}>
      <Typography variant="h3" gutterBottom align="center">
        Your Case
      </Typography>
      <Typography align="center" variant="subtitle1">You can drag and drop all files from your study disc.</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "750px",
          margin: "0 auto",
        }}
      >
        <TextField
          id="outlined-flexible"
          label="Email"
          maxRows={1}
          minRows={1}
          value={email}
          disabled
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mt: 3 }}
        />
        <TextField
          id="outlined-multiline-flexible"
          label="Case Notes"
          placeholder="What do you want to tell the doctor?"
          multiline
          maxRows={20}
          minRows={3}
          value={recordNotes}
          onChange={handleRecordNotesChange}
          sx={{ mt: 3 }}
        />
        <br />
        <div onDrop={handleDrop} onDragOver={handleDragOver}>
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            multiple
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
              border: "2px dashed grey",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => document.querySelector('input[type="file"]').click()}
          >
            <Typography variant="body1" color="text.secondary">
              Drag and drop or click to upload files
            </Typography>
          </Box>
          <br />
          {files.length > 0 && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file, index) => (
                    <TableRow key={file.name}>
                      <TableCell>{file.name}</TableCell>
                      <TableCell>
                        <Button>Delete</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <br />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date"
            value={date}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
            sx={{ mt: 3 }}
          />
        </LocalizationProvider>
        <hr />
        <Button
          disabled={!files}
          variant="contained"
          onClick={handleSubmit}
          sx={{ mt: 3 }}
        >
          {"Upload"}
        </Button>
        <br />
        {isLoading && <CircularProgress sx={{ marginLeft: "47%" }} />}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ fontWeight: 'bold', fontSize: 20 }}>{loadingMessage}</p>
        </div>
      </Box>
    </Paper>
  );
}

export default UploadPage;
