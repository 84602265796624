import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../api/authorization/Account';
import { store } from '../../redux/store';
import { setSession } from '../../redux/actions';

function Status(props) {
    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        getSession(true)
            .then((session) => {
                console.log("Status: ", session);
                props.setLoggedInStatus(true);
                store.dispatch(setSession(session));
            }).catch();
    }, []);
    return (
        <div/>
    );
};

Status.propTypes = {
    setLoggedInStatus: PropTypes.func.isRequired
};

export default Status;