import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Account } from "./api/authorization/Account";
import Status from "./components/LoginAndRegister/Status";
import { Menu, Layout } from "antd";
import "antd/dist/antd.css";
import "./style.css";
import Text from "antd/lib/typography/Text";
import { Button } from "@mui/material";
import PatientPortal from "./PatientPortal";
import ProviderPortal from "./ProviderPortal";
import Login from "./components/LoginAndRegister/Login";
import RecoverPassword from "./components/LoginAndRegister/RecoverPassword";
import RecoverAccount from "./components/LoginAndRegister/RecoverAccount";
import Register from "./components/LoginAndRegister/Register";
import PatientRegistration from "./components/LoginAndRegister/PatientRegistration";
import ProviderRegistration from "./components/LoginAndRegister/ProviderRegistration";
import UploadPage from "./pages/UploadPage";

const { Footer } = Layout;

const styles = {
  content: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#041836",
    padding: "10px",
  },
  header: {
    position: "fixed",
    zIndex: 1,
    width: "100%",
    background: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "myriad, sans-serif",
    borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
    padding: "0 10px",
    boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
  },
  headerRight: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "600",
  },
};

const App = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState(window.location.host.includes('upload.') ? '/secureupload' : '/login');
  const [redirect, setRedirect] = useState(window.location.pathname.includes('/secureupload') ? false : true);

  useEffect(() => {
    console.log("loggedin: ", isLoggedIn);
    if (isLoggedIn) {
      setRedirectRoute('/patient');
    } else if (window.location.pathname.includes('/secureupload')) {
      setRedirect(false);
    } else {
      setRedirectRoute('/login');
    }
  }, [isLoggedIn]);

  return (
    <Account>
      <Layout style={{ height: "100vh", overflow: "auto" }}>
        <Status setLoggedInStatus={(bool) => { setIsLoggedIn(bool) }} />
        <Router>
          <div style={styles.content}>
            <Switch>
              <Route path="/login" exact component={Login} />
              <Route path="/register" exact component={Register} />
              <Route path="/providerprofile" exact component={ProviderRegistration} />
              <Route path='/patientprofile' exact component={PatientRegistration} />
              <Route path='/patient' exact component={PatientPortal} />
              <Route path='/provider' exact component={ProviderPortal} />
              <Route path='/secureupload' exact component={UploadPage} />
              <Route path="/recoverpassword" exact component={RecoverPassword} />
              <Route path="/recoveraccount" exact component={RecoverAccount} />
            </Switch>
            {redirect && <Redirect to={redirectRoute} />}
          </div>
        </Router>
        <Footer style={{ textAlign: "center" }}>
          <Text style={{ display: "block" }}>
            🔐 Welcome to DoubleCheck Care's Secure Patient Portal.
          </Text>
          <Text style={{ display: "inline-block", marginRight: "20px" }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.doublecheckcare.com/terms-and-conditions"
            >
              Terms and Conditions
            </a>
          </Text>
          <Text style={{ display: "inline-block" }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.doublecheckcare.com/privacypolicy"
            >
              Privacy Policy
            </a>
          </Text>

          <Text style={{ display: "block" }}>
            © 2023 DoubleCheck Care. All rights reserved.
          </Text>
        </Footer>

      </Layout>
    </Account>
  );
};

export default App;
