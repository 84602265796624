import * as React from 'react';
import * as PropTypes from 'prop-types';
import Grid from "@mui/material/Grid/Grid";
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Button from "@mui/material/Button/Button";


function AllergyInput(props) {
    //const [type, setType] = useState("");
    //const [allergy, setAllergy] = useState("");
    //const [reaction, setReaction] = useState("");

    const onTypeChange = (event, val) => {
        //setType(event.target.value);
        props.onChange({
            type: val,
            allergy: props.allergies[props.index].allergy,
            reaction: props.allergies[props.index].reaction,
        })
    };

    const onAllergyChange = (event) => {
        //setAllergy(event.target.value);
        props.onChange({
            type: props.allergies[props.index].type,
            allergy: event.target.value,
            reaction: props.allergies[props.index].reaction
        })
    };

    const onReactionChange = (event) => {
        //setReaction(event.target.value);
        props.onChange({
            type: props.allergies[props.index].type,
            allergy: props.allergies[props.index].allergy,
            reaction: event.target.value
        })
    };

    const onItemDelete = () => {
        props.onDelete()
    };

    return(
        <Grid container>
            <Grid item xs={12} sm={3}>
                <Autocomplete
                    disablePortal
                    id="genderDropdown"
                    options={[
                        'Drug',
                        'Food',
                        'Environment'
                    ]}
                    value={props.allergies[props.index].type}
                    onChange={onTypeChange}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => {
                        return (
                            <TextField
                                fullWidth
                                margin={"normal"}
                                label={"Type"}
                                autoFocus
                                {...params}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4.49}>
                <TextField
                    margin={"normal"}
                    fullWidth
                    label="Allergy"
                    value={props.allergies[props.index].allergy}
                    onChange={onAllergyChange}
                />
            </Grid>
            <Grid item xs={12} sm={4.49}>
                <TextField
                    margin={"normal"}
                    fullWidth
                    label="Reaction"
                    value={props.allergies[props.index].reaction}
                    onChange={onReactionChange}
                />
            </Grid>
            <Grid item xs={12} sm={0.02}>
                <Button
                    variant={"contained"}
                    sx={{mt: 3, mb: 1}}
                    onClick={onItemDelete}
                    color={'secondary'}
                >
                    X
                </Button>
            </Grid>
        </Grid>
    );
}

AllergyInput.propTypes = {
    allergies: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
};

export default AllergyInput;