import React from 'react';
import * as PropTypes from 'prop-types';
import {
    Paper,
    Box,
    Typography,
    Divider,
    Avatar,
    IconButton,
    Button
} from '@mui/material';

function NextPatient(props) {
    return (
        <Box sx={{m: 2}}>
            <Paper sx={{padding: 3}}>
                <Divider sx={{mb: 1}}/>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <Typography sx={{justifyContent: 'center'}} variant={'h6'}> Next Patient </Typography>
                </Box>
                <Divider sx={{mt: 1}}/>
                <Box sx={{mt: 2, display: 'flex', flexDirection: 'row'}}>
                    <Avatar sx={{width:35, height:35}} alt={props.name} src={props.profilePhoto}/>
                    <Typography sx={{ml: 1.6, mt: 0.6}}>{props.name}</Typography>
                </Box>
                <Box sx={{mt: 2, mb: 2, ml: 1, mr: 1}}>
                    <Typography variant={'subtitle2'}> Appointment: </Typography>
                    <Typography variant={'body2'}> {props.title} </Typography>
                </Box>
                <Button
                    fullWidth
                    variant={'outlined'}
                    onClick={props.onViewProfile}
                >
                    View Profile
                </Button>
                <Button
                    fullWidth
                    onClick={props.onJoin}
                    variant={'contained'}
                >
                    Join Now
                </Button>
            </Paper>
        </Box>
    );
}

NextPatient.propTypes = {
    name: PropTypes.string.isRequired,
    profilePhoto: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onViewProfile: PropTypes.func.isRequired,
    onJoin: PropTypes.func.isRequired
};

export default NextPatient;