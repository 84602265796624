import { CognitoUserPool } from "amazon-cognito-identity-js";

// const patientPool = {
//     userPoolId: "us-west-1_XjbLpIqsf",
//     userPoolWebClientId: "31vmqutlcq5kli2m90mr04bpuj"
// };

export const patientUserPool = new CognitoUserPool({
    UserPoolId: "us-west-1_xBEW6bpi8",
    ClientId: "2enbkn161m0l5lltsnekp3tbl"
});

export const providerUserPool = new CognitoUserPool({
    UserPoolId: "us-west-1_pkefHttUa",
    ClientId: "7ajm806kehne55h49m5c651p7v"
});