import React from 'react';
import * as PropTypes from 'prop-types';
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import Table from "@mui/material/Table/Table";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";

function sortByDate(arr) {
    if (arr == null || arr == undefined) {
        return []
    }
    // Use the Array.prototype.sort() method with a custom comparator function
    arr.sort((a, b) => {
      const dateA = a.date;
      const dateB = b.date;
  
      if (dateA === undefined && dateB === undefined) {
        return 0; // Both dates are undefined, consider them equal
      } else if (dateA === undefined) {
        return 1; // 'a' has an undefined date, place 'b' before 'a'
      } else if (dateB === undefined) {
        return -1; // 'b' has an undefined date, place 'a' before 'b'
      }
  
      // Compare the dates
      if (dateA < dateB) {
        return -1; // Return a negative value to place 'a' before 'b'
      } else if (dateA > dateB) {
        return 1; // Return a positive value to place 'a' after 'b'
      } else {
        return 0; // Return 0 to indicate that 'a' and 'b' are equal in terms of dates
      }
    });
  
    return arr;
  }
function PastMedicalRecords(props) {
    const records = sortByDate(props.records).reverse() || [];
    // Create an empty object to store the study objects
    return (
        <Box sx={{ m: 2 }}>
            <Typography sx={{ m: 1 }} variant={'h5'}> Doctor's Comments </Typography>

            {/* {props.caseInfo && <><Typography sx={{ m: 1 }} variant={'subtitle1'}> You provided the following case notes: </Typography><Typography sx={{ m: 1 }} variant={'body1'}>{props.caseInfo.caseNotes}</Typography></>}
            <hr /> */}
            {props?.caseInfo?.doctorCaseNotes && <><Typography sx={{ m: 1 }} fontWeight={'bold'} variant={'h6'}>{props.caseInfo?.doctorCaseNotes || "No comments yet."}</Typography></>}
            <hr />
            <Typography sx={{ m: 1 }} variant={"h6"}>
                {" "}
                Case Uploads{" "}
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Date
                            </TableCell>
                            <TableCell>
                                Images
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map((val, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    {new Date(val.date).toDateString()}
                                </TableCell>
                                <TableCell>
                                    <><a href={val.link} target="_blank" rel="noreferrer noopener">
                                        {val?.fileName || val.s3key}
                                    </a><br /></>
                                </TableCell>
                            </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

PastMedicalRecords.propTypes = {
    records: PropTypes.array
};

export default PastMedicalRecords;