import * as React from 'react';
import {
    Box,
} from '@mui/material';

export default function ComingSoon(props) {
    return (
        <Box sx={{ alignText: 'center', paddingTop: 25, paddingRight: props?.provider ? 30 : 0 }}>
            <h1>Coming Soon</h1>
        </Box>
    )
}