import {
    IconButton,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import * as PropTypes from "prop-types";
import React from "react";

function CustomTableToolbar(props) {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
            <Typography
                sx={{ flex: '1', marginLeft: 1 }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                {props.title}
            </Typography>
            <TextField
                value={props.query}
                onChange={props.onQueryChange}
                variant={'outlined'}
                label={'Search'}
                sx={{
                    m: 2
                }}
            />
            <Tooltip title="Filter list">
                <IconButton>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
};

CustomTableToolbar.propTypes = {
    title: PropTypes.string,
    query: PropTypes.string.isRequired,
    onQueryChange: PropTypes.func.isRequired
};

export default CustomTableToolbar;