import { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import PaginatedTable from "../../components/TableComponents/PaginatedTable";
import { TableCell, TableRow, Button } from "@mui/material";
import * as PropTypes from "prop-types";

import { getAllAppointments, getDoctorUpcoming } from '../../api/apigateway/apigateway';
import { solanaNET } from '../../constants/Constants';

const headCells = [
    {
        id: 'name',
        numeric: false,
        label: 'Patient ID',
    },
    {
        id: 'type',
        numeric: false,
        label: 'Appointment Name',
    },
    {
        id: 'date',
        numeric: true,
        label: 'Date'
    },
    {
        id: 'time',
        numeric: true,
        label: 'Time',
    },
    {
        id: 'price',
        numeric: true,
        label: 'Price',
    },
    {
        id: 'payment',
        numeric: false,
        label: 'Payment Status',
    },
    {
        id: 'wallet',
        numeric: false,
        label: 'Pay'
    }
];

// const getProvider = () => {
//     if ("solana" in window) {
//         const anyWindow = window;
//         const provider = anyWindow.solana;
//         if (provider.isPhantom) {
//             return provider;
//         }
//     }
//     return null;
// };

const exampleRowUnfulfilled = {
    patientId: '183',
    title: 'Consultation',
    startDate: new Date().toString(),
    price: 0.01,
    payment: 'Unfulfilled',
}

const exampleRowUnfulfilledF = {
    patientId: '183',
    title: 'Consultation',
    startDate: new Date().toString(),
    price: 0.01,
    payment: 'Fulfilled',
}

const exampleRowFulfilled = {
    patientId: '187',
    title: 'Consultation',
    startDate: new Date().toString(),
    price: 0.01,
    payment: 'Fulfilled',
}

function AdminBilling() {
    const [rows, setRows] = useState([exampleRowUnfulfilled, exampleRowFulfilled]);
    // const provider = getProvider();
    const [logs, setLogs] = useState([]);
    // const connection = new Connection(NETWORK);
    const [walletAddress, setWalletAddress] = useState(null);
    const [addy, setAddy] = useState(null);
    const [balance, setBalance] = useState(0);
    const addLog = useCallback(
        (log) => setLogs((logs) => [...logs, "> " + log]),
        []
    );


    // const connectWallet = async () => {
    //     const { solana } = window;

    //     if (solana) {
    //         const response = await solana.connect();
    //         setAddy(response.publicKey);
    //         setWalletAddress(response.publicKey.toString());
    //         if (response.publicKey != null) {
    //             connection.getBalance(response.publicKey).then((res) => { setBalance(res / 1000000000) });
    //         }
    //     }
    // };

    const renderNotConnectedContainer = () => (
        <button
            className="cta-button connect-wallet-button"
            // onClick={connectWallet}
            style={{ textAlign: 'center', marginLeft: '207px' }}
        >
            Connect to Wallet
        </button>
    );

    const createTransferTransaction = async (solanaPrice) => {
        if (!provider.publicKey) return;
        const dxmdWallet = new PublicKey("6MY9bicoRqmuxPWGxo3sPTsuVTq3Pvj1gkzfuZg6Xv1H");
        const escrowWallet = new PublicKey("2shBLPKNBfnTXZ6ai4YQLMAFyMgdZGQqbDcFCTvFkaFs");
        const treasuryWallet = new PublicKey("5RXmpYzMiaUEqbQg7dfSJQNYm15qcQcLotuGjuNehnEG");
        let transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: provider.publicKey,
                toPubkey: treasuryWallet,
                // lamports: LAMPORTS_PER_SOL * solanaPrice,
                lamports: 1000,
            })
        );
        transaction.feePayer = provider.publicKey;
        addLog("Getting recent blockhash");
        const anyTransaction = transaction;
        anyTransaction.recentBlockhash = (
            await connection.getRecentBlockhash()
        ).blockhash;
        return transaction;
    };
    const sendTransaction = async (solanaPrice) => {
        try {
            const transaction = await createTransferTransaction(solanaPrice);
            if (!transaction) return;
            let signed = await provider.signTransaction(transaction);
            addLog("Got signature, submitting transaction");
            let signature = await connection.sendRawTransaction(signed.serialize());
            addLog("Submitted transaction " + signature + ", awaiting confirmation");
            let confirmation = await connection.confirmTransaction(signature);
            console.log('confirmation', confirmation);
            addLog("Transaction " + signature + " confirmed");
            setRows([exampleRowUnfulfilledF, exampleRowFulfilled])
            return true;
        } catch (err) {
            console.warn(err);
            addLog("[error] sendTransaction: " + JSON.stringify(err));
        }
    };

    const renderConnectedContainer = () => (
        <>
            <h4 style={{ textAlign: 'center' }}>
                Wallet Balance: {balance.toFixed(3)} SOL
            </h4>
            <p style={{ textAlign: 'center' }}>{provider.publicKey.toString() == "2shBLPKNBfnTXZ6ai4YQLMAFyMgdZGQqbDcFCTvFkaFs" ? "You are connected to the DXMD Care Wallet." : "You are not connected to the DXMD Care Wallet."}</p>
            <br />
            {/* <h5 style={{ textAlign: 'center' }}>
                Total unfulfilled payments: 0.01 SOL
            </h5> */}
            <br />
        </>
    )



    useEffect(() => {
        const onLoad = async () => {
            await checkIfWalletIsConnected();
        };
        window.addEventListener("load", onLoad);
        return () => window.removeEventListener("load", onLoad);
    }, []);

    useEffect(() => {
        // connectWallet();
        getAllAppointments(setRows);
    }, []);

    function CustomTableRow(props) {
        const { row } = props;

        return (
            <TableRow
                hover
                onClick={() => { }}
                key={row.patientId}
            >
                <TableCell padding={'normal'} align={'left'}>
                    {row.patientId}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.title}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.startDate.substring(0, 10)}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.startDate.substring(12, 16)}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.price || "0.01"}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.payment || 'Fulfilled'}
                </TableCell>
                <TableCell padding={'normal'} align={'left'}>
                    {row.payment == 'Unfulfilled' ? <Button variant="outlined" onClick={() => {
                        sendTransaction(row.price).then(res => getAllAppointments(setRows))
                    }}>Pay</Button> : "---"}
                </TableCell>
            </TableRow>
        );
    }

    return (
        <Box>
            {walletAddress ? renderConnectedContainer() : renderNotConnectedContainer()}
            <PaginatedTable
                tableName={'Appointments'}
                headCells={headCells}
                rows={rows}
                CustomTableRow={CustomTableRow}
            />
        </Box>

    );
};

export default AdminBilling;