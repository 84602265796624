import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

function createData(item, value) {
    return {item, value};
}

const rowsBasic = [
    createData('First Name', "Sourish"),
    createData('Last Name', "Agarwal"),
    createData('Preferred Name', ""),
    createData('Date of Birth', "08/15/1969"),
    createData('Address', "Berkeley, CA"),
    createData('Country', "USA"),
    createData('Zip Code', "94704"),
    createData('Email', "sourish@dxmd.org"),
    createData('Preferred Language', "English"),
    createData('Gender', "Male"),
    createData('Preferred Gender Pronouns', "He/Him/His"),
];

const rowsInsurance = [
    createData('Insurance Provider', "United Healthcare"),
    createData('Member ID', "123456789"),
    createData('Group ID', "0123456"),
];

const rowsMedicalHistory = [
    createData('Height', "7'9\""),
    createData('Weight', "95lbs"),
    createData('Allergies Exist', "No"),
    createData('Allergy Type', ""),
    createData('Allergy', ""),
    createData('Reaction', ""),
    createData('Smokes?', "No"),
    createData('If yes, how often', ""),
    createData('If yes, how long', ""),
    createData('Drinks Alcohol?', "yes"),
    createData('If yes, how often', "1 drink per week"),
    createData('Medications?', "yes"),
    createData('Medication:', "xyz"),
    createData('Medical History:', "Diabetes"),
    createData('Family History:', "Heart Attack, Stroke"),
];

function GeneralPatientData() {
    return (
        <Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="basic">
                    <TableHead sx={{align: 'center'}}>
                        <TableRow>
                            <TableCell align="right">Basic Information</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsBasic.map((row) => (
                            <TableRow
                                key={row.item}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{row.item}</TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider sx={{margin: 3}}/>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="basic">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">Insurance</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsInsurance.map((row) => (
                            <TableRow
                                key={row.item}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{row.item}</TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider sx={{margin: 3}}/>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="basic">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">Medical History</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsMedicalHistory.map((row) => (
                            <TableRow
                                key={row.item}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{row.item}</TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default GeneralPatientData;
