import * as React from 'react';

import Box from '@mui/material/Box';
import ChangePassword from "../components/LoginAndRegister/ChangePassword";
import ChangeEmail from "../components/LoginAndRegister/ChangeEmail";

function ProviderSettings() {
    return (
        <Box>
            <ChangePassword isPatient={true}/>
            <ChangeEmail isPatient={true}/>
        </Box>
    );
};

export default ProviderSettings;