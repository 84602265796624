import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import SelectBox from '../components/deprecated/Dashboard/SelectBox';
import calendarIcon from "../assets/icons/Calendar.svg";
import { SelectOptionsHome } from '../components/deprecated/Dashboard/data/SlectBoxData';
import "./RightSidebar.css";
import RightSidebar from './RightSidebar';
import "../styles/ProviderDashboard.css";
import { getDoctorUpcomingToday, getProviderDashboard, getPicture, getPatientUploadsByDoctor, getPatientCasesByDoctor } from '../api/apigateway/apigateway';
import { store } from "../redux/store";
import PastMedicalRecords from '../components/Profile/PatientProfileComponents/PastMedicalRecords'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
  } from '@mui/material';

function ProviderDashboard() {
    const [providerData, setProviderData] = useState({});
    const [rows, setRows] = useState([]);
    const [patientInfo, setPatientInfo] = useState([]);
    const [profilePicture, setProfilePicture] = useState('');
    const [allPatientRecords, setAllPatientRecords] = useState([]);
    const [data, setData] = useState([]);
    const [cases, setCases] = useState([]);
    const doctorId = store.getState().userID;

    useEffect(() => {
        getPatientCasesByDoctor(doctorId, setCases);
        getPatientUploadsByDoctor(doctorId, setData);
    }, []);

    useEffect(() => {
        getProviderDashboard(store.getState().userID, setProviderData);
        getDoctorUpcomingToday(
            store.getState().userID,
            (rows) => setRows(rows),
            (patientInfo) => setPatientInfo(patientInfo)
        );
        getPicture(store.getState().userID, (pic) => setProfilePicture(pic));
        getPatientUploadsByDoctor(store.getState().userID, setAllPatientRecords);
    }, []);



    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <section className="home_wrap">
                <Box className="c_container">
                    <Box
                        className="h_head"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <h2>
                            <b>Hello</b>{providerData["provider-info"] ? ", Dr. " +
                                providerData["provider-info"].firstName + " " + providerData["provider-info"].lastName : null} 👋
                        </h2>
                        <Box>
                            <img src={calendarIcon} alt="..." />
                            <span>{new Date().toDateString()}</span>
                        </Box>
                    </Box>
                    <Box className="h_stats">
                        <h2>
                            <b>Current Patient Cases</b>
                        </h2>
                        <SelectBox
                            color="#83879b"
                            border="0"
                            width="150px"
                            fontWeight="300"
                            backgroundColor="#fff"
                            BorderRadius="0"
                            defaultValue={3}
                            data={SelectOptionsHome}
                        />
                    </Box>
                    <Box className="stats_wrap">
                    <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Case Notes</TableCell>
                                        <TableCell>Upload Date</TableCell>
                                        <TableCell>Doctor Feedback</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cases.map(row => (
                                        <TableRow key={row.caseID}>
                                            <TableCell>{row.caseNotes}</TableCell>
                                            <TableCell>{new Date(row.created_date).toDateString()}</TableCell>
                                            <TableCell>{row?.doctorNotes || "None provided yet."}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                </Box>
            </section>
            <Drawer sx={{ width: 300 }} variant="permanent" anchor="right">
                <RightSidebar
                    solanaBalance={0}
                    name={providerData["provider-info"] ?
                        providerData["provider-info"].firstName + " " + providerData["provider-info"].lastName : null}
                    profilePhoto={profilePicture}
                />
            </Drawer>
        </Box>
    )
}

export default ProviderDashboard;