import React from "react";
// MATERIAL UI
import Box from "@mui/material/Box";
const UserBox = (props) => {
  return (
    <>
      <Box className={`us_box ${props.classes}`}>
        <Box className="us_head">
          <img src={props.img} alt="" />
          <span>{props.text}</span>
        </Box>
        <p>
          <sup>{props.currency} </sup>
          <b>{props.value}</b>
        </p>
      </Box>
    </>
  );
};

export default UserBox;
