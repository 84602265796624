import React from "react";
import { useEffect, useState} from "react";
import * as PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import BookProvider from "../../../components/deprecated/Scheduling/BookProvider";
import { TableContainer } from "@mui/material";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { getAvailableAppointments, bookAppointment, getAllAppointments, getAllUnbooked } from "../../../api/apigateway/apigateway";
import moment from 'moment';
import { store } from '../../../redux/store';

// const NETWORK = clusterApiUrl(solanaNET);

// const getProvider = () => {
//     if ("solana" in window) {
//         const anyWindow = window;
//         const provider = anyWindow.solana;
//         if (provider.isPhantom) {
//             return provider;
//         }
//     }
//     return null;
// };


const availableProviders = [
    {
        name: 'Sourish Agarwal',
        profilePhoto: "https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        specialty: 'Cardiologist'
    },
    {
        name: 'Priyam Mohanty',
        profilePhoto: "https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        specialty: 'Dermatologist'
    },
    {
        name: 'Rohan Hajela',
        profilePhoto: "https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        specialty: 'Urologist'
    },
    {
        name: 'Siddharth Bansal',
        profilePhoto: "https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        specialty: 'Primary Care'
    },
    {
        name: 'Andy Zhao',
        profilePhoto: "https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        specialty: 'Intensive Care'
    }
];

function ScheduleAppointment(props) {
    // const provider = getProvider();
    // const connection = new Connection(NETWORK);
    const [value, setValue] = React.useState(new Date());
    const [showBook, setShowBook] = React.useState(true);
    const [timeSwitch, setSwitch] = React.useState(true);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [selected, setSelected] = React.useState(-1);
    const [wallet, setWallet] = React.useState(null);
    const [patientId] = useState(store.getState().userID);
    const [dateState, setDateState] = useState(new Date());
    const [available, setAvailable] = useState([]);
    const [providerInfo, setProviderInfo] = useState([]);
    const [selectedAppt, setSelectedAppt] = React.useState(null);
    const [displayAll, setDisplayAll] = useState(false);
    const [availableAll, setAvailableAll] = useState([]);
    const [providerInfoAll, setProviderInfoAll] = useState([]);

    useEffect(() => {
        getAvailableAppointments(moment().format("MM/DD/YYYY"), setAvailable, setProviderInfo);
      }, []);

    const onChange = (e) => {
        setDateState(e);
        setDisplayAll(false);
        getAvailableAppointments(moment(e).format("MM/DD/YYYY"), setAvailable, setProviderInfo);  
    };

    function showAllAppointments() {
        setDisplayAll(true);
        getAllUnbooked(setAvailable, setProviderInfo); 
        console.log("AVAILABLE", availableAll);
    }

    // const connectWallet = async () => {
    //     const { solana } = window;

    //     if (solana) {
    //         const response = await solana.connect();
    //         console.log('did something Connected with Public Key:', response.publicKey.toString());
    //         console.log('did something got response:', response);
    //         setWallet(response.publicKey);
    //     }
    // };

    const createTransferTransaction = async (price, careProviderPubKey) => {
        if (!provider.publicKey) return false;
        const priceNum = Number(price);
        if (isNaN(priceNum)) {
            alert("Invalid price. Appointment cannot be booked.");
            return false;
        }
        const priceLamports = priceNum * LAMPORTS_PER_SOL;
        const escrowWallet = new PublicKey("2shBLPKNBfnTXZ6ai4YQLMAFyMgdZGQqbDcFCTvFkaFs");
        const providerWallet = new PublicKey(careProviderPubKey);
        const treasuryWallet = new PublicKey("5RXmpYzMiaUEqbQg7dfSJQNYm15qcQcLotuGjuNehnEG");
        let transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: provider.publicKey,
                toPubkey: providerWallet,
                lamports: priceLamports * 0.9,
            })
        ).add(
            SystemProgram.transfer({
                fromPubkey: provider.publicKey,
                toPubkey: treasuryWallet,
                lamports: priceLamports * 0.1,
            })
        );
        transaction.feePayer = provider.publicKey;
        const anyTransaction = transaction;
        anyTransaction.recentBlockhash = (
            await connection.getRecentBlockhash()
        ).blockhash;
        return transaction;
    };

    const sendTransaction = async (price, providerWallet) => {
        try {
            const transaction = await createTransferTransaction(price, providerWallet);
            if (!transaction) return Promise.reject(new Error("Transaction failed."));
            let signed = await provider.signTransaction(transaction);
            console.log("Got signature, submitting transaction");
            let signature = await connection.sendRawTransaction(signed.serialize());
            console.log("Submitted transaction " + signature + ", awaiting confirmation");
            alert("Submitted transaction. Awaiting confirmation from Solana...");
            await connection.confirmTransaction(signature);
            console.log("Transaction " + signature + " confirmed");
            return Promise.resolve("Transaction confirmed.");
        } catch (err) {
            console.warn(err);
            console.log("[error] sendTransaction: " + JSON.stringify(err));
            return Promise.reject("Transaction was rejected");
        }
    };

    React.useEffect(() => {
        getAvailableAppointments(moment('02-21-2022').format("MM/DD/YYYY"), setAvailable, setProviderInfo);
        // connectWallet();
      }, []);

    /*const setSelectedAppt = () => {

    };*/

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>

            {showBook && (<Stack spacing={3} direction="column"><LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                    orientation="portrait"
                    openTo="day"
                    value={dateState}
                    // onChange={(newValue) => {
                    //     onChange(newValue);
                    //     setSwitch(!timeSwitch)
                    //     setSelectedAppt(null);
                    // }}
                    onChange={onChange}
                    minDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
                <Stack spacing={2} direction="column" >
                    {/* <Button variant="outlined" onClick={() => setSelectedAppt(1)}>{timeSwitch ? "2:30 PM" : "11:00 AM"}</Button>
                    <Button variant="outlined" onClick={() => setSelectedAppt(1)}>{timeSwitch ? "3:30 PM" : "1:00 PM"}</Button>
                    <Button variant="outlined" onClick={() => setSelectedAppt(1)}>{timeSwitch ? "5:30 PM" : "3:15 PM"}</Button> */}
                    {available.map(slot => {
                        return (<Button onClick={() => {
                            setSelectedAppt(slot.appointmentId);
                        }} variant="primary">{slot.startTime}</Button>)
                    })}
                </Stack>
                {/* <h3 style={{textAlign: "center"}}>Price: 0.25 SOL</h3> */}
                {<Button variant="contained" onClick={showAllAppointments}>Show All Appointments</Button>}
            </Stack>)}

            <TableContainer sx={{ minWidth: 500, maxHeight: 1000 }}>
                <Table>
                    <TableBody>
                        {!displayAll && available.map(slot => (
                            <TableRow>
                                <TableCell>
                                    <BookProvider
                                        // x = {console.log("SLOT", providerInfo)}
                                        name={providerInfo[slot.doctorId] ? providerInfo[slot.doctorId].firstName + " " + providerInfo[slot.doctorId].lastName: "NONE"}
                                        slot={slot}
                                        specialty={"Specialty: " + (providerInfo[slot.doctorId] ? providerInfo[slot.doctorId].specialty: "NONE")}
                                        // time ={"Time: " + slot.startDate.substring(11,16)}
                                        time ={"Time: " + new Date(slot.startDate).toLocaleString().split(",")[1]}
                                        showDate = {false}
                                        price={slot.price}
                                        onBookClick={() => {
                                            if (wallet == null) {
                                                alert("You must connect a Phantom wallet in order to proceed.")
                                                // connectWallet();
                                            } else {
                                                sendTransaction(slot.price, slot.wallet).then(res => {
                                                    bookAppointment(patientId, slot.doctorId, slot.appointmentId);
                                                    alert("Booked");
                                                    getAvailableAppointments(moment(dateState).format("MM/DD/YYYY"), setAvailable, setProviderInfo);
                                                }).catch(err => {
                                                    alert("Error booking appointment.");
                                                })
                                            }
                                        }}
                                        // Rohan - this is used to book without solana transactions
                                        // onBookClick={() => {
                                        //     bookAppointment(patientId, slot.doctorId, slot.appointmentId);
                                        //     alert("Booked");
                                        //     getAvailableAppointments(moment(dateState).format("MM/DD/YYYY"), setAvailable, setProviderInfo);
                                        // }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        {displayAll && available.map(slot => (
                            <TableRow>
                                <TableCell>
                                    <BookProvider
                                        // x = {console.log("SLOT", providerInfo)}
                                        name={providerInfo[slot.doctorId] ? providerInfo[slot.doctorId].firstName + " " + providerInfo[slot.doctorId].lastName: "NONE"}
                                        slot={slot}
                                        specialty={"Specialty: " + (providerInfo[slot.doctorId] ? providerInfo[slot.doctorId].specialty: "NONE")}
                                        // time ={"Time: " + slot.startDate.substring(11,16)}
                                        time ={"Time: " + new Date(slot.startDate).toLocaleString().split(",")[1]}
                                        showDate = {true}
                                        date = {"Date: " + new Date(slot.startDate).toLocaleString().split(",")[0]}
                                        price={slot.price}
                                        onBookClick={() => {
                                            if (wallet == null) {
                                                alert("You must connect a Phantom wallet in order to proceed.")
                                                connectWallet();
                                            } else {
                                                sendTransaction(slot.price, slot.wallet).then(res => {
                                                    bookAppointment(patientId, slot.doctorId, slot.appointmentId);
                                                    alert("Booked");
                                                    getAvailableAppointments(moment(dateState).format("MM/DD/YYYY"), setAvailable, setProviderInfo);
                                                }).catch(err => {
                                                    alert("Error booking appointment.");
                                                })
                                            }
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

ScheduleAppointment.propTypes = {

};

export default ScheduleAppointment;
