import React from 'react';
import * as PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';

function BookProvider(props) {
    return (
        <Paper sx={{display: 'flex', flexDirection: 'row', flexGrow: '1', backgroundColor: props.selected ? '#d3d3d3' : 'white'}}>
            <Box sx={{display: 'flex', flexDirection:'column', mt: 5.5, mb: 3, ml: 3}}>
                {props.profilePhoto ?
                    <Avatar sx={{width:100, height:100}} alt={props.name} src={props.profilePhoto}/> :
                    <Avatar sx={{width:100, height:100}} alt={props.name}>{props.name.charAt(0)}</Avatar>
                }
            </Box>
            <Box sx={{display: 'flex', flexDirection:'column', m: 4, mt: 6.5}}>
                <Typography variant={'h6'}>{"Dr. " + props.name}</Typography>
                <Typography>Type: {props.slot.title}</Typography>
                {/* <Typography variant={'h6'}>{props.price}</Typography> */}
                <Typography>{props.specialty}</Typography>
                {props.showDate && <Typography>{props.date}</Typography>}
                <Typography>{props.time}</Typography>
                {/* <Rating
                    value={3}
                    readOnly
                /> */}
            </Box>
            <Box sx={{display: 'flex', flexDirection:'column', mr: 3, mt: 6, mb: 3}}>
                <Button
                    fullWidth
                    onClick={props.onSeeAvailabilityClick}
                    variant={'outlined'}
                    sx={{m: 0.5}}
                >
                    View Profile
                </Button>
                <Button
                    fullWidth
                    onClick={props.onBookClick}
                    variant={'contained'}
                    sx={{m: 0.5}}
                >
                    Book {props.price ? "(" + props.price + " SOL)" : null}
                </Button>
            </Box>
        </Paper>
    );
}

BookProvider.propTypes = {
    profilePhoto: PropTypes.string,
    name: PropTypes.string.isRequired,
    specialty: PropTypes.string.isRequired,
    onBookClick: PropTypes.func.isRequired,
    onSeeAvailabilityClick: PropTypes.func
};

export default BookProvider;