import * as React from 'react';
import * as PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid/Grid";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

function ProviderHealthcareConsultantLicenseInput(props) {

    const [specialty, setSpecialty] = useState(null);
    const [areasOfExpertise, setAreasOfExpertise] = useState([]);
    const [consultingSkills, setConsultingSkills] = useState("");
    const [yrsOfExp, setYrsOfExp] = useState("");
    const [expectedHoursPerWeek, setExpectedHoursPerWeek] = useState("");

    useEffect(() => {
        props.onFieldsChange({
            specialty,
            areasOfExpertise,
            consultingSkills,
            yrsOfExp,
            expectedHoursPerWeek
        });
    }, [
        specialty,
        areasOfExpertise,
        consultingSkills,
        yrsOfExp,
        expectedHoursPerWeek
    ]);

    const specialties = [
        'Medical Legal',
        'Pharmaceutical Research',
        'Medical Informatics',
    ];

    const onSpecialtyChange = (event, val) => {
        setSpecialty(val);
    };

    const onAreasOfExpertiseChange = (event) => {
        const {
            target: { value },
        } = event;
        setAreasOfExpertise(typeof value === 'string' ? value.split(',') : value);
    };

    const onConsultingSkillsChange = (event) => {
        setConsultingSkills(event.target.value);
    };

    const onYearsOfExperienceChange = (event) => {
        setYrsOfExp(event.target.value);
    };

    const onExpectedHoursPerWeekChange = (event) => {
        setExpectedHoursPerWeek(event.target.value);
    };

    return (
        <Grid container sx={{mt: 3}}>
            <Grid item xs={12}>
                <Autocomplete
                    disablePortal
                    options={[
                        'Allergy',
                        'Immunology',
                        'Diagnostic Radiology',
                        'Interventional Radiology'
                    ]}
                    value={specialty}
                    onChange={onSpecialtyChange}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => {
                        return (
                            <TextField
                                margin={'normal'}
                                fullWidth
                                sx={{mt: 1}}
                                label={"Primary Specialty"}
                                autoFocus

                                {...params}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography component="h1" variant="body2" align='left' sx={{m:1, mt: 2}}>
                    Select all other areas of expertise for consulting purposes.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={areasOfExpertise}
                    onChange={onAreasOfExpertiseChange}
                    input={<OutlinedInput
                        sx={{mb: 2}}
                        fullWidth
                        label="Other Areas of Specialty"
                    />}
                >
                    {specialties.map((specialty) => (
                        <MenuItem
                            key={specialty}
                            value={specialty}
                        >
                            {specialty}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin={'normal'}
                    required
                    fullWidth
                    label="Tell us about you and your consulting skills."
                    autoFocus
                    value={consultingSkills}
                    onChange={onConsultingSkillsChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin={'normal'}
                    fullWidth
                    label="Years of Experience"
                    autoFocus
                    value={yrsOfExp}
                    onChange={onYearsOfExperienceChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin={'normal'}
                    fullWidth
                    label="Expected # of working hours on DXMD"
                    autoFocus
                    value={expectedHoursPerWeek}
                    onChange={onExpectedHoursPerWeekChange}
                />
            </Grid>
        </Grid>
    );
}

ProviderHealthcareConsultantLicenseInput.propTypes = {
    fields: PropTypes.object,
    onFieldsChange: PropTypes.func
};

export default ProviderHealthcareConsultantLicenseInput;