import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import {insuranceProviders} from "../../../constants/Constants";
import Typography from "@mui/material/Typography/Typography";

function EditBio(props) {
    return (
        <Box sx={{m: 4}}>
            <Typography sx={{m: 1}} variant={'h6'}> Edit Bio </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        fullWidth
                        label="Bio"
                        value={props.bio}
                        onChange={props.onBioChange}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

EditBio.propTypes = {
    bio: PropTypes.string,
    onBioChange: PropTypes.func,
};

export default EditBio;