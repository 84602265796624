import { useEffect, useState} from "react";
import Box from '@mui/material/Box';
import PaginatedTable from "../../../components/TableComponents/PaginatedTable";
import {Avatar, TableCell, TableRow} from "@mui/material";
import * as PropTypes from "prop-types";
import { getDoctorUpcoming } from '../../../api/apigateway/apigateway';
import { store } from '../../../redux/store';
import TabContext from "@mui/lab/TabContext/TabContext";
import Tabs from "@mui/material/Tabs/Tabs";
import Tab from "@mui/material/Tab/Tab";
import Grid from '@mui/material/Grid'
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import InputNewPatientVisit from '../../../components/deprecated/PatientData/InputNewPatientVisit';
import VideoAppointment from "../../../components/deprecated/Video/VideoAppointment";
import NextPatient from "./NextPatient";

const headCells = [
    {
        id: 'photo',
        numeric: false,
        label: '',
    },
    {
        id: 'name',
        numeric: false,
        label: 'Name',
    },
    {
        id: 'type',
        numeric: false,
        label: 'Type',
    },
    {
        id: 'time',
        numeric: true,
        label: 'Time',
    },
    {
        id: 'date',
        numeric: true,
        label: 'Date'
    },
    {
        id: 'complaint',
        numeric: true,
        label: 'Chief Complaint',
    },
];

function CustomTableRow(props) {
    console.log(props);
    const row = props.row;
    const userInfo = props.userInfo;

    return (
        <TableRow
            hover
            onClick={() => {}}
            key={row.patientId}
        >
            <TableCell padding={'normal'} align={'left'}>
                <Avatar src={userInfo[row.patientId] ? userInfo[row.patientId].picture : null}/>
            </TableCell>
            <TableCell padding={'normal'} align={'left'}>
                {userInfo[row.patientId] ? userInfo[row.patientId].firstName + " " + userInfo[row.patientId].lastName : null}
            </TableCell>
            <TableCell padding={'normal'} align={'left'}>
                {row.title}
            </TableCell>
            <TableCell padding={'normal'} align={'left'}>
                {new Date(row.startDate).toLocaleString().split(",")[0]}
            </TableCell>
            <TableCell padding={'normal'} align={'left'}>
                {new Date(row.startDate).toLocaleString().split(",")[1]}
            </TableCell>
            <TableCell padding={'normal'} align={'left'}>
                {row.complaint}
            </TableCell>
        </TableRow>
    );
}

CustomTableRow.propTypes = {
    row: PropTypes.object.isRequired,
};

function ProviderAppointments() {

    const [rows, setRows] = useState([]);
    const [nextAppt, setNextAppt] = useState();
    const [patientInfo, setPatientInfo] = useState([]);

    const [tabValue, setTabValue] = useState('1');
    const [currentAppointmentChannel, setCurrentAppointmentChannel] = useState('test');
    const [currentAgoraToken, setCurrentAgoraToken] = useState('');

    const onJoinCurrentAppointment = () => {
        console.log("NEXT", nextAppt)
        setCurrentAgoraToken(nextAppt.agoraTokenD)
        setCurrentAppointmentChannel(nextAppt.channelName)
        setTabValue('3');
    };

    const onLeaveCurrentAppointment = () => {
        //setTabValue('1');
    };

    const handleTabValueChange = (event, newValue) => {
        setTabValue(newValue)
    };

    const onCurrentAppointmentChannelChange = (newChannel) => {
        setCurrentAppointmentChannel(newChannel);
    };

    const onCurrentAgoraTokenChange = (newToken) => {
        setCurrentAgoraToken(newToken);
    };

    useEffect(() => {
        getDoctorUpcoming(store.getState().userID, (rows) => setRows(rows), (patientInfo) => setPatientInfo(patientInfo));
        //TODO: update current appointment channel from database value for next appoinment
    }, []);

    useEffect(() => {
        if (rows.length > 0) {
            setNextAppt(rows[0]);
            // console.log('patient info', rows[0]);
        }
    }, [rows]);
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent:'flex-start', alignItems: 'flex-start', align:'left', ml: 0, paddingLeft: 20 }}>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs sx={{align: 'left'}} value={tabValue} onChange={handleTabValueChange}>
                        <Tab label={'Appointments'} index="1" value="1"/>
                        {/* <Tab label={'Schedule'} index="2" value="2"/> */}
                        <Tab label={'Current'} index="3" value="3"/>
                    </Tabs>
                </Box>
                <TabPanel index="1" value="1">
                    <Box>
                        <Grid container spacing={2}>
                            {nextAppt && <Grid item xs={12}>
                                <NextPatient
                                    name={nextAppt && patientInfo[nextAppt.patientId] ? patientInfo[nextAppt.patientId].firstName + " " + patientInfo[nextAppt.patientId].lastName : null}
                                    profilePhoto={nextAppt && patientInfo[nextAppt.patientId] ? patientInfo[nextAppt.patientId].picture : null}
                                    title={nextAppt?.title}
                                    onViewProfile={() => {}}
                                    onJoin={onJoinCurrentAppointment}
                                    onChannelChange={onCurrentAppointmentChannelChange}
                                    onTokenChange={onCurrentAgoraTokenChange}
                                />
                            </Grid>}
                            <Grid item xs={12}>
                                <PaginatedTable
                                    tableName={'Upcoming Appointments'}
                                    headCells={headCells}
                                    rows={rows}
                                    userInfo={patientInfo}
                                    CustomTableRow={CustomTableRow}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </TabPanel>
                {/* <TabPanel index="2" value="2">
                    <ProviderSchedule/>
                </TabPanel> */}
                <TabPanel index="3" value="3">
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <InputNewPatientVisit/>
                        <VideoAppointment
                            onLeave={onLeaveCurrentAppointment}
                            channel={currentAppointmentChannel}
                            agoraToken={currentAgoraToken}
                        />
                    </Box>
                </TabPanel>
            </TabContext>
        </Box>
    );
}

export default ProviderAppointments;