import * as React from 'react';
import * as PropTypes from 'prop-types';
import Grid from "@mui/material/Grid/Grid";
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Button from "@mui/material/Button/Button";

function PhysicianInput(props) {
    const onFirstNameChange = (event) => {
        props.onChange({
            firstName: event.target.value,
            lastName: props.physicians[props.index].lastName,
            phone: props.physicians[props.index].phone,
            extension: props.physicians[props.index].extension,
            fax: props.physicians[props.index].fax,
            faxExtension: props.physicians[props.index].faxExtension,
            email: props.physicians[props.index].email,
            specialty: props.physicians[props.index].specialty,
        });
    };

    const onLastNameChange = (event) => {
        props.onChange({
            firstName: props.physicians[props.index].firstName,
            lastName: event.target.value,
            phone: props.physicians[props.index].phone,
            extension: props.physicians[props.index].extension,
            fax: props.physicians[props.index].fax,
            faxExtension: props.physicians[props.index].faxExtension,
            email: props.physicians[props.index].email,
            specialty: props.physicians[props.index].specialty,
        });
    };

    const onEmailChange = (event) => {
        props.onChange({
            firstName: props.physicians[props.index].firstName,
            lastName: props.physicians[props.index].lastName,
            phone: props.physicians[props.index].phone,
            extension: props.physicians[props.index].extension,
            fax: props.physicians[props.index].fax,
            faxExtension: props.physicians[props.index].faxExtension,
            email: event.target.value,
            specialty: props.physicians[props.index].specialty,
        });
    };

    const onPhoneNumberChange = (event) => {
        props.onChange({
            firstName: props.physicians[props.index].firstName,
            lastName: props.physicians[props.index].lastName,
            phone: event.target.value,
            extension: props.physicians[props.index].extension,
            fax: props.physicians[props.index].fax,
            faxExtension: props.physicians[props.index].faxExtension,
            email: props.physicians[props.index].email,
            specialty: props.physicians[props.index].specialty,
        });
    };

    const onPhoneExtensionChange = (event) => {
        props.onChange({
            firstName: props.physicians[props.index].firstName,
            lastName: props.physicians[props.index].lastName,
            phone: props.physicians[props.index].phone,
            extension: event.target.value,
            fax: props.physicians[props.index].fax,
            faxExtension: props.physicians[props.index].faxExtension,
            email: props.physicians[props.index].email,
            specialty: props.physicians[props.index].specialty,
        });
    };

    const onFaxChange = (event) => {
        props.onChange({
            firstName: props.physicians[props.index].firstName,
            lastName: props.physicians[props.index].lastName,
            phone: props.physicians[props.index].phone,
            extension: props.physicians[props.index].extension,
            fax: event.target.value,
            faxExtension: props.physicians[props.index].faxExtension,
            email: props.physicians[props.index].email,
            specialty: props.physicians[props.index].specialty,
        });
    };

    const onFaxExtensionChange = (event) => {
        props.onChange({
            firstName: props.physicians[props.index].firstName,
            lastName: props.physicians[props.index].lastName,
            phone: props.physicians[props.index].phone,
            extension: props.physicians[props.index].extension,
            fax: props.physicians[props.index].fax,
            faxExtension: event.target.value,
            email: props.physicians[props.index].email,
            specialty: props.physicians[props.index].specialty,
        });
    };

    const onSpecialtyChange = (event, val) => {
        props.onChange({
            firstName: props.physicians[props.index].firstName,
            lastName: props.physicians[props.index].lastName,
            phone: props.physicians[props.index].phone,
            extension: props.physicians[props.index].extension,
            fax: props.physicians[props.index].fax,
            faxExtension: props.physicians[props.index].faxExtension,
            email: props.physicians[props.index].email,
            specialty: val,
        });
    };

    const onItemDelete = () => {
        props.onDelete();
    };

    return (
        <Grid container sx={{mb: 3}}>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="First Name"
                    autoFocus
                    value={props.physicians[props.index].firstName}
                    onChange={onFirstNameChange}
                    sx={{mt: 1}}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Last Name"
                    autoFocus
                    value={props.physicians[props.index].lastName}
                    onChange={onLastNameChange}
                    sx={{mt: 1}}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Email"
                    autoFocus
                    value={props.physicians[props.index].email}
                    onChange={onEmailChange}
                    sx={{mt: 1}}
                />
            </Grid>
            <Grid item xs={12} sm={9}>
                <TextField
                    fullWidth
                    label="Phone"
                    autoFocus
                    value={props.physicians[props.index].phone}
                    onChange={onPhoneNumberChange}
                    sx={{mt: 1}}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField
                    fullWidth
                    label="Ext."
                    autoFocus
                    value={props.physicians[props.index].extension}
                    onChange={onPhoneExtensionChange}
                    sx={{mt: 1}}
                />
            </Grid>
            <Grid item xs={12} sm={9}>
                <TextField
                    fullWidth
                    label="Fax"
                    autoFocus
                    value={props.physicians[props.index].fax}
                    onChange={onFaxChange}
                    sx={{mt: 1}}
                />
            </Grid>
            <Grid item xs={12} xs={3}>
                <TextField
                    fullWidth
                    label="Ext."
                    autoFocus
                    value={props.physicians[props.index].faxExtension}
                    onChange={onFaxExtensionChange}
                    sx={{mt: 1}}
                />
            </Grid>
            <Grid item xs={9}>
                <Autocomplete
                    disablePortal
                    options={[
                        'Cardiologist',
                        'Nephrologist',
                        'Pulmonologist'
                    ]}
                    value={props.physicians[props.index].specialty}
                    onChange={onSpecialtyChange}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => {
                        return (
                            <TextField
                                fullWidth
                                label={"Specialty"}
                                autoFocus
                                {...params}
                                sx={{mt: 1}}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Button
                    variant={"contained"}
                    sx={{ml: 2, mt: 2}}
                    onClick={onItemDelete}
                    color={'secondary'}
                >
                    X
                </Button>
            </Grid>
        </Grid>
    );
}

PhysicianInput.propTypes = {
    physicians: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default PhysicianInput;