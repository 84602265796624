import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import * as PropTypes from 'prop-types';
import Paper from '@mui/material/Paper'
import EditProfilePicture from './EditProfilePicture';
import EditBackground from './PatientProfileComponents/EditBackground';
import IconButton from "@mui/material/IconButton/IconButton";
import HeightIcon from '@mui/icons-material/Height';
import WeightIcon from '@mui/icons-material/Scale';
import NameIcon from '@mui/icons-material/Label';
import GenderIcon from '@mui/icons-material/Transgender';
import PronounsIcon from '@mui/icons-material/Animation';
import AgeIcon from '@mui/icons-material/Timeline';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PaginatedTable from "../TableComponents/PaginatedTable";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Fab from '@mui/material/Fab';
import EditInsurance from "./PatientProfileComponents/EditInsurance";
import EditMedications from "./PatientProfileComponents/EditMedications";
import PastMedicalRecords from './PatientProfileComponents/PastMedicalRecords';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { store } from '../../redux/store';
import { getPicture, getPatientUploads } from '../../api/apigateway/apigateway';

function ProfilePicture(props) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={11}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'left', m: 4}}>
                    <Avatar src={props.profilePicture ? props.profilePicture : ""} sx={{height: 100, width: 100, ml: 4}}/>
                    <Box sx={{m: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Typography variant={'h4'}>{props.firstName + " " + props.lastName}</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={1}>
                <Fab
                    color="secondary"
                    aria-label="edit"
                    onClick={props.onEditClick}
                >
                    <EditIcon />
                </Fab>
            </Grid>
        </Grid>
    )
}

ProfilePicture.propTypes = {
    profilePicture: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    onEditClick: PropTypes.func
};

function BackgroundStat(props) {
    return(
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 1
        }}>
            <IconButton> <props.icon/> </IconButton>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <Typography sx={{m: 0.5, mt: 0, fontWeight: 'bold'}} variant={'subtitle1'}>{props.label}</Typography>
                <Typography sx={{m: 0.5, mt: 0}} variant={'subtitle2'}>{props.value}</Typography>
            </Box>
        </Box>
    )
}

BackgroundStat.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    icon: PropTypes.object,
    onClick: PropTypes.func
};

function Background(props) {
    return (
        <Box sx={{m: 2}}>
            <Typography sx={{m: 1}} variant={'h6'}> Background </Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <BackgroundStat
                        label={"Preferred Name"}
                        value={props.preferredName}
                        onClick={() => {}}
                        icon={NameIcon}
                    />
                </Grid>
                <Grid item xs={4}>
                    <BackgroundStat
                        label={"Gender"}
                        value={props.gender}
                        onClick={() => {}}
                        icon={GenderIcon}
                    />
                </Grid>
                <Grid item xs={4}>
                    <BackgroundStat
                        label={"Pronouns"}
                        value={props.pronouns}
                        onClick={() => {}}
                        icon={PronounsIcon}
                    />
                </Grid>
                <Grid item xs={4}>
                    <BackgroundStat
                        label={"Age"}
                        value={props.age}
                        onClick={() => {}}
                        icon={AgeIcon}
                    />
                </Grid>
                <Grid item xs={4}>
                    <BackgroundStat
                        label={"Height"}
                        value={props.height}
                        onClick={() => {}}
                        icon={HeightIcon}
                    />
                </Grid>
                <Grid item xs={4}>
                    <BackgroundStat
                        label={"Weight"}
                        value={props.weight}
                        onClick={() => {}}
                        icon={WeightIcon}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

Background.propTypes = {
    preferredName: PropTypes.string,
    gender: PropTypes.string,
    pronouns: PropTypes.string,
    age: PropTypes.number,
    height: PropTypes.string,
    weight: PropTypes.number,
};

function Insurance(props) {
    return (
        <Box sx={{m: 2}}>
            <Typography sx={{m: 1}} variant={'h6'}> Insurance </Typography>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography sx={{m: 0.5, mt: 0, fontWeight: 'bold'}} variant={'subtitle1'}>Insurance Provider</Typography>
                        <Typography sx={{m: 0.5, mt: 0}} variant={'subtitle2'}>{props.insuranceProvider}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography sx={{m: 0.5, mt: 0, fontWeight: 'bold'}} variant={'subtitle1'}>MemberID</Typography>
                        <Typography sx={{m: 0.5, mt: 0}} variant={'subtitle2'}>{props.memberID}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

Insurance.propTypes = {
    insuranceProvider: PropTypes.string,
    memberID: PropTypes.string,
};

function CurrentCondition(props) {

    // const [expanded, setExpanded] = React.useState(false);
    //
    // const handleExpandClick = () => {
    //     setExpanded(!expanded);
    // };

    return(
            <Box sx={{display: 'flex', flexDirection: 'row', padding: 2, m: 1}}>
                {/*<IconButton sx={{ml: -2, mr: 1}} onClick={handleExpandClick}>*/}
                    {/*<ExpandMoreIcon/>*/}
                {/*</IconButton>*/}
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Box sx={{ml: 1, alignItems: 'center'}}>
                            <Typography variant={'h6'}>{props.condition}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{}}>
                            <Typography variant={'subtitle2'}>Description</Typography>
                            <Typography variant={'body2'}>{props.description}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{}}>
                            <Typography variant={'subtitle2'}>Diagnosing Physician</Typography>
                            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                {/*<Avatar src={"https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}/>*/}
                                <Typography sx={{}} variant={'body2'}> Dr. {props.diagnoser}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{}}>
                            <Typography variant={'subtitle2'}>Treatment</Typography>
                            <Typography variant={'body2'}>{props.treatment}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
    )
}

CurrentCondition.propTypes = {
    condition: PropTypes.string,
    description: PropTypes.string,
    diagnoser: PropTypes.string,
    profilePic: PropTypes.string,
    treatment: PropTypes.string,
};

function CurrentConditions(props) {
    return (
        <Box sx={{m: 2}}>
            <Typography sx={{m: 1}} variant={'h6'}> Conditions </Typography>
            {props.conditions?.map((condition, index) => {
                return (
                    <CurrentCondition
                        condition={condition.condition}
                        description={condition.description}
                        diagnoser={condition.diagnoser}
                        profilePic={condition.profilePic}
                        treatment={condition.treatment}
                        key={index}
                    />
                )
            })}
        </Box>
    )
}

CurrentConditions.propTypes = {
    conditions: PropTypes.array,
};

function CurrentMedication(props) {

    // const [expanded, setExpanded] = React.useState(false);
    //
    // const handleExpandClick = () => {
    //     setExpanded(!expanded);
    // };

    return(
        <Box sx={{display: 'flex', flexDirection: 'row', padding: 2, m: 1}}>
            {/*<IconButton sx={{ml: -2, mr: 1}} onClick={handleExpandClick}>*/}
            {/*<ExpandMoreIcon/>*/}
            {/*</IconButton>*/}
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Box sx={{ml: 1, alignItems: 'center'}}>
                        <Typography variant={'h6'}>{props.medication} ({props.dosage})</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{}}>
                        <Typography variant={'subtitle2'}>Instructions</Typography>
                        <Typography variant={'body2'}>{props.instructions}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{}}>
                        <Typography variant={'subtitle2'}>Prescribing Physician</Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            {/*<Avatar src={"https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}/>*/}
                            <Typography sx={{}} variant={'body2'}> Dr. {props.prescriber}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{}}>
                        <Typography variant={'subtitle2'}>Last Refill</Typography>
                        <Typography variant={'body2'}>{props.lastRefill}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

CurrentMedication.propTypes = {
    medication: PropTypes.string,
    dosage: PropTypes.string,
    instructions: PropTypes.string,
    prescriber: PropTypes.string,
    lastRefill: PropTypes.string,
};

function CurrentMedications(props) {
    return (
        <Box sx={{m: 2}}>
            <Typography sx={{m: 1}} variant={'h6'}> Medications </Typography>
            {props.medications?.map((medication, index) => {
                return (
                    <CurrentMedication
                        medication={medication.name}
                        dosage={medication.dosage}
                        instructions={medication.instructions}
                        prescriber={medication.prescriber}
                        lastRefill={medication.lastRefill}
                        key={index}
                    />
                )
            })}
        </Box>
    )
}

CurrentMedications.propTypes = {
    medications: PropTypes.array
};

function PastVisitRow(props) {
    const {row} = props;
    // const [open, setOpen] = useState(false);

    return (
        <TableRow
            hover
            onClick={() => {}}
            key={row.name}
        >
            <TableCell>
                {row.date}
            </TableCell>
            <TableCell>
                {row.type}
            </TableCell>
            <TableCell padding={'normal'} align={'left'}>
                {row.provider}
            </TableCell>
            <TableCell padding={'normal'} align={'left'}>
                {row.complaint}
            </TableCell>
        </TableRow>
    );
}

function PastVisitHistory(props) {
    return (
        <Box sx={{m: 2}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PaginatedTable
                        tableName={"Past Visit History"}
                        headCells={[
                            {
                                id: 'date',
                                numeric: false,
                                label: 'Date',
                            },
                            {
                                id: 'type',
                                numeric: false,
                                label: 'Type/Specialty',
                            },
                            {
                                id: 'provider',
                                numeric: false,
                                label: 'Doctor',
                            },
                            {
                                id: 'complaint',
                                numeric: false,
                                label: 'Complaint',
                            },
                        ]}
                        rows={props.visits}
                        CustomTableRow={PastVisitRow}
                        paginationOptions={[5]}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

PastVisitHistory.propTypes = {
    visits: PropTypes.array
};

function PatientProfile() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [profilePicture, setProfilePicture] = useState('');
    const [age, setAge] = useState('');
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState('');
    const [pronouns, setPronouns] = useState('');
    const [gender, setGender] = useState('');
    const [preferredName, setPreferredName] = useState('');
    const [insuranceProvider, setInsuranceProvider] = useState('');
    const [memberID, setMemberID] = useState('');
    const [conditions, setConditions] = useState([]);
    const [medications, setMedications] = useState([]);
    const [visits, setVisits] = useState([]);
    const [records, setRecords] = useState([]);

    const [isEditing, setIsEditing] = useState(false);

    const onHeightChange = (event) => {
        setHeight(event.target.value);
    };

    const onWeightChange = (event) => {
        setWeight(event.target.value);
    };

    const onPreferredNameChange = (event) => {
        setPreferredName(event.target.value);
    };

    const onGenderChange = (event, val) => {
        setGender(val);
    };

    const onPronounsChange = (event, newValue) => {
        setPronouns(newValue);
    };

    const onAgeChange = (val) => {
        setAge(val);
    };

    const onInsuranceProviderChange = (event, val) => {
        setInsuranceProvider(val);
    };

    const onMemberIDChange = (event) => {
        setMemberID(event.target.value);
    };

    const onAddMedication = () => {
        const newMedications = [...medications];
        newMedications.push({
            name: "",
            dosage: "",
            instructions: null,
            status: null
        });
        setMedications(newMedications);
    };

    const onMedicationsChange = (newMedications) => {
        setMedications(newMedications);
    };

    const onEditClick = () => {
        setIsEditing(!isEditing);
    };

    const onSaveClick = () => {
        setIsEditing(!isEditing);
        //TODO: Api call to save new profile information
        const data = {
            preferredName: preferredName,
            gender: gender,
            pronouns: pronouns,
            age: age,
            height: height,
            weight: weight,
            insuranceProvider: insuranceProvider,
            memberID: memberID,
            medications: medications
        };
        const ENDPOINT = 'https://706dh7yrbg.execute-api.us-west-1.amazonaws.com/dev/profile';
        axios.post(
            ENDPOINT,
            data
        ).then((result) => {
            console.log(result['status']);
        }).catch((e) =>
            console.error('Error saving profile data')
        );
    };

    useEffect(() => {
        //TODO: Api Call to retrieve profile information
        const ENDPOINT = 'https://706dh7yrbg.execute-api.us-west-1.amazonaws.com/dev/profile';
        axios.get(ENDPOINT, {
            params: {
                patientID: store.getState().userID
            }
        }).then((response) => {
            console.log(response);
            setFirstName(response.data['firstName']);
            setLastName(response.data['lastName']);
            setPreferredName(response.data['preferredName']);
            setGender(response.data['gender']);
            setPronouns(response.data['pronouns']);
            setAge(response.data['age']);
            setHeight(response.data['height']);
            setWeight(response.data['weight']);
            setMedications(response.data['medications']);
            setInsuranceProvider(response.data['insurance']);
            setMemberID(response.data['memberID']);
            setRecords(response.data['records']);
            setVisits(response.data['pastVisits']);
        }).catch((err) => {
            console.log(err);
        });
        getPicture(store.getState().userID, setProfilePicture);
        getPatientUploads(store.getState().userID, setRecords);
    }, []);

    return (
        <Paper sx={{padding: 2, paddingRight: 4}}>
            {!isEditing &&
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    {/*<EditProfilePicture/>*/}
                    <ProfilePicture
                        profilePicture={profilePicture}
                        firstName={firstName}
                        lastName={lastName}
                        onEditClick={onEditClick}
                    />
                    <Divider/>
                    <Background
                        preferredName={preferredName}
                        gender={gender}
                        pronouns={pronouns}
                        age={age}
                        height={height}
                        weight={weight}
                    />
                    <Divider/>
                    <Insurance
                        insuranceProvider={insuranceProvider}
                        memberID={memberID}
                    />
                    <Divider/>
                    <CurrentConditions conditions={conditions}/>
                    <Divider/>
                    <CurrentMedications medications={medications}/>
                    <Divider/>
                    <PastVisitHistory visits={visits}/>
                    <Divider/>
                    <PastMedicalRecords records={records}/>
                </Box>
            }
            {isEditing &&
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', ml: 30}}>
                    <EditProfilePicture onSaveClick={onSaveClick}/>
                    <Divider/>
                    <EditBackground
                        age={age}
                        onAgeChange={onAgeChange}
                        height={height}
                        onHeightChange={onHeightChange}
                        weight={weight}
                        onWeightChange={onWeightChange}
                        pronouns={pronouns}
                        onPronounsChange={onPronounsChange}
                        preferredName={preferredName}
                        onPreferredNameChange={onPreferredNameChange}
                        gender={gender}
                        onGenderChange={onGenderChange}
                    />
                    <Divider/>
                    <EditInsurance
                        insurance={insuranceProvider}
                        onInsuranceChange={onInsuranceProviderChange}
                        memberID={memberID}
                        onMemberIDChange={onMemberIDChange}
                    />
                    <Divider/>
                    <EditMedications
                        medications={medications}
                        onAddMedication={onAddMedication}
                        onMedicationsChange={onMedicationsChange}
                    />
                </Box>
            }
        </Paper>
    );
}

PatientProfile.propTypes = {

};

export default PatientProfile;