import * as React from 'react';
import { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import {
    Box,
} from '@mui/material';
import PatientNavigationDrawer from "./components/NavigationDrawer/PatientNavigationDrawer";
import PortalAppBar from "./components/NavigationDrawer/PortalAppBar";
import PatientAppointments from "./pages/deprecated/PatientAppointments/PatientAppointments";
import ScheduleAppointment from "./pages/deprecated/PatientAppointments/ScheduleAppointment";
import { AccountContext } from "./api/authorization/Account";
import PatientSettings from "./pages/PatientSettings";
import PatientBilling from "./pages/deprecated/PatientBilling";
import { store } from "./redux/store";
import * as pools from './api/authorization/PoolCredentials';
import PatientProviders from "./pages/deprecated/PatientProviders";
import PatientDashboard from "./pages/PatientDashboard/PatientDashboard";
import PatientMyProfile from "./pages/PatientMyProfile";
import PatientRecords from "./pages/PatientRecords";
import ComingSoon from "./pages/deprecated/ComingSoon";

function PatientPortal() {

    const [screenToRender, setScreenToRender] = useState('Records');
    const history = useHistory();

    const { signOut } = useContext(AccountContext);

    const handleScreenChange = (newScreen) => {
        setScreenToRender(newScreen);
    };

    const onLogout = () => {
        const isPatient = store.getState().userPool === pools.patientUserPool;
        signOut(isPatient, () => {history.push("/login")});
    };

    return (
        <Box>
            {/* <PortalAppBar
                onMenuItemClick={handleScreenChange}
                onLogout={onLogout}
                header={'DXMD Patient Portal'}
            /> */}
            <Box sx={{display: 'flex'}}>
                <PatientNavigationDrawer selectedScreen={screenToRender} onDrawerItemClick={handleScreenChange} onLogout={onLogout} />
                {(screenToRender === 'Dashboard') &&
                    <PatientDashboard/>
                }
                {(screenToRender === 'My Profile') &&
                    <PatientMyProfile/>
                }
                {(screenToRender === 'Appointments') &&
                    <PatientAppointments/>
                }
                {(screenToRender === 'Scheduling') &&
                    <ScheduleAppointment />
                }
                {(screenToRender === 'Billing') &&
                    <PatientBilling />
                }
                {(screenToRender === 'Settings') &&
                    <PatientSettings/>
                }
                {(screenToRender === 'Messages') &&
                    <ComingSoon/>
                }
                {(screenToRender === 'Notes') &&
                    <ComingSoon />
                }
                {(screenToRender === 'Prescriptions') &&
                    <ComingSoon />
                }
                {(screenToRender==='Providers') &&
                    <PatientProviders/>
                }
                {(screenToRender==='Records') &&
                    <PatientRecords/>
                }
            </Box>
        </Box>
    );
};

export default PatientPortal;